import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AutoTextArea from './AutoTextArea';
import { CircularProgress } from '@material-ui/core';
import Avatar from './Avatar';
import PostMessage from './PostMessage';

const ActionReplyBody = React.memo(({ props }) => {
  const exp = useSelector((state) => state.experience);
  const account = useSelector((state) => state.auth.account);

  return (
    <div className="flex flex-col space-y-10" id="ActionReplyBody">
      <div className="flex space-x-2">
        <div className="flex-initial">
          <Avatar
            url={props.avatarUrl}
            name={props.name}
            id={props.id}
            canEdit={false}
            style={`rounded-full h-12 w-12 text-white font-bold flex items-center justify-center ${
              exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
            }`}
          />
        </div>
        <div className="flex-auto flex flex-col col-span-9">
          <div className="flex space-x-1 lg:space-x-2 items-start lg:items-center">
            <div className="font-bold">{props.name}</div>
            <div className="text-gray-700 text-sm font-semibold">
              @{props.userName}
            </div>
            <div className="text-gray-700 text-xs font-semibold">•</div>
            <div className="text-gray-700 text-xs font-semibold">
              {moment(props.time).fromNow()}
            </div>
          </div>
          <div className="mb-5">
            <PostMessage message={props.message} />
          </div>
          <div className="text-sm text-gray-500">
            Replying to{' '}
            <span
              className={`${
                exp === 'Recreational' ? 'text-green-500' : 'text-yellow-400'
              }`}
            >
              @{props.userName}
            </span>
          </div>
        </div>
      </div>
      <div className="flex space-x-2 items-start">
        <div className="flex-initial">
          <Avatar
            url={account.avatarUrl}
            name={account.name}
            id={account.id}
            canEdit={false}
            style={`rounded-full h-6 w-6 text-white text-xs font-bold flex items-center justify-center ${
              exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
            }`}
          />
        </div>
        <div className="flex-auto">
          <AutoTextArea
            className="w-full"
            placeholder={'Post your reply'}
            value={props.postText}
            onChange={props.handleTextChange}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex space-x-2 items-center">
          <div>{240 - props.postText.length}</div>
          <div>
            <button
              className={`${
                props.postButtonDisabled
                  ? 'bg-gray-500 cursor-not-allowed'
                  : exp === 'Recreational'
                  ? 'bg-green-500'
                  : 'bg-yellow-400'
              } py-2 px-5 rounded-full flex focus:outline-none`}
              type="button"
              disabled={props.postButtonDisabled}
              onClick={() => props.reply()}
            >
              <div className="flex space-x-2 justify-center items-center text-lg font-bold text-white antialiased">
                {props.posting && (
                  <div>
                    <CircularProgress size={10} />
                  </div>
                )}
                <div>{props.posting ? 'Posting' : 'Post'}</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ActionReplyBody;
