import React, { useState, useEffect } from 'react';

const regex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const PostMessage = React.memo(({ message }) => {
  const [displayText, setDisplayText] = useState(message);

  useEffect(() => {
    if (message) {
      let cloneText = message;

      message.match(regex)?.forEach((link) => {
        cloneText = cloneText.replaceAll(
          link,
          `<a class="postLink" target="_blank"
                  rel="noreferrer"
                  href=${link}>${link}</a>`
        );
      });

      setDisplayText(cloneText);
    }
  }, [message]);

  return message ? (
    <div
      id="message"
      className="font-mono"
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  ) : null;
});

export default PostMessage;
