import React from 'react';
import { useSelector } from 'react-redux';
import { BottomNavigation } from '@material-ui/core';
import {
  HomeRounded,
  PersonAddRounded,
  PersonRounded,
  InfoRounded,
  ShoppingCartRounded,
} from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

const notSelected = 'text-white';

const recSelected = 'text-green-500';

const medSelected = 'text-yellow-500';

const BottomBar = React.memo(() => {
  /* Variables */
  const path = useLocation().pathname.split('/');
  const value =
    path.length === 4 ? path[path.length - 2] : path[path.length - 1];
  const classes = useStyles();
  const exp = useSelector((state) => state.experience);
  const account = useSelector((state) => state.auth.account);

  return (
    <div className="w-full ">
      <BottomNavigation value={value} className={classes.root}>
        <NavItem
          to="/cloud"
          title="Home"
          icon={
            <HomeRounded
              fontSize="small"
              className={`${
                value === 'cloud'
                  ? exp === 'Recreational'
                    ? recSelected
                    : medSelected
                  : notSelected
              }`}
            />
          }
          external={false}
        />
        <NavItem
          to="/cloud/buds"
          title="Buds"
          icon={
            <PersonAddRounded
              fontSize="small"
              className={`${
                value === 'buds'
                  ? exp === 'Recreational'
                    ? recSelected
                    : medSelected
                  : notSelected
              }`}
            />
          }
          external={false}
        />
        <NavItem
          to="/cloud/learn"
          title="Learn"
          icon={
            <InfoRounded
              fontSize="small"
              className={`${
                value === 'learn'
                  ? exp === 'Recreational'
                    ? recSelected
                    : medSelected
                  : notSelected
              }`}
            />
          }
          external={false}
        />
        <NavItem
          to="https://120i36398444513.3dcartstores.com"
          title="Market"
          icon={
            <ShoppingCartRounded
              fontSize="small"
              className={`${
                value === 'market'
                  ? exp === 'Recreational'
                    ? recSelected
                    : medSelected
                  : notSelected
              }`}
            />
          }
          external={true}
        />
        <NavItem
          to={`/cloud/profile/${account.userName}`}
          title="Profile"
          icon={
            <PersonRounded
              fontSize="small"
              className={`${
                value === 'profile'
                  ? exp === 'Recreational'
                    ? recSelected
                    : medSelected
                  : notSelected
              }`}
            />
          }
          external={false}
        />
      </BottomNavigation>
    </div>
  );
});

const NavItem = React.memo(({ icon, title, to, external }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <>
      {external ? (
        <a
          className="flex flex-col justify-center items-center"
          target="_blank"
          rel="noreferrer"
          href={to}
        >
          <div>{icon}</div>
          <div className="text-white text-xs font-bold">{title}</div>
        </a>
      ) : (
        <NavLink
          to={to}
          className="relative flex flex-col justify-center items-center"
        >
          {title === 'Buds' && auth.account.newBudRequests && (
            <div className="absolute top-2 right-0 animate-pulse h-2 w-2 rounded-full bg-red-500"></div>
          )}
          <div>{icon}</div>
          <div className="text-white text-xs font-bold">{title}</div>
        </NavLink>
      )}
    </>
  );
});

export default BottomBar;
