import * as actionTypes from '../actions/actionTypes';

const initState = {
  fetching: false,
  loading: true,
  notifications: [],
  noMore: false,
  lastCreatedAt: null,
};

const getNotifications = (state, action) => {
  const { notifications, noMore, lastCreatedAt } = action;
  let updateState = { ...state };
  updateState.notifications = notifications;
  updateState.noMore = noMore;
  updateState.loading = false;
  updateState.lastCreatedAt = lastCreatedAt;
  return updateState;
};

const startFetching = (state) => {
  let updateState = { ...state };
  updateState.fetching = true;
  return updateState;
};

const fetchMore = (state, action) => {
  const { notifications, noMore, lastCreatedAt } = action;
  let updateState = { ...state };
  updateState.notifications = [...updateState.notifications, ...notifications];
  updateState.noMore = noMore;
  updateState.fetching = false;
  updateState.lastCreatedAt = lastCreatedAt;
  return updateState;
};

const resetNotificationReducer = () => {
  return initState;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS:
      return getNotifications(state, action);
    case actionTypes.START_FETCHING_NOTIFICATIONS:
      return startFetching(state);
    case actionTypes.FETCH_MORE_NOTIFICATIONS:
      return fetchMore(state, action);
    case actionTypes.RESET_NOTICATION_REDUCER:
      return resetNotificationReducer();
    default:
      return state;
  }
};

export default reducer;
