import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { Twitter, Instagram, Facebook } from '@material-ui/icons';

export const WebFooter = React.memo(() => {
  // const [email, setEmail] = useState('');

  // const handleEmailUpdate = (event) => {
  //   event.persist();
  //   setEmail(event.target.value);
  // };

  // const emailSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(email);
  //   setEmail('');
  // };

  return (
    <div className="bg-black">
      <div className="container mx-auto grid grid-cols-3 justify-between content-center px-2 md:px-0 text-white font-serif py-16 place-content-center gap-y-6 sm-gap-y-0">
        <div className="col-span-3 sm:col-span-1 grid justify-items-center space-y-3">
          <div>
            <NavLink exact to="/home">
              <img alt="home" src={Logo} className="h-10" />
            </NavLink>
          </div>
          <a href="mailto: Matchbudsllc@gmail.com">Matchbudsllc@gmail.com</a>
          <div className="flex space-x-2">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/MatchBudsllc"
            >
              <Twitter />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/matchbudsllc/"
            >
              <Instagram />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/MatchBuds-108385638157877"
            >
              <Facebook />
            </a>
          </div>
        </div>
        <div className="col-span-3 sm:col-span-1 grid justify-items-center space-y-3">
          {/* <div>
            <NavLink exact to="/login">
              <div className="font-bold">Log In</div>
            </NavLink>
          </div> */}
          <div>
            <NavLink exact to="/about">
              <div className="font-bold">About</div>
            </NavLink>
          </div>
          <div>
            <NavLink exact to="/learn">
              <div className="font-bold">Learn</div>
            </NavLink>
          </div>
          {/* <div>
            <NavLink exact to="/market">
              <div className="font-bold">Market Place</div>
            </NavLink>
          </div> */}
          <div>
            <NavLink exact to="/cloud9">
              <div className="font-bold">Cloud 9</div>
            </NavLink>
          </div>
        </div>
        <div className="col-span-3 sm:col-span-1 grid justify-items-center ">
          <div>
            <NavLink exact to="/terms">
              <div className="font-bold">Terms & Conditions</div>
            </NavLink>
          </div>
          <div>
            <NavLink exact to="/privacy">
              <div className="font-bold">Privacy Policy</div>
            </NavLink>
          </div>
          {/* <div className="font-bold">Get Updates & More</div>
          <form className="flex space-x-2" onSubmit={emailSubmit}>
            <div>
              <input
                type="email"
                value={email}
                onChange={(event) => handleEmailUpdate(event)}
                className="w-full rounded focus:outline-none text-black p-1"
                placeholder="Your email"
              />
            </div>
            <div>
              <button
                className="focus:outline-none"
                onClick={(event) => emailSubmit}
                disabled={email === ''}
              >
                <SendSharp
                  fontSize="small"
                  className="transform -rotate-45 text-green-500"
                />
              </button>
            </div>
          </form> */}
        </div>
      </div>
    </div>
  );
});
