import React, { useState } from 'react';
import { VisibilityRounded, VisibilityOffRounded } from '@material-ui/icons';
import { PasswordCheck } from './Authenticate';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { handlePasswordReset } from '../supabase/queries/auth';
import { useHistory } from 'react-router-dom';

const NewPasswordForm = React.memo(({ access_token, close }) => {
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasCapLet, setHasCapLet] = useState(false);
  const [hasLowerLet, setHasLowerLet] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [settingPassword, setSettingPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const isOnline = useSelector((state) => state.network.isOnline);
  const history = useHistory();

  /* validations */
  const passwordMatch = password === confirmPassword;
  const signupDisabled =
    password === null ||
    password === '' ||
    confirmPassword === null ||
    confirmPassword === '' ||
    !hasEightChars ||
    !hasCapLet ||
    !hasLowerLet ||
    !hasNumber ||
    !hasSpecial ||
    !passwordMatch ||
    settingPassword ||
    !isOnline;

  const handleValidatePassword = (pass) => {
    const passArr = [...pass];
    let hasCap = false;
    let hasLow = false;
    let hasNum = false;
    let hasSpecial = false;

    // iterate array of chars
    passArr.forEach((p) => {
      if (hasCap === false && p === p.toUpperCase()) {
        hasCap = true;
      }
      if (hasLow === false && p === p.toLowerCase()) {
        hasLow = true;
      }
      if (hasNum === false && p >= '0' && p <= '9') {
        hasNum = true;
      }
      if (
        hasSpecial === false &&
        !!/[~`!#$%\\^&*+=\-\\[\]\\';,/{}|\\":<>\\?]/g.test(p)
      ) {
        hasSpecial = true;
      }
    });

    // check if at least 8 charaacters
    if (pass.length >= 8) {
      setHasEightChars(true);
    } else {
      setHasEightChars(false);
    }

    setHasCapLet(hasCap);
    setHasLowerLet(hasLow);
    setHasNumber(hasNum);
    setHasSpecial(hasSpecial);
  };

  const handleToggleShowPassword = () => {
    setPasswordType((oldPassType) => {
      if (oldPassType === 'password') {
        return 'text';
      } else {
        return 'password';
      }
    });
  };

  const updatePasswordData = (event, field) => {
    event.persist();
    let val = event.target.value;

    // validate password
    if (field === 'password') {
      handleValidatePassword(val);
      setPassword(val);
    } else {
      setConfirmPassword(val);
    }
  };

  const handleReset = async (event) => {
    event.preventDefault();
    setSettingPassword(true);

    handlePasswordReset(access_token, password)
      .then(() => {
        close(true);
      })
      .catch((err) => {
        console.log('err: ', err);
        setSettingPassword(false);
      });
  };

  return (
    <form
      className="flex flex-col space-y-6 relative pb-3"
      onSubmit={(event) => handleReset(event)}
      autoComplete="true"
    >
      <div className="text-xl font-bold text-center">Change Your Password</div>
      <div className="relative flex flex-col">
        <div className="text-sm font-bold">Create New Password</div>
        <div>
          <input
            className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
            type={passwordType}
            value={password}
            onChange={(event) => updatePasswordData(event, 'password')}
          />
        </div>
        <div className="absolute bottom-2 right-2">
          <button
            className="focus:outline-none"
            onClick={() => handleToggleShowPassword()}
            type="button"
          >
            {passwordType === 'password' ? (
              <VisibilityRounded className="text-green-500" />
            ) : (
              <VisibilityOffRounded className="text-gray-500" />
            )}
          </button>
        </div>
      </div>
      <div className="relative flex flex-col space-y-2">
        <PasswordCheck
          string={'Must be at least 8 characters long'}
          passed={hasEightChars}
        />
        <PasswordCheck
          string={'Must have at least 1 capital letter'}
          passed={hasCapLet}
        />
        <PasswordCheck
          string={'Must have at least 1 lower case letter'}
          passed={hasLowerLet}
        />
        <PasswordCheck
          string={'Must have at least 1 number'}
          passed={hasNumber}
        />
        <PasswordCheck
          string={'Must have at least 1 special character'}
          passed={hasSpecial}
        />
      </div>
      <div className="flex flex-col">
        <div className="text-sm font-bold">Confirm Password</div>
        <div>
          <input
            className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
            type="password"
            value={confirmPassword}
            onChange={(event) => updatePasswordData(event, 'confirmPassword')}
          />
        </div>
      </div>
      <button
        className={`${
          signupDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-600'
        } float-right py-2 px-6 rounded-full text-white focus:outline-none shadow-xl w-full`}
        type="submit"
        disabled={signupDisabled}
      >
        <div className="flex space-x-2 justify-center items-center">
          {settingPassword && (
            <div>
              <CircularProgress size={10} />
            </div>
          )}
          <div>{settingPassword ? 'Changing' : 'Change Password'}</div>
        </div>
      </button>
    </form>
  );
});

export default NewPasswordForm;
