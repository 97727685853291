import React, { useState } from 'react';
import Logo from '../../assets/logo.png';
import SearchBar from '../../components/SearchBar';
import User from './User';
import { NavLink } from 'react-router-dom';
import { SearchRounded } from '@material-ui/icons';

const TopBar = React.memo(() => {
  /* Variabled */
  const [searchClicked, setSearchClicked] = useState(false);

  /* Methods */
  const toggleSearchClicked = (open) => {
    setSearchClicked(open);
  };

  return (
    <div className="container mx-auto px-5 flex justify-between py-2">
      {searchClicked && (
        <div className="w-full">
          <SearchBar
            mobile={true}
            mobileClose={() => toggleSearchClicked(false)}
          />
        </div>
      )}
      {!searchClicked && (
        <div className="flex space-x-1 lg:space-x-8 items-center">
          <div>
            <NavLink exact to="/cloud">
              <img alt="home" src={Logo} className="h-10" />
            </NavLink>
          </div>
          <button
            className="flex lg:hidden text-white focus:outline-none"
            onClick={() => toggleSearchClicked(true)}
          >
            <SearchRounded />
          </button>
          <div className="hidden lg:flex">
            <SearchBar mobile={false} />
          </div>
        </div>
      )}
      {!searchClicked && (
        <div className="place-self-end">
          <User />
        </div>
      )}
    </div>
  );
});

export default TopBar;
