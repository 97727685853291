import * as actionTypes from './actionTypes';
import {
  getPostsForTimeline,
  fetchPostsByUser,
  fetchPostsLikedByUser,
} from '../../supabase/queries/post';
import { getAllBudIdsForUser } from '../../supabase/queries/buds';

export const addPostLikeAndRepost = (postId, postLikeId, postRepostId) => {
  return {
    type: actionTypes.ADD_POST_LIKE_AND_REPOST,
    postId: postId,
    postLikeId: postLikeId,
    postRepostId: postRepostId,
  };
};

export const addPostLike = (postId, postLikeId, isInit) => {
  return {
    type: actionTypes.ADD_POST_LIKE,
    postId: postId,
    postLikeId: postLikeId,
    isInit: isInit,
  };
};

export const removePostLike = (postId) => {
  return {
    type: actionTypes.REMOVE_POST_LIKE,
    postId: postId,
  };
};

export const addPostRepost = (postId, postRepostId, isInit) => {
  return {
    type: actionTypes.ADD_POST_REPOST,
    postId: postId,
    postRepostId: postRepostId,
    isInit: isInit,
  };
};

export const removePostRepost = (postId) => {
  return {
    type: actionTypes.REMOVE_POST_REPOST,
    postId: postId,
  };
};

export const addPostComment = (postId) => {
  return {
    type: actionTypes.ADD_POST_COMMENT,
    postId: postId,
  };
};

export const removePostComment = (postId) => {
  return {
    type: actionTypes.REMOVE_POST_COMMENT,
    postId: postId,
  };
};

export const getPosts = (exp, accountId) => {
  return (dispatch) => {
    getBudIdsAndPosts(accountId, exp)
      .then((res) => {
        dispatch(
          getPostSuccess(
            res.budIds,
            res.posts,
            res.interactions,
            res.lastCreatedAt,
            res.noMore
          )
        );
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };
};

const getBudIdsAndPosts = async (accountId, exp) => {
  let body = {
    budIds: [],
    posts: [],
    interactions: [],
    lastCreatedAt: null,
    noMore: false,
  };

  body.budIds = [accountId, ...(await getAllBudIdsForUser(accountId))];

  await getPostsForTimeline(body.budIds, exp, null, 10)
    .then((res) => {
      if (res.length > 0) {
        body = cleanPostsData(res, body);
      }
    })
    .catch((e) => {
      console.log('e: ', e);
    });

  return body;
};

export const fetchMore = (budIds, exp, lastCreatedAt, interactions) => {
  return (dispatch) => {
    fetchMorePosts(budIds, exp, lastCreatedAt, interactions).then((res) => {
      dispatch(
        fetchPostSuccess(
          res.posts,
          res.interactions,
          res.lastCreatedAt,
          res.noMore
        )
      );
    });
  };
};

const fetchMorePosts = async (budIds, exp, lastCreatedAt, interactions) => {
  let body = {
    posts: [],
    interactions: [...interactions],
    lastCreatedAt: null,
    noMore: false,
  };

  await getPostsForTimeline(budIds, exp, lastCreatedAt, 10)
    .then((res) => {
      if (res.length > 0) {
        body = cleanPostsData(res, body);
      }
    })
    .catch((e) => {
      console.log('e: ', e);
    });

  return body;
};

export const getUserPosts = (userId, exp) => {
  return (dispatch) => {
    fetchUserPosts(userId, exp, null, null).then((res) => {
      dispatch(
        fetchUserPostSuccess(
          res.posts,
          res.interactions,
          res.lastCreatedAt,
          res.noMore,
          true
        )
      );
    });
  };
};

export const fetchMoreUserPosts = (
  userId,
  exp,
  lastCreatedAt,
  interactions
) => {
  return (dispatch) => {
    fetchUserPosts(userId, exp, lastCreatedAt, interactions).then((res) => {
      dispatch(
        fetchUserPostSuccess(
          res.posts,
          res.interactions,
          res.lastCreatedAt,
          res.noMore,
          false
        )
      );
    });
  };
};

export const getUserLikedPosts = (userId, exp) => {
  return (dispatch) => {
    fetchUserLikedPosts(userId, exp, null, null).then((res) => {
      dispatch(
        fetchUserPostSuccess(
          res.posts,
          res.interactions,
          res.lastCreatedAt,
          res.noMore,
          true
        )
      );
    });
  };
};

export const fetchMorePostsLikedByUser = (
  userId,
  exp,
  lastCreatedAt,
  interactions
) => {
  return (dispatch) => {
    fetchUserLikedPosts(userId, exp, lastCreatedAt, interactions).then(
      (res) => {
        dispatch(
          fetchUserPostSuccess(
            res.posts,
            res.interactions,
            res.lastCreatedAt,
            res.noMore,
            false
          )
        );
      }
    );
  };
};

const fetchUserPosts = async (userId, exp, lastCreatedAt, interactions) => {
  let body = {
    posts: [],
    interactions: interactions ? [...interactions] : [],
    lastCreatedAt: null,
    noMore: false,
  };

  await fetchPostsByUser(userId, exp, lastCreatedAt, 10)
    .then((res) => {
      if (res.length > 0) {
        body = cleanPostsData(res, body);
      }
    })
    .catch((e) => {
      console.log('e: ', e);
    });

  return body;
};

const fetchUserLikedPosts = async (
  userId,
  exp,
  lastCreatedAt,
  interactions
) => {
  let body = {
    posts: [],
    interactions: interactions ? [...interactions] : [],
    lastCreatedAt: null,
    noMore: false,
  };

  await fetchPostsLikedByUser(userId, exp, lastCreatedAt, 10)
    .then((res) => {
      if (res.length > 0) {
        const posts = res.map((p) => p.post);
        body = cleanPostsData(posts, body);
      }
    })
    .catch((e) => {
      console.log('e: ', e);
    });

  return body;
};

const getPostSuccess = (budIds, posts, interactions, lastCreatedAt, noMore) => {
  return {
    type: actionTypes.GET_POSTS,
    budIds: budIds,
    posts: posts,
    interactions: interactions,
    lastCreatedAt: lastCreatedAt,
    noMore: noMore,
  };
};

export const addPost = (post) => {
  return {
    type: actionTypes.ADD_POST,
    post: post,
  };
};

const fetchPostSuccess = (posts, interactions, lastCreatedAt, noMore) => {
  return {
    type: actionTypes.FETCH_MORE,
    posts: posts,
    interactions: interactions,
    lastCreatedAt: lastCreatedAt,
    noMore: noMore,
  };
};

const fetchUserPostSuccess = (
  posts,
  interactions,
  lastCreatedAt,
  noMore,
  isInit
) => {
  return {
    type: isInit
      ? actionTypes.GET_USER_POSTS
      : actionTypes.FETCH_MORE_USER_POSTS,
    posts: posts,
    interactions: interactions,
    lastCreatedAt: lastCreatedAt,
    noMore: noMore,
  };
};

const cleanPostsData = (posts, body) => {
  if (posts.length < 10) {
    body.noMore = true;
  }

  body.lastCreatedAt = posts[posts.length - 1].createdAt;

  posts.forEach((p, i) => {
    const childOfInactive =
      p.parentPost !== null && p.parentPost.status !== 'active';

    if (childOfInactive === false) {
      body.posts.push({
        id: p.id,
        name: p.account.name,
        userName: p.account.userName,
        message: p.message,
        comments: p.comments,
        likes: p.likes,
        reposts: p.reposts,
        time: p.createdAt,
        avatarUrl: p.account.avatarUrl,
        account: p.account.id,
        parentPost: p.parentPost,
        type: p.type,
      });

      // check if post is in array
      if (body.interactions.findIndex((pi) => pi.postId === p.id) === -1) {
        body.interactions.push({
          postId: p.id,
          postLikeId: null,
          postRepostId: null,
          likes: p.likes,
          reposts: p.reposts,
          comments: p.comments,
          fetched: false,
        });
      }

      // check if parent post is in array
      if (
        p.parentPost &&
        body.interactions.findIndex((pi) => pi.postId === p.parentPost.id) ===
          -1
      ) {
        body.interactions.push({
          postId: p.parentPost.id,
          postLikeId: null,
          postRepostId: null,
          likes: p.parentPost.likes,
          reposts: p.parentPost.reposts,
          comments: p.parentPost.comments,
          fetched: false,
        });
      }

      // check if grandparent post is in array
      if (
        p?.parentPost?.parentPost &&
        body.interactions.findIndex(
          (pi) => pi.postId === p.parentPost.parentPost.id
        ) === -1
      ) {
        body.interactions.push({
          postId: p.parentPost.parentPost.id,
          postLikeId: null,
          postRepostId: null,
          likes: p.parentPost.parentPost.likes,
          reposts: p.parentPost.parentPost.reposts,
          comments: p.parentPost.parentPost.comments,
          fetched: false,
        });
      }
    }
  });

  return body;
};

export const deletePost = (postId) => {
  return {
    type: actionTypes.DELETE_POST,
    postId: postId,
  };
};

export const setFetching = () => {
  return {
    type: actionTypes.SET_FETCHING,
  };
};

export const stopFetching = () => {
  return {
    type: actionTypes.STOP_FETCHING,
  };
};

export const stopLoading = () => {
  return {
    type: actionTypes.STOP_FETCHING,
  };
};

export const resetPostReducer = () => {
  return {
    type: actionTypes.RESET_POST_REDUCER,
  };
};
