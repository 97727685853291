import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavItem } from './NavItem';
import {
  HomeRounded,
  PersonAddRounded,
  PersonRounded,
  InfoRounded,
  ShoppingCartRounded,
  CreateRounded,
} from '@material-ui/icons';
import { Switch } from '../../components/Switch';
import * as actionTypes from '../../store/actions';
import { useSnackbar } from 'notistack';

export const NavSidebar = React.memo(({ togglePost }) => {
  /* Variables */
  const exp = useSelector((state) => state.experience);
  const account = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /* Methods */
  const handleExperienceSwitch = () => {
    dispatch(
      actionTypes.setExp(exp === 'Recreational' ? 'Medicinal' : 'Recreational')
    );
    displaySnackBar(
      exp === 'Recreational'
        ? 'Switched to Medicinal'
        : 'Switched to Recreational',
      'success'
    );
  };

  const displaySnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  return (
    <div className="h-screen px-10">
      <div
        style={{ height: 'inherit' }}
        className="flex justify-between flex-col"
      >
        <div className="flex flex-col space-y-8 -mt-2 xl:mt-0 pt-0 xl:pt-5">
          <div className="hidden xl:inline">
            <Switch
              on={exp !== 'Recreational'}
              title={'switch experience'}
              function={handleExperienceSwitch}
            />
          </div>
          <div className="flex flex-col space-y-8">
            <NavItem
              icon={<HomeRounded fontSize="large" />}
              title="Home"
              to="/cloud"
              external={false}
            />
            <NavItem
              icon={<PersonAddRounded fontSize="large" />}
              title="Buds"
              to="/cloud/buds"
              external={false}
            />
            <NavItem
              icon={<InfoRounded fontSize="large" />}
              title="Learn"
              to="/cloud/learn"
              external={false}
            />
            <NavItem
              icon={<ShoppingCartRounded fontSize="large" />}
              title="Market Place"
              to="https://120i36398444513.3dcartstores.com"
              external={true}
            />
            <NavItem
              icon={<PersonRounded fontSize="large" />}
              title="Profile"
              to={`/cloud/profile/${account.userName}`}
              external={false}
            />
          </div>
          <button
            className={`${
              exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
            } px-6 py-3 shadow rounded-full h-10 w-10 xl:h-auto xl:w-48 flex items-center justify-center focus:outline-none`}
            onClick={togglePost}
          >
            <p className="hidden xl:inline text-xl font-bold text-white antialiased">
              Post
            </p>
            <div className="inline xl:hidden text-white">
              <CreateRounded fontSize="small" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
});
