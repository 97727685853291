import * as actionTypes from "../actions/actionTypes";
import moment from "moment";

const initState = false;

const answerYes = () => {
  localStorage.setItem("over21expiry", moment().add(1, "w").unix());
  return true;
};

const resetOver21 = () => {
  localStorage.removeItem("over21expiry");
  return initState;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ANSWER_YES:
      return answerYes();
    case actionTypes.RESET_OVER_21:
      return resetOver21();
    default:
      return state;
  }
};

export default reducer;
