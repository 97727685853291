import * as actionTypes from '../actions/actionTypes';

const initState = {
  postId: '',
  post: null,
  error: null,
  loading: true,
  interactions: {
    postLikeId: null,
    postRepostId: null,
  },
};

const selectPost = (state, action) => {
  const { postId } = action;
  let update = { ...state };
  update.postId = postId;
  update.post = null;
  return update;
};

const fetchPost = (state, action) => {
  const { post, error, interactions } = action;
  let update = { ...state };
  update.post = post;
  update.error = error;
  update.loading = false;
  update.interactions = interactions;
  return update;
};

const clearSelectedPost = () => {
  return initState;
};

const addLike = (state, action) => {
  const { postLikeId } = action;
  let update = { ...state };
  update.interactions.postLikeId = postLikeId;
  update.post.likes = update.post.likes + 1;
  return update;
};

const addRepost = (state, action) => {
  const { postRepostId } = action;
  let update = { ...state };
  update.interactions.postRepostId = postRepostId;
  update.post.reposts = update.post.reposts + 1;
  return update;
};

const removeLike = (state) => {
  let update = { ...state };
  update.interactions.postLikeId = null;
  update.post.likes = update.post.likes - 1;
  return update;
};

const removeRepost = (state) => {
  let update = { ...state };
  update.interactions.postRepostId = null;
  update.post.reposts = update.post.reposts - 1;
  return update;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_POST:
      return selectPost(state, action);
    case actionTypes.FETCH_POST:
      return fetchPost(state, action);
    case actionTypes.CLEAR_SELECTED_POST:
      return clearSelectedPost();
    case actionTypes.SINGLE_POST_ADD_LIKE:
      return addLike(state, action);
    case actionTypes.SINGLE_POST_ADD_REPOST:
      return addRepost(state, action);
    case actionTypes.SINGLE_POST_REMOVE_LIKE:
      return removeLike(state);
    case actionTypes.SINGLE_POST_REMOVE_REPOST:
      return removeRepost(state);
    default:
      return state;
  }
};

export default reducer;
