import React from 'react';
import { useSelector } from 'react-redux';

export const Switch = React.memo((props) => {
  /* Variables */
  const exp = useSelector((state) => state.experience);

  return (
    <button
      className={`w-full rounded-full flex focus:outline-none ${
        props.on ? 'flex-row-reverse' : ''
      } items-center ${
        exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
      }`}
      onClick={() => {
        props.function();
      }}
    >
      <div className="rounded-full m-1 py-1 flex items-center justify-center bg-white text-white">
        white
      </div>
      <div className="text-white font-bold text-xs">{props.title}</div>
    </button>
  );
});
