import supabase from '../api';
import { fetchSinglePost } from './post';

/**
 * get notifications for a specific user
 * @param {string} userId uuid of user whose nootifications will be fetched
 * @returns array of notifications
 */
export const getNotifications = async (
  userId,
  exp,
  lastCreatedAt,
  fetchLimit
) => {
  // query string
  const query =
    lastCreatedAt === null
      ? `and(user.eq.${userId},environment.eq.${exp})`
      : `and(user.eq.${userId},environment.eq.${exp},createdAt.lt.${lastCreatedAt})`;

  const { data: notifications, error } = await supabase
    .from('notifications')
    .select('*, createdBy(name, id, avatarUrl)')
    .or(query)
    .order('createdAt', { ascending: false })
    .limit(fetchLimit);

  if (error) {
    console.log('error: ', error);
    throw error;
  }

  if (notifications) {
    let promiseArray = [];

    notifications.forEach((n) => {
      if (n.post) {
        promiseArray.push(fetchSinglePost(n.post));
      }
    });

    await Promise.all(promiseArray)
      .then((posts) => {
        notifications.forEach((n) => {
          n.post = posts.find((p) => p.id === n.post);
        });
      })
      .catch((err) => {
        console.log('err: ', err);
      });
    return notifications;
  }
};
