import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import privacyPdf from '../../assets/pdfs/Privacy.pdf';
import { WebFooter } from '../../components/WebFooter';
import { WebNavBar } from '../../components/WebNavbar';
import { CircularProgress } from '@material-ui/core';
import PdfController from '../../components/PDFcontroller';

const PrivacyView = React.memo(() => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (add) => {
    setPageNumber((old) => {
      return add ? old + 1 : old - 1;
    });
  };

  return (
    <div className="">
      <WebNavBar />
      <div className="flex flex-col items-center container mx-auto px-4 md:px-0">
        <div className="flex space-x-2 items-center pt-2">
          <PdfController
            num={pageNumber}
            pages={numPages}
            change={changePage}
          />
        </div>
        <div className="hidden lg:inline">
          <Document
            file={privacyPdf}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<CircularProgress />}
          >
            <Page pageNumber={pageNumber} scale="2.0" renderInteractiveForms />
          </Document>
        </div>
        <div className="hidden md:inline lg:hidden">
          <Document
            file={privacyPdf}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<CircularProgress />}
          >
            <Page
              pageNumber={pageNumber}
              height={500}
              scale="2.0"
              renderInteractiveForms
            />
          </Document>
        </div>
        <div className="inline md:hidden">
          <Document
            file={privacyPdf}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<CircularProgress />}
          >
            <Page
              pageNumber={pageNumber}
              height={250}
              scale="2.0"
              renderInteractiveForms
            />
          </Document>
        </div>
        <div className="flex space-x-2 items-center pb-2">
          <PdfController
            num={pageNumber}
            pages={numPages}
            change={changePage}
            lloading={<CircularProgress />}
          />
        </div>
      </div>
      <WebFooter />
    </div>
  );
});

export default PrivacyView;
