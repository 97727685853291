import React from "react";
import { WebFooter } from "../../components/WebFooter";
import { WebNavBar } from "../../components/WebNavbar";
import { FourthSection } from "../HomeView/FourthSection";
import { FirstSection } from "./FirstSection";
import { SecondSection } from "./SecondSection";

const AboutView = React.memo(() => {
  return (
    <div>
      <WebNavBar />
      <FirstSection />
      <SecondSection />
      <FourthSection />
      <WebFooter />
    </div>
  );
});

export default AboutView;
