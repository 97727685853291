import * as actionTypes from './actionTypes';
import {
  fetchSinglePost,
  fetchPostLike,
  fetchPostRepost,
} from '../../supabase/queries/post';

export const selectPost = (postId) => {
  return {
    type: actionTypes.SELECT_POST,
    postId: postId,
  };
};

export const fetchPost = (postId, accountId) => {
  return (dispatch) => {
    fetchPostAsync(postId, accountId).then((res) => {
      dispatch(fetchPostSuccess(res.post, res.error, res.interactions));
    });
  };
};

export const clearSelectedPost = () => {
  return {
    type: actionTypes.CLEAR_SELECTED_POST,
  };
};

export const singlePostAddLike = (postLikeId) => {
  return {
    type: actionTypes.SINGLE_POST_ADD_LIKE,
    postLikeId: postLikeId,
  };
};

export const singlePostAddRepost = (postRepostId) => {
  return {
    type: actionTypes.SINGLE_POST_ADD_REPOST,
    postRepostId: postRepostId,
  };
};

export const singlePostRemoveLike = () => {
  return {
    type: actionTypes.SINGLE_POST_REMOVE_LIKE,
  };
};

export const singlePostRemoveRepost = () => {
  return {
    type: actionTypes.SINGLE_POST_REMOVE_REPOST,
  };
};

const fetchPostAsync = async (postId, accountId) => {
  let retVal = {
    post: null,
    interactions: {
      postLikeId: null,
      postRepostId: null,
    },
    error: null,
  };

  const promiseArray = [
    fetchSinglePost(postId),
    fetchPostLike(accountId, postId),
    fetchPostRepost(accountId, postId),
  ];

  try {
    let res = await Promise.all(promiseArray);
    retVal.post = res[0] ? res[0] : null;
    retVal.interactions.postLikeId = res[1] ? res[1].id : null;
    retVal.interactions.postRepostId = res[2] ? res[2].id : null;
  } catch (error) {
    retVal.error = error;
  }

  return retVal;
};

const fetchPostSuccess = (post, error, interactions) => {
  return {
    type: actionTypes.FETCH_POST,
    post: post,
    error: error,
    interactions: interactions,
  };
};
