import * as actionTypes from '../actions/actionTypes';

const initState = {
  fetching: false,
  loading: true,
  requests: [],
  noMore: false,
  lastCreatedAt: null,
};

const getRequests = (state, action) => {
  const { requests, noMore, lastCreatedAt } = action;
  let updateState = { ...state };
  updateState.requests = requests;
  updateState.noMore = noMore;
  updateState.loading = false;
  updateState.lastCreatedAt = lastCreatedAt;
  return updateState;
};

const startFetching = (state) => {
  let updateState = { ...state };
  updateState.fetching = true;
  return updateState;
};

const fetchMore = (state, action) => {
  const { requests, noMore, lastCreatedAt } = action;
  let updateState = { ...state };
  updateState.requests = [...updateState.requests, ...requests];
  updateState.noMore = noMore;
  updateState.fetching = false;
  updateState.lastCreatedAt = lastCreatedAt;
  return updateState;
};

const resetRequestsReducer = () => {
  return initState;
};

const handleRequestResponse = (state, action) => {
  const { reqId, actionTaken } = action;
  let updateState = { ...state };
  const reqIndex = updateState.requests.findIndex((r) => r.id === reqId);
  updateState.requests[reqIndex].actionTaken = actionTaken;
  return updateState;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_REQUESTS:
      return getRequests(state, action);
    case actionTypes.START_FETCHING_REQUESTS:
      return startFetching(state);
    case actionTypes.FETCH_MORE_REQUESTS:
      return fetchMore(state, action);
    case actionTypes.RESET_REQUESTS_REDUCER:
      return resetRequestsReducer();
    case actionTypes.HANDLE_REQUEST_RESPONSE:
      return handleRequestResponse(state, action);
    default:
      return state;
  }
};

export default reducer;
