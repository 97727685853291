import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SplashScreen from './SplashScreen';
import * as actionTypes from '../store/actions';
import { useHistory } from 'react-router-dom';
import {
  autoLogin,
  signOut,
  sendPasswordResetEmail,
} from '../supabase/queries/auth';
import { Modal } from './Modal';
import NewPasswordForm from './NewPasswordForm';
import { ForgotPasswordForm } from './Authenticate';

const AuthAuto = React.memo(({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const [recoveryToken, setRecoveryToken] = useState(null);
  const [recoveryExpired, setRecoveryExpired] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);
  const [resetErr, setResetErr] = useState(false);
  const [passwordReSuccess, setPasswordReSuccess] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
      await autoLogin()
        .then((e) => {
          if (e) {
            // dispatch user's main use as the experience
            dispatch(actionTypes.setExp(e.mainUse));
            // dispatch current user information
            dispatch(actionTypes.login(e));
            // history.push('/cloud');
          } else {
            history.push('/');
          }
        })
        .catch((e) => {});

      setLoading(false);
    };

    /* Recovery url is of the form
     * <SITE_URL>#access_token=x&refresh_token=y&expires_in=z&token_type=bearer&type=recovery
     * Read more on https://supabase.io/docs/reference/javascript/reset-password-email#notes
     */
    let url = window.location.hash;
    let query = url.substr(1);
    let result = {};

    query.split('&').forEach((part) => {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });

    if (result?.type === 'recovery') {
      setRecoveryToken(result.access_token);
    } else if (result?.error_description === 'User+not+found') {
      setRecoveryExpired(true);
    } else {
      initAuth();
    }
  }, [dispatch, history]);

  const close = async (success) => {
    setRecoveryToken(null);
    setRecoveryExpired(false);

    if (success) {
      await autoLogin()
        .then((e) => {
          if (e) {
            // dispatch user's main use as the experience
            dispatch(actionTypes.setExp(e.mainUse));
            // dispatch current user information
            dispatch(actionTypes.login(e));
            history.push('/cloud');
          } else {
            history.push('/');
          }
        })
        .catch((e) => {});

      setLoading(false);
    } else {
      signOut();
      localStorage.removeItem('authEmail');
      dispatch(actionTypes.logout());
      history.push('/');
    }
  };

  const sendEmail = async (event, email) => {
    event.preventDefault();
    setSendingReset(true);
    setResetErr(false);

    try {
      let e = await sendPasswordResetEmail(email.toLowerCase().trim());

      if (e) {
        setPasswordReSuccess(true);
      } else {
        setResetErr(true);
      }
      setSendingReset(false);
    } catch (error) {
      console.log('error: ', error);
      setSendingReset(false);
      setResetErr(true);
    }
  };

  if (isLoading && !recoveryToken && !recoveryExpired) {
    return <SplashScreen />;
  }

  if (recoveryToken) {
    return (
      <Modal
        isOpen={recoveryToken !== null}
        handleClose={() => close(false)}
        content={<NewPasswordForm access_token={recoveryToken} close={close} />}
      />
    );
  }

  if (recoveryExpired) {
    return (
      <Modal
        isOpen={recoveryExpired}
        handleClose={() => close(false)}
        content={
          <ForgotPasswordForm
            sendEmail={sendEmail}
            sendingReset={sendingReset}
            passwordReSuccess={passwordReSuccess}
            resetErr={resetErr}
            fromAuth={false}
          />
        }
      />
    );
  }
  return children;
});

export default AuthAuto;
