import React from 'react';
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@material-ui/icons';

const PdfController = React.memo(({ num, pages, change }) => {
  return (
    <>
      <button onClick={() => change(false)} className="focus:outline-none">
        <KeyboardArrowLeftRounded fontSize="large" />
      </button>
      <div className="font-bold">{`Page ${num} of ${pages}`}</div>
      <button onClick={() => change(true)} className="focus:outline-none">
        <KeyboardArrowRightRounded fontSize="large" />
      </button>
    </>
  );
});

export default PdfController;
