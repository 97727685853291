import * as actionTypes from '../actions/actionTypes';

const initState = {
  account: {},
};

const login = (state, action) => {
  let updateState = { ...state };
  updateState.account = action.res;
  return updateState;
};

const signup = (state, action) => {
  let updateState = { ...state };
  updateState.account = action.res;
  return updateState;
};

const logout = () => {
  return initState;
};

const updateAccount = (state, action) => {
  let updateState = { ...state };
  updateState.account = action.data;
  return updateState;
};

const updateUrl = (state, action) => {
  let updateState = { ...state };
  updateState.account.avatarUrl = action.url;
  return updateState;
};

const handleNotificationRead = (state, action) => {
  const { notification } = action;
  let update = { ...state };
  update.account[notification] = false;
  return update;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.LOG_IN:
      return login(state, action);
    case actionTypes.SIGN_UP:
      return signup(state, action);
    case actionTypes.LOG_OUT:
      return logout();
    case actionTypes.UPDATE_ACCOUNT:
      return updateAccount(state, action);
    case actionTypes.HANDLE_NOTIFICATION_READ:
      return handleNotificationRead(state, action);
    case actionTypes.UPDATE_URL:
      return updateUrl(state, action);
    default:
      return state;
  }
};

export default reducer;
