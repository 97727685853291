import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  signIn,
  signUp,
  userNameInUse,
  emailInUse,
  sendPasswordResetEmail,
} from '../../supabase/queries/auth';
import { CircularProgress } from '@material-ui/core';
import {
  CheckCircleRounded,
  CancelRounded,
  VisibilityRounded,
  VisibilityOffRounded,
} from '@material-ui/icons';
import _ from 'lodash';
import * as actionTypes from '../../store/actions';
import states from '../../shared/states.json';
import Avatar from '../Avatar';

export const Authenticate = React.memo(({ propMode, close }) => {
  /* Variables/State */
  const dispatch = useDispatch();
  const history = useHistory();
  const isOnline = useSelector((state) => state.network.isOnline);
  // const [isLogin, setIsLogin] = useState(login !== null ? login : false);
  const [mode, setMode] = useState(propMode);
  const [step, setStep] = useState(1);
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasCapLet, setHasCapLet] = useState(false);
  const [hasLowerLet, setHasLowerLet] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [logInErr, setLogInErr] = useState(false);
  const [signUpErr, setSignUpErr] = useState(false);
  const [isEmailInUse, setEmailInUse] = useState(false);
  const [isUsernameInUse, setUserNameInUse] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [authData, setAuthData] = useState({
    email: '',
    password: '',
  });
  const [signUpData, setSignUpData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    dateOfBirth: moment().subtract(21, 'years').format('YYYY-MM-DD'),
    userName: '',
    mainUse: '',
    strains: [],
    bio: '',
    state: '',
    avatarUrl: null,
  });
  const under21 = moment().diff(signUpData.dateOfBirth, 'years') < 21;
  const [resetErr, setResetErr] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);
  const [passwordReSuccess, setPasswordReSuccess] = useState(false);

  /* Methods */
  const changeMode = (val) => {
    if (mode === 'forgot' && val === 'login') {
      setPasswordReSuccess(false);
      setResetErr(false);
      setSendingReset(false);
    }
    setMode(val);
  };

  const updateAuthData = (event, field) => {
    event.persist();
    setAuthData((oldData) => {
      let updateData = { ...oldData };
      updateData[field] = event.target.value;
      return updateData;
    });
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setLogInErr(false);
    setIsLoggingIn(true);

    signIn(authData.email.trim().toLowerCase(), authData.password)
      .then((e) => {
        // dispatch user's main use as the experience
        dispatch(actionTypes.setExp(e.mainUse));
        // dispatch current user information
        dispatch(actionTypes.login(e));
        // history push to entrance page
        history.push('/cloud');
        setIsLoggingIn(false);
        close();
      })
      .catch((e) => {
        console.log('e: ', e);
        setIsLoggingIn(false);
        setLogInErr(true);
      });
  };

  const updateSignUpData = (event, field) => {
    event.persist();
    let val = event.target.value;

    // validate password
    if (field === 'password') {
      handleValidatePassword(val);
    }

    // handle limits on text fields
    if (
      (['name', 'email', 'userName'].includes(field) && val.length <= 50) ||
      (field === 'bio' && val.length <= 160) ||
      !['name', 'email', 'userName', 'bio'].includes(field)
    ) {
      setSignUpData((oldData) => {
        let updateData = { ...oldData };
        updateData[field] = val;
        return updateData;
      });
    }
  };
  const handleSignUp = (event) => {
    event.preventDefault();
    setSignUpErr(false);
    setIsSigningUp(true);
    signUp(
      signUpData.name.trim(),
      signUpData.email.trim().toLowerCase(),
      signUpData.dateOfBirth,
      signUpData.userName,
      signUpData.mainUse,
      signUpData.password,
      signUpData.avatarUrl,
      signUpData.bio,
      signUpData.state
    )
      .then((e) => {
        // dispatch user's main use as the experience
        dispatch(actionTypes.setExp(signUpData.mainUse));
        // dispatch current user information
        dispatch(actionTypes.signup(e));
        // history push to entrance page
        history.push('/cloud');
        setIsSigningUp(false);
        close();
      })
      .catch((err) => {
        setSignUpErr(false);
        setIsSigningUp(false);
      });
  };

  const handleNextClicked = async (s) => {
    const isFirst = s === 2;

    // if first next is is clicked
    if (isFirst) {
      setEmailInUse(false);
      setCheckingEmail(true);

      // check if email is in use
      const eInUse = await emailInUse(signUpData.email.trim()).catch((e) => {});
      if (eInUse) {
        setEmailInUse(true);
      } else {
        setStep(s);
      }

      setCheckingEmail(false);
    } else {
      setCheckingUsername(true);
      // call function checking if username is in use
      const uInUse = await userNameInUse(signUpData.userName.trim()).catch(
        (e) => {}
      );

      if (!uInUse) {
        setStep(s);
      } else {
        setUserNameInUse(true);
      }
      setCheckingUsername(false);
    }
  };

  const handlePrevClicked = (s) => {
    setStep(s);
  };
  const handleAddToStrains = (s) => {
    setSignUpData((oldData) => {
      const updateData = { ...oldData };
      const updateArray = _.concat(updateData.strains, s);
      updateData.strains = updateArray;
      return updateData;
    });
  };
  const removeFromStrains = (s) => {
    setSignUpData((oldData) => {
      const updateData = { ...oldData };
      _.remove(updateData.strains, function (n) {
        return n === s;
      });
      return updateData;
    });
  };
  const handleValidatePassword = (pass) => {
    const passArr = [...pass];
    let hasCap = false;
    let hasLow = false;
    let hasNum = false;
    let hasSpecial = false;

    // iterate array of chars
    passArr.forEach((p) => {
      if (hasCap === false && p === p.toUpperCase()) {
        hasCap = true;
      }
      if (hasLow === false && p === p.toLowerCase()) {
        hasLow = true;
      }
      if (hasNum === false && p >= '0' && p <= '9') {
        hasNum = true;
      }
      if (
        hasSpecial === false &&
        !!/[~`!#$%\\^&*+=\-\\[\]\\';,/{}|\\":<>\\?]/g.test(p)
      ) {
        hasSpecial = true;
      }
    });

    // check if at least 8 charaacters
    if (pass.length >= 8) {
      setHasEightChars(true);
    } else {
      setHasEightChars(false);
    }

    setHasCapLet(hasCap);
    setHasLowerLet(hasLow);
    setHasNumber(hasNum);
    setHasSpecial(hasSpecial);
  };

  const setAvatarUrl = (url) => {
    setSignUpData((old) => {
      let update = { ...old };
      update.avatarUrl = url;
      return update;
    });
  };

  const sendEmail = async (event, email) => {
    event.preventDefault();
    setSendingReset(true);
    setResetErr(false);

    try {
      let e = await sendPasswordResetEmail(email.toLowerCase().trim());

      if (e) {
        setPasswordReSuccess(true);
      } else {
        setResetErr(true);
      }
      setSendingReset(false);
    } catch (error) {
      console.log('error: ', error);
      setSendingReset(false);
      setResetErr(true);
    }
  };

  return (
    <div className="flex flex-col space-y-6 font-serif px-5">
      <div className="text-xl font-bold text-center">
        {mode === 'login'
          ? 'Log In To Your Account'
          : mode === 'signup'
          ? 'Create Your Account'
          : 'Reset Password'}
      </div>

      {mode === 'login' && (
        <LoginForm
          handleLogin={handleLogin}
          authData={authData}
          updateAuthData={updateAuthData}
          isLoggingIn={isLoggingIn}
          isOnline={isOnline}
          logInErr={logInErr}
          changeMode={changeMode}
        />
      )}

      {mode === 'signup' && (
        <SignUpForm
          handleSignUp={handleSignUp}
          step={step}
          signUpData={signUpData}
          updateSignUpData={updateSignUpData}
          handleNextClicked={handleNextClicked}
          handlePrevClicked={handlePrevClicked}
          handleAddToStrains={handleAddToStrains}
          removeFromStrains={removeFromStrains}
          hasEightChars={hasEightChars}
          hasCapLet={hasCapLet}
          hasLowerLet={hasLowerLet}
          hasNumber={hasNumber}
          hasSpecial={hasSpecial}
          isSigningUp={isSigningUp}
          isOnline={isOnline}
          signUpErr={signUpErr}
          emailInUse={isEmailInUse}
          userNameInUse={isUsernameInUse}
          checkingEmail={checkingEmail}
          checkingUsername={checkingUsername}
          under21={under21}
          setAvatarUrl={setAvatarUrl}
        />
      )}

      {mode === 'forgot' && (
        <ForgotPasswordForm
          changeMode={changeMode}
          sendEmail={sendEmail}
          resetErr={resetErr}
          sendingReset={sendingReset}
          passwordReSuccess={passwordReSuccess}
          fromAuth={true}
        />
      )}

      {mode === 'login' && (
        <div className="text-sm text-center">
          Don't have an account? Click{' '}
          <span>
            <button
              className="text-green-700 focus:outline-none"
              onClick={() => changeMode('signup')}
            >
              here
            </button>
          </span>{' '}
          to sign up
        </div>
      )}

      {mode === 'signup' && (
        <div className="text-sm text-center">
          Already have an account? Click{' '}
          <span>
            <button
              className="text-green-700 focus:outline-none"
              onClick={() => changeMode('login')}
            >
              here
            </button>
          </span>{' '}
          to log in
        </div>
      )}
    </div>
  );
});

const LoginForm = React.memo((props) => {
  /* Variables */
  const loginDisabled =
    props.isLoggingIn ||
    props.authData.email === null ||
    props.authData.email === '' ||
    props.authData.password === null ||
    props.authData.password === '' ||
    !props.isOnline;
  const [passwordType, setPasswordType] = useState('password');

  /* Methods */
  const handleToggleShowPassword = () => {
    setPasswordType((oldPassType) => {
      if (oldPassType === 'password') {
        return 'text';
      } else {
        return 'password';
      }
    });
  };

  return (
    <form
      className="flex flex-col space-y-6 relative"
      onSubmit={(event) => props.handleLogin(event)}
      autoComplete="true"
    >
      <div className="flex flex-col">
        <div className="text-sm font-bold">Email Address</div>
        <div>
          <input
            className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
            type="email"
            value={props.authData.email}
            onChange={(event) => props.updateAuthData(event, 'email')}
          />
        </div>
      </div>
      <div className="relative flex flex-col">
        <div className="text-sm font-bold">Password</div>
        <div>
          <input
            className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
            type={passwordType}
            value={props.authData.password}
            onChange={(event) => props.updateAuthData(event, 'password')}
          />
        </div>
        <div className="absolute bottom-2 right-2">
          <button
            className="focus:outline-none"
            onClick={() => handleToggleShowPassword()}
            type="button"
          >
            {passwordType === 'password' ? (
              <VisibilityRounded className="text-green-500" />
            ) : (
              <VisibilityOffRounded className="text-gray-500" />
            )}
          </button>
        </div>
      </div>
      <div className="flex justify-end">
        <div>
          <button
            className="text-sm font-bold text-green-700 focus:outline-none"
            type="button"
            onClick={() => props.changeMode('forgot')}
          >
            Forgot password?
          </button>
        </div>
      </div>
      {props.logInErr && (
        <div className="absolute text-sm font-bold text-red-600 bottom-11 left-6">
          Incorrect email or password.
        </div>
      )}
      <div>
        <button
          className={`${
            loginDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-600'
          } w-full py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
          type="submit"
          disabled={loginDisabled}
        >
          <div className="flex space-x-2 justify-center items-center">
            {props.isLoggingIn && (
              <div>
                <CircularProgress size={10} />
              </div>
            )}
            <div>{props.isLoggingIn ? 'Logging In' : 'Log In'}</div>
          </div>
        </button>
      </div>
    </form>
  );
});

const SignUpForm = React.memo((props) => {
  /* Variables */
  const isEmailValid =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      props.signUpData.email
    );
  const stepOneNextDisabled =
    props.signUpData.name === null ||
    props.signUpData.name === '' ||
    props.signUpData.email === null ||
    props.signUpData.email === '' ||
    props.month === null ||
    props.month === '' ||
    props.year === null ||
    props.year === '' ||
    props.day === null ||
    props.day === '' ||
    !isEmailValid ||
    props.under21 ||
    props.checkingEmail;
  const stepTwoNextDisabled =
    props.signUpData.userName === null ||
    props.signUpData.userName === '' ||
    props.signUpData.mainUse === null ||
    props.signUpData.mainUse === '' ||
    props.checkingUsername ||
    states.findIndex((s) => s.name === props.signUpData.state) === -1;
  const [passwordType, setPasswordType] = useState('password');
  const passwordMatch =
    props.signUpData.password === props.signUpData.confirmPassword;
  const signupDisabled =
    props.signUpData.password === null ||
    props.signUpData.password === '' ||
    props.signUpData.confirmPassword === null ||
    props.signUpData.confirmPassword === '' ||
    !props.hasEightChars ||
    !props.hasCapLet ||
    !props.hasLowerLet ||
    !props.hasNumber ||
    !props.hasSpecial ||
    !passwordMatch ||
    props.isSigningUp ||
    !props.isOnline;

  /* methods */
  const handleToggleShowPassword = () => {
    setPasswordType((oldPassType) => {
      if (oldPassType === 'password') {
        return 'text';
      } else {
        return 'password';
      }
    });
  };

  return (
    <form
      className="flex flex-col space-y-6"
      onSubmit={(event) => props.handleSignUp(event)}
      autoComplete="true"
    >
      <div className="font-black">{`Step ${props.step} of 3`}</div>
      {props.step === 1 && (
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="text-sm font-bold">Name</div>
              <div className="text-sm text-gray-600">{`${
                props.signUpData.name ? props.signUpData.name.length : '0'
              }/50`}</div>
            </div>
            <div>
              <input
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                type="text"
                value={props.signUpData.name}
                onChange={(event) => props.updateSignUpData(event, 'name')}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="text-sm font-bold">Email Address</div>
              <div className="text-sm text-gray-600">{`${
                props.signUpData.email ? props.signUpData.email.length : '0'
              }/50`}</div>
            </div>
            <div className="relative">
              <input
                className={`w-full p-2 text-black rounded-lg border ${
                  props.emailInUse ? 'border-red-500' : 'border-black'
                } focus:outline-none`}
                type="email"
                value={props.signUpData.email}
                onChange={(event) => props.updateSignUpData(event, 'email')}
              />
              {props.signUpData.email !== null &&
                props.signUpData.email !== '' && (
                  <div className="absolute top-2 right-3">
                    {isEmailValid && !props.emailInUse ? (
                      <CheckCircleRounded
                        fontSize="small"
                        className="text-green-500"
                      />
                    ) : (
                      <CancelRounded
                        fontSize="small"
                        className="text-red-500"
                      />
                    )}
                  </div>
                )}
              {props.emailInUse && (
                <div className="absolute -bottom-6 md:left-28 font-bold text-red-500 text-sm">
                  This email address is already in use.
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col relative">
            <div className="text-sm font-bold">Date of Birth</div>
            <div className="text-sm text-gray-500">
              This will NOT be shown publicly. Confirm your age so you enjoy
              Cloud 9 legally.
            </div>
            <div>
              <input
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                type="date"
                value={props.signUpData.dateOfBirth}
                onChange={(event) =>
                  props.updateSignUpData(event, 'dateOfBirth')
                }
                max={moment().subtract(21, 'years').format('YYYY-MM-DD')}
              />
            </div>
            {props.under21 && (
              <div className="absolute -bottom-5 w-full">
                <div className="text-sm font-bold text-center text-red-600">
                  Must be 21+ to use Match Buds.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {props.step === 2 && (
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col items-center">
            <Avatar
              url={props.signUpData.avatarUrl}
              name={props.signUpData.name}
              canEdit={true}
              fromSignup={true}
              setSignupAvatar={props.setAvatarUrl}
              style={`rounded-full h-32 w-32 text-white text-xs font-bold flex items-center justify-center bg-green-500 text-xl`}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="text-sm font-bold">Username</div>
              <div className="text-sm text-gray-600">{`${
                props.signUpData.userName
                  ? props.signUpData.userName.length
                  : '0'
              }/50`}</div>
            </div>
            <div className="text-sm text-gray-500">
              This will be your unique user handle. Other buds can tag you using
              this username.
            </div>
            <div className="relative">
              <input
                className={`w-full pl-8 p-2 text-black rounded-lg border ${
                  props.userNameInUse ? 'border-red-500' : 'border-black'
                } focus:outline-none`}
                type="text"
                value={props.signUpData.userName}
                onChange={(event) => props.updateSignUpData(event, 'userName')}
              />
              <div className="absolute left-1 bottom-2.5 text-xl font-bold text-green-600">
                @:
              </div>
              {props.userNameInUse && (
                <div className="absolute -bottom-6 md:left-32 font-bold text-red-500 text-sm">
                  This username is already in use.
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-bold">Main Use of Marijuana</div>
            <div className="text-sm text-gray-500">
              What is your main use of marijuana? You'll be able to switch
              through experiences but this will be your default experience in
              Cloud 9.
            </div>
            <div>
              <select
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                onChange={(event) => props.updateSignUpData(event, 'mainUse')}
                value={props.signUpData.mainUse}
              >
                <option value=""></option>
                <option value="Recreational">Recreational</option>
                <option value="Medicinal">Medicinal</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-bold">Which State are you in?</div>
            <div>
              <input
                list="states"
                id="state-choice"
                name="state-choice"
                placeholder="Select State"
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                value={props.signUpData.state ? props.signUpData.state : ''}
                onChange={(event) => props.updateSignUpData(event, 'state')}
                required
              />
              <datalist id="states">
                {states.map((s) => (
                  <option key={s.abbreviation} value={s.name}></option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="text-sm font-bold">Bio</div>
              <div className="text-sm text-gray-600">{`${
                props.signUpData.bio ? props.signUpData.bio.length : '0'
              }/160`}</div>
            </div>
            <div>
              <textarea
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                type="text"
                placeholder="Tell us about yourself. This is completely optional!"
                value={props.signUpData.bio ? props.signUpData.bio : ''}
                onChange={(event) => props.updateSignUpData(event, 'bio')}
              />
            </div>
          </div>
        </div>
      )}
      {props.step === 3 && (
        <div className="flex flex-col space-y-6 relative">
          <div className="relative flex flex-col">
            <div className="text-sm font-bold">Create Password</div>
            <div>
              <input
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                type={passwordType}
                value={props.signUpData.password}
                onChange={(event) => props.updateSignUpData(event, 'password')}
              />
            </div>
            <div className="absolute bottom-2 right-2">
              <button
                className="focus:outline-none"
                onClick={() => handleToggleShowPassword()}
                type="button"
              >
                {passwordType === 'password' ? (
                  <VisibilityRounded className="text-green-500" />
                ) : (
                  <VisibilityOffRounded className="text-gray-500" />
                )}
              </button>
            </div>
          </div>
          <div className="relative flex flex-col space-y-2">
            <PasswordCheck
              string={'Must be at least 8 characters long'}
              passed={props.hasEightChars}
            />
            <PasswordCheck
              string={'Must have at least 1 capital letter'}
              passed={props.hasCapLet}
            />
            <PasswordCheck
              string={'Must have at least 1 lower case letter'}
              passed={props.hasLowerLet}
            />
            <PasswordCheck
              string={'Must have at least 1 number'}
              passed={props.hasNumber}
            />
            <PasswordCheck
              string={'Must have at least 1 special character'}
              passed={props.hasSpecial}
            />
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-bold">Confirm Password</div>
            <div>
              <input
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                type="password"
                value={props.signUpData.confirmPassword}
                onChange={(event) =>
                  props.updateSignUpData(event, 'confirmPassword')
                }
              />
            </div>
          </div>
          {props.signUpErr && (
            <div className="absolute text-sm font-bold text-red-600 -bottom-5 left-1/4">
              There has been an issue, please refresh your page and try again.
            </div>
          )}
        </div>
      )}
      <div>
        {props.step === 1 && (
          <button
            className={`${
              stepOneNextDisabled
                ? 'bg-gray-500 cursor-not-allowed'
                : 'bg-green-600'
            } float-right py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
            type="button"
            disabled={stepOneNextDisabled}
            onClick={() => props.handleNextClicked(2)}
          >
            {props.checkingEmail ? 'Validating Email' : 'Next'}
          </button>
        )}
        {props.step === 2 && (
          <div className="flex w-full justify-between">
            <button
              className={`bg-green-600 py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
              type="button"
              onClick={() => props.handlePrevClicked(1)}
            >
              Prev
            </button>
            <button
              className={`${
                stepTwoNextDisabled
                  ? 'bg-gray-500 cursor-not-allowed'
                  : 'bg-green-600'
              } float-right py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
              type="button"
              onClick={() => props.handleNextClicked(3)}
              disabled={stepTwoNextDisabled}
            >
              {props.checkingUsername ? 'Validating Username' : 'Next'}
            </button>
          </div>
        )}
        {props.step === 3 && (
          <div className="flex w-full justify-between">
            <button
              className={`bg-green-600 py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
              type="button"
              onClick={() => props.handlePrevClicked(2)}
            >
              Prev
            </button>
            <button
              className={`${
                signupDisabled
                  ? 'bg-gray-500 cursor-not-allowed'
                  : 'bg-green-600'
              } float-right py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
              type="submit"
              disabled={signupDisabled}
            >
              <div className="flex space-x-2 justify-center items-center">
                {props.isSigningUp && (
                  <div>
                    <CircularProgress size={10} />
                  </div>
                )}
                <div>{props.isSigningUp ? 'Signing Up' : 'Sign Up'}</div>
              </div>
            </button>
          </div>
        )}
      </div>
    </form>
  );
});

export const PasswordCheck = (props) => {
  return (
    <div className="flex space-x-2 items-center">
      <div>
        <CheckCircleRounded
          fontSize="small"
          className={`${props.passed ? 'text-green-500' : 'text-gray-500'}`}
        />
      </div>
      <div
        className={`${
          props.passed ? 'text-green-500' : 'text-gray-500'
        } text-sm font-serif font-bold`}
      >
        {props.string}
      </div>
    </div>
  );
};

export const ForgotPasswordForm = React.memo((props) => {
  /* Variables */
  const [email, setEmail] = useState('');
  const isEmailValid =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    );
  const resetDisabled = email === '' || !isEmailValid || props.sendingReset;

  /* Methods */

  /* JSX */
  return (
    <form
      className="flex flex-col space-y-6 pb-5"
      onSubmit={(event) => props.sendEmail(event, email)}
      autoComplete="true"
    >
      {!props.fromAuth && (
        <div className="text-xl font-bold text-center">
          Link No Longer Valid. Enter Email Again
        </div>
      )}
      {props.passwordReSuccess && (
        <div className="flex flex-col">
          <div className="text-center text-lg font-bold">{`A password reset email has been successfully sent to ${email
            .toLowerCase()
            .trim()}, please follow the link to reset your password.`}</div>
        </div>
      )}
      {!props.passwordReSuccess && (
        <div className="flex flex-col">
          <div className="text-sm font-bold">Email Address</div>
          <div>
            <input
              className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
        </div>
      )}
      {props.resetErr && (
        <div className="text-sm text-center">
          There is no account with this email. Please try again or sign up.
        </div>
      )}
      <div className="flex justify-between">
        {props.fromAuth && (
          <button
            type="button"
            className="bg-red-500 py-2 px-6 rounded-full text-white focus:outline-none shadow-xl"
            onClick={() => props.changeMode('login')}
          >
            Back to Login
          </button>
        )}
        {!props.passwordReSuccess && (
          <button
            className={`${
              resetDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-600'
            } flex space-x-2 justify-center items-center py-2 px-6 rounded-full text-white focus:outline-none shadow-xl ${
              props.fromAuth ? '' : 'w-full'
            }`}
            type="submit"
            disabled={resetDisabled}
          >
            {props.sendingReset && (
              <div>
                <CircularProgress size={10} />
              </div>
            )}
            <div>{props.sendingReset ? 'Sending Email' : 'Send Email'}</div>
          </button>
        )}
      </div>
    </form>
  );
});
