import React, { useState } from 'react';
import useQuery from '../../../hooks/useQuery';
import { SearchSection } from './SearchSection';
import { CSSTransition } from 'react-transition-group';
import { BasicInfoSection } from './BasicInfoSection';
import { LawSection } from './LawSection';
import { DispensarySection } from './DispensarySection';
import LearnIntro from './LearnIntro';
import { getState } from '../../../supabase/queries/learn';

export const LocationInformation = React.memo(() => {
  // if there's a state query then get its value
  const query = useQuery();
  const stateFromUrl = query.get('state');

  /**variables**/
  const [selectedState, setSelectedState] = useState(
    stateFromUrl === null ? '' : stateFromUrl
  );
  const [state, setState] = useState(null);

  const [fetching, setFetching] = useState(true);

  /**methods**/
  const handleSubmit = async (search) => {
    setFetching(true);

    // tryna decide if this should be a document query or api callout
    setSelectedState(search);
    setState(await fetchState(search));

    setFetching(false);
  };

  const fetchState = async (name) => {
    let s = null;

    await getState(name)
      .then((res) => {
        if (res.length > 0) {
          s = res[0];
        }
      })
      .catch((err) => {
        console.log('err: ', err);
      });

    return s;
  };

  const stateCreateUpdate = (s) => {
    setState(s);
  };

  /**display**/
  return (
    <div className="flex flex-col space-y-6">
      <LearnIntro />
      {/* top section will accept state/zip code information from user */}
      <SearchSection selectedState={selectedState} submit={handleSubmit} />
      <CSSTransition
        in={selectedState !== '' && selectedState !== null}
        timeout={1000}
        unmountOnExit
      >
        <div className="flex flex-col py-6 space-y-6">
          <BasicInfoSection
            fetching={fetching}
            state={state}
            name={selectedState}
            stateCreateUpdate={stateCreateUpdate}
          />
          {state && <LawSection state={state} />}
          {state && <DispensarySection state={state} />}
        </div>
      </CSSTransition>
    </div>
  );
});
