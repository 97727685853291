import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actionTypes from './store/actions';
import LoadingScreen from './components/LoadingScreen';
import Home from './views/HomeView';
import About from './views/AboutView';
import Learn from './views/LearnView';
import WhatIsCloud9 from './views/CloudView';
import Terms from './views/TermsView';
import Privacy from './views/PrivacyView';
import Cloud9Layout from './Cloud9Layout';
import { useSnackbar } from 'notistack';

const Routes = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // listen for online status changing
  window.addEventListener('online', () => {
    displaySnackBar('You have been reconnected to the internet.', 'success');
    dispatch(actionTypes.updateNetworkStatus(true));
  });

  // now we listen for network status changes
  window.addEventListener('offline', () => {
    displaySnackBar('You have been disconnected from the internet.', 'error');
    dispatch(actionTypes.updateNetworkStatus(false));
  });

  const displaySnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  history.listen(() => {
    dispatch(actionTypes.clearSelectedPost());
    window.scrollTo(0, 0);
  });

  // create routes config
  const routesConfig = [
    {
      exact: true,
      path: '/',
      component: () => <Redirect to="/home" />,
    },
    {
      exact: true,
      path: '/about',
      component: About,
    },
    {
      exact: true,
      path: '/learn',
      component: Learn,
    },
    {
      exact: true,
      path: '/cloud9',
      component: WhatIsCloud9,
    },
    {
      exact: true,
      path: '/terms',
      component: Terms,
    },
    {
      exact: true,
      path: '/privacy',
      component: Privacy,
    },
    {
      path: '/cloud',
      layout: Cloud9Layout,
      routes: [
        {
          exact: true,
          path: '/cloud',
          component: lazy(() => import('./views/Cloud9Views/CloudHome')),
        },
        {
          exact: true,
          path: '/cloud/notifications',
          component: lazy(() => import('./views/Cloud9Views/Notifications')),
        },
        {
          exact: true,
          path: '/cloud/learn',
          component: lazy(() => import('./views/Cloud9Views/Learn')),
        },
        {
          exact: true,
          path: '/cloud/buds',
          component: lazy(() => import('./views/Cloud9Views/Buds')),
        },
        {
          // exact: true,
          path: '/cloud/profile/:username',
          component: lazy(() => import('./views/Cloud9Views/Profile')),
        },
      ],
    },
    {
      path: '*',
      routes: [
        {
          // exact: true,
          path: '/home',
          component: Home,
        },
        {
          component: () => <Redirect to="/home" />,
        },
      ],
    },
  ];

  const renderRoutes = (routes) => {
    return routes ? (
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      </Suspense>
    ) : null;
  };

  return renderRoutes(routesConfig);
};

export default Routes;
