import supabase from '../api';
import moment from 'moment';

export const getUserRequests = async (userId, lastCreatedAt, fetchLimit) => {
  const query =
    lastCreatedAt === null
      ? `and(user.eq.${userId})`
      : `and(user.eq.${userId},createdAt.lt.${lastCreatedAt})`;

  // fetch requests for user
  const { data: requests, error } = await supabase
    .from('requests')
    .select('*, user(*), from(*)')
    .or(query)
    .order('createdAt', { ascending: false })
    .limit(fetchLimit);

  // if there's an error then throw it
  if (error) {
    throw error;
  }

  // if reqs fetch is successful then return it
  if (requests) {
    return requests;
  }
};

/**
 * method to accept a bud request
 * @param {string} respondingUserId uuid of user responding
 * @param {string} sentUserId uuid of user that sent request
 * @param {string} budId uuid of request itself
 * @returns promise array
 */
export const acceptBudRequest = (respondingUserId, sentUserId, budId) => {
  // promise array comprised of update to bud relationship, update to original request, creation of new request
  const promiseArray = [
    supabase
      .from('buds')
      .update({
        lastAction: 'buds',
        becameBuds: moment().unix(),
      })
      .eq('id', budId),
    supabase
      .from('requests')
      .update({
        actionTaken: 'accepted',
      })
      .eq('budId', budId),
    supabase.from('requests').insert([
      {
        user: sentUserId,
        from: respondingUserId,
        type: 'follow_accept',
        budId: budId,
      },
    ]),
    supabase
      .from('accounts')
      .update({ newBudRequests: true })
      .eq('id', sentUserId),
  ];

  try {
    return Promise.all(promiseArray);
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

/**
 * method to decline a bud request
 * @param {string} budId uuid of request
 * @returns promise array or error thrown
 */
export const declineBudRequest = (budId) => {
  // promise array comprised of deletion of bud request and request
  const promiseArray = [
    supabase.from('buds').delete().eq('id', budId),
    supabase.from('requests').delete().eq('budId', budId),
  ];

  try {
    return Promise.all(promiseArray);
  } catch (error) {
    throw error;
  }
};
