import React from 'react';
import { FancyHeader } from '../../components/FancyHeader';
import Girls from '../../assets/images/girls.png';
import WebAppOne from '../../assets/images/wepAppOne.png';
import WebAppTwo from '../../assets/images/webAppTwo.png';
import WebAppThree from '../../assets/images/webAppThree.png';
import Leaf from '../../assets/icons/leaf.png';

export const SecondSection = React.memo(() => {
  return (
    <div className="py-20 relative overflow-hidden">
      <div className="absolute transform top-80 rotate-45 -left-36 z-0 opacity-20">
        <img alt="leaf" src={Leaf} className="img-fluid" />
      </div>
      <div className="absolute transform bottom-1/4 -rotate-45 -right-36 z-0 opacity-20">
        <img alt="leaf" src={Leaf} className="img-fluid" />
      </div>
      <div className="container mx-auto grid place-items-center px-4 md:px-0 space-y-10 gap-8">
        <div className="w-full flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0">
          <div className="flex-1 flex flex-col space-y-8">
            <div className="">
              <FancyHeader text={'What is Cloud 9?'} color={'dark'} />
            </div>
            <div className="font-serif text-xl w-auto lg:w-8/12">
              Cloud 9 is a social platform on Match Buds which enables different
              Marijuana enthusiasts to connect with each other. Here you can
              connect with different people (buds) who use the Marijuana for
              different experiences, take part in various sub communities (i.e.
              cultivation), and follow your favorite strains and manufacturers.
            </div>
          </div>
          <div className="self-end lg:self-center z-50">
            <img className="img-fluid" alt="girls" src={Girls} />
          </div>
        </div>
        <div className="">
          <FancyHeader text={'Cloud 9 Features'} color={'dark'} />
        </div>
        <div className="w-full flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0">
          <div className="flex-1 flex flex-col space-y-8">
            <div className="font-serif text-3xl font-bold">Home Page</div>
            <div className="font-serif text-xl w-auto lg:w-8/12">
              This page keeps you and your buds updated on your recent
              activities. You can intereact with them on the timeline and vice
              versa.
            </div>
          </div>
          <div className="flex-auto lg:flex-1 self-end lg:self-center">
            <img className="img-fluid" alt="WebAppOne" src={WebAppOne} />
          </div>
        </div>
        <div className="w-full flex flex-col-reverse lg:flex-row items-center space-y-8 lg:space-y-0">
          <div className="flex-1 self-end lg:self-center z-50 pt-8 lg:pt-0">
            <img className="img-fluid" alt="WebAppTwo" src={WebAppTwo} />
          </div>
          <div className="flex-1 flex flex-col justify-end space-y-8">
            <div className="font-serif text-3xl font-bold self-end text-right">
              Switch Between Experiences
            </div>
            <div className="font-serif text-xl w-auto lg:w-8/12 self-end text-right">
              You can switch between recreational and medicinal experience
              whenever you like. This also influences the type of content you
              see on your feed and timeline
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0">
          <div className="flex-1 flex flex-col space-y-8">
            <div className="font-serif text-3xl font-bold">Match Up!</div>
            <div className="font-serif text-xl w-auto lg:w-8/12">
              Connect with like minded Cannabis consumers in the Cloud 9 or to
              marijuana strains and use the MatchBuds feature to matchup!
            </div>
          </div>
          <div className="flex-1 self-end lg:self-center">
            <img className="img-fluid" alt="WebAppThree" src={WebAppThree} />
          </div>
        </div>
      </div>
    </div>
  );
});
