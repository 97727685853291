import * as actionTypes from '../actions/actionTypes';

const initState = 'Recreational';

const setExp = (action) => {
  return action.exp;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_EXP:
      return setExp(action);
    default:
      return state;
  }
};

export default reducer;
