import React, { useState, useCallback, useRef, useEffect } from 'react';
import { getInitials } from '../utils';
import { Image } from 'cloudinary-react';
import { updateAccount } from '../supabase/queries/auth';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../store/actions';
import { uploadAvatar, deleteImage } from '../cloudinary';
import { Modal } from './Modal';
import { CircularProgress } from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const Avatar = React.memo(
  ({ url, name, id, canEdit, style, fromSignup, setSignupAvatar }) => {
    const [avatarUrl, setAvatarUrl] = useState(url);
    const [uploading, setUploading] = useState(false);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(false);
    const dispatch = useDispatch();

    const handleUpload = async (img) => {
      setUploading(true);

      try {
        // add new avatar upload to array
        const promiseArr = [uploadAvatar(img)];

        // if url exists then add deletion to array
        if (avatarUrl) {
          promiseArr.push(deleteImage(avatarUrl));
        }

        await Promise.all(promiseArr).then(async (res) => {
          const newAvatarUrl = res[0].data.public_id;

          if (fromSignup) {
            setSignupAvatar(newAvatarUrl);
          } else {
            await updateAccount(id, { avatarUrl: newAvatarUrl });
          }
          setAvatarUrl(newAvatarUrl);
          dispatch(actionTypes.updateUrl(newAvatarUrl));
        });
      } catch (error) {
        console.log('error: ', error);
      }

      setUploading(false);
    };

    const onSelectFile = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () =>
            // this.setState({ src: reader.result })
            setFile(reader.result),
          setOpen(true)
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    const handleClose = () => {
      setOpen(false);
      setFile(null);
    };

    return (
      <div className={style}>
        <label
          htmlFor="changeAvatar"
          className="w-full h-full rounded-full bg-transparent flex items-center justify-center cursor-pointer"
        >
          {avatarUrl ? (
            <Image
              cloudName="match-buds"
              publicId={avatarUrl}
              style={{ maxHeight: '100%' }}
              className="rounded-full"
            />
          ) : (
            <div>{getInitials(name)}</div>
          )}
        </label>
        <input
          className="hidden"
          type="file"
          id="changeAvatar"
          accept="image/*"
          onChange={onSelectFile}
          disabled={!canEdit || uploading}
        />
        <Modal
          isOpen={open}
          handleClose={() => handleClose()}
          content={
            <ImageCrop
              uploading={uploading}
              close={() => handleClose()}
              handleUpload={(imgFile) => handleUpload(imgFile)}
              file={file}
            />
          }
        />
      </div>
    );
  }
);

const ImageCrop = React.memo(({ uploading, close, handleUpload, file }) => {
  const isOnline = useSelector((state) => state.network.isOnline);
  const exp = useSelector((state) => state.experience);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const confirmButtonDisabled = !isOnline || uploading;
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 1 / 1,
    width: 200,
    height: 200,
  });

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const getCroppedImg = () => {
    return new Promise((resolve, reject) => {
      previewCanvasRef.current.toBlob((f) => {
        f.name = 'temp.jpg';
        f.lastModifiedDate = new Date();
        resolve(f);
      }, 'image/jpeg');
    });
  };

  const upload = async () => {
    const imgBlob = await getCroppedImg();
    const imgFile = new File([imgBlob], 'filename');
    await handleUpload(imgFile);
    close();
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <div className="flex flex-col space-y-5 items-center">
      {file && (
        <div>
          <ReactCrop
            src={file}
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            circularCrop
            minWidth={200}
            minHeight={200}
            maxWidth={500}
            maxHeight={500}
            onComplete={(newCrop) => setCompletedCrop(newCrop)}
            onImageLoaded={onLoad}
          />
        </div>
      )}
      <div className="hidden">
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
      <div className="flex justify-between items-center w-full">
        <button
          className="bg-red-500 py-2 px-5 rounded-full flex focus:outline-none text-lg font-bold text-white antialiased"
          type="button"
          onClick={() => close()}
        >
          Cancel
        </button>
        <button
          className={`${
            confirmButtonDisabled
              ? 'bg-gray-500 cursor-not-allowed'
              : exp === 'Recreational'
              ? 'bg-green-500'
              : 'bg-yellow-400'
          } py-2 px-5 rounded-full flex focus:outline-none`}
          type="button"
          disabled={confirmButtonDisabled}
          onClick={() => upload()}
        >
          <div className="flex space-x-2 justify-center items-center text-lg font-bold text-white antialiased">
            {uploading && (
              <div>
                <CircularProgress size={10} />
              </div>
            )}
            <div>{uploading ? 'Uploading' : 'Upload'}</div>
          </div>
        </button>
      </div>
    </div>
  );
});

export default Avatar;
