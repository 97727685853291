import React, { useEffect, useState } from 'react';
import MatchSuggestion from './MatchSuggestion';
import { RefreshRounded } from '@material-ui/icons';
import { getBudSuggestions } from '../../supabase/queries/buds';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const SuggestBuds = React.memo(() => {
  /* Variables */
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const account = useSelector((state) => state.auth.account);
  const exp = useSelector((state) => state.experience);

  /* useEffect to fetch users */
  useEffect(() => {
    getBudSuggestions(account.id)
      .then((res) => {
        setSuggestions(res);
        setLoading(false);
      })
      .catch((e) => {
        console.log('e: ', e);
        setLoading(false);
      });
  }, [account.id]);

  /* Methods */
  const handleRefresh = () => {
    getBudSuggestions(account.id)
      .then((res) => {
        setSuggestions(res);
        setLoading(false);
      })
      .catch((e) => {
        console.log('e: ', e);
        setLoading(false);
      });
  };

  return (
    <div className="bg-white rounded-lg p-5 shadow">
      <div className="flex flex-col space-y-5">
        <div className="flex items-center justify-between border-b border-gray-300 pb-5">
          <div className="font-bold">Match Suggestions</div>
          <div>
            <button
              className="focus:outline-none"
              onClick={() => handleRefresh()}
            >
              <RefreshRounded
                className={`${
                  exp === 'Recreational' ? 'text-green-500' : 'text-yellow-500'
                }`}
              />
            </button>
          </div>
        </div>
        {loading && (
          <div className="grid justify-items-center">
            <CircularProgress size={20} color="secondary" />
          </div>
        )}
        {!loading &&
          suggestions.length !== 0 &&
          suggestions.map((s) => (
            <MatchSuggestion
              key={s.id}
              id={s.id}
              name={s.name}
              userName={s.userName}
              avatarUrl={s.avatarUrl}
            />
          ))}
      </div>
    </div>
  );
});

export default SuggestBuds;
