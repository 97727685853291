import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AutoTextArea from './AutoTextArea';
import { CircularProgress } from '@material-ui/core';
import Avatar from './Avatar';
import PostMessage from './PostMessage';

const ActionQuoteBody = React.memo(({ props }) => {
  const exp = useSelector((state) => state.experience);
  const account = useSelector((state) => state.auth.account);

  return (
    <div className="flex flex-col space-y-10" id="ActionQuoteBody">
      <div className="flex space-x-2 items-start">
        <div className="flex-initial">
          <Avatar
            url={account.avatarUrl}
            name={account.name}
            id={account.id}
            canEdit={false}
            style={`rounded-full h-12 w-12 text-white font-bold flex items-center justify-center ${
              exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
            }`}
          />
        </div>
        <div className="flex flex-col space-y-4 w-full">
          <div>
            <AutoTextArea
              className="w-full"
              placeholder={'Add a comment'}
              value={props.postText}
              onChange={props.handleTextChange}
            />
          </div>
          <div className="flex flex-col rounded-lg border-gray-400 border-2 p-2">
            <div className="flex space-x-1 lg:space-x-2 items-start lg:items-center">
              <Avatar
                url={props.avatarUrl}
                name={props.name}
                id={props.id}
                canEdit={false}
                style={`rounded-full h-6 w-6 text-white text-xs font-bold flex items-center justify-center ${
                  exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
                }`}
              />
              <div className="font-bold text-xs">{props.name}</div>
              <div className="text-gray-700 text-xs font-semibold">
                @{props.userName}
              </div>
              <div className="text-gray-700 text-xs font-semibold">•</div>
              <div className="text-gray-700 text-xs font-semibold">
                {moment(props.time).fromNow()}
              </div>
            </div>
            <PostMessage message={props.message} />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex space-x-2 items-center">
          <div>{240 - props.postText.length}</div>
          <div>
            <button
              className={`${
                props.postButtonDisabled
                  ? 'bg-gray-500 cursor-not-allowed'
                  : exp === 'Recreational'
                  ? 'bg-green-500'
                  : 'bg-yellow-400'
              } py-2 px-5 rounded-full flex focus:outline-none`}
              type="button"
              disabled={props.postButtonDisabled}
              onClick={() => props.repost()}
            >
              <div className="flex space-x-2 justify-center items-center text-lg font-bold text-white antialiased">
                {props.posting && (
                  <div>
                    <CircularProgress size={10} />
                  </div>
                )}
                <div>{props.posting ? 'Posting' : 'Post'}</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ActionQuoteBody;
