import React from 'react';
import { WebFooter } from '../../components/WebFooter';
import { WebNavBar } from '../../components/WebNavbar';
import { FirstSection } from './FirstSection';
// import { FourthSection } from "./FourthSection";
import { SecondSection } from './SecondSection';
// import { ThirdSection } from "./ThirdSection";

const HomeView = React.memo(() => {
  return (
    <div className="">
      <WebNavBar />
      <FirstSection />
      <SecondSection />
      {/* <ThirdSection />
      <FourthSection /> */}
      <WebFooter />
    </div>
  );
});

export default HomeView;
