import React, { useState, useEffect, useRef } from 'react';
import { SearchRounded, CancelRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getUsersByTextSearch } from '../../supabase/queries/buds';
import Avatar from '../Avatar';

const SearchBar = React.memo(({ mobile, mobileClose }) => {
  /* Variables */
  const isOnline = useSelector((state) => state.network.isOnline);
  const [menu, setMenu] = useState(false);
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [users, setUsers] = useState([]);
  const timeoutRef = useRef(null);
  const history = useHistory();

  /* Methods */
  const toggleMenu = (open) => {
    setMenu(open);
  };

  const handleSearch = (event) => {
    event.persist();
    const text = event.target.value;
    setSearch(text);
  };

  useEffect(() => {
    if (isOnline) {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        if (search.length >= 3) {
          setSearching(true);
          getUsersByTextSearch(search)
            .then((res) => {
              setUsers(res);
              setSearching(false);
            })
            .catch((err) => {
              console.log('err.message: ', err.message);
              setSearching(false);
            });
        } else {
          setSearching(false);
          setUsers([]);
        }
      }, 500);
    }
  }, [search, isOnline]);

  const handleUserClicked = (userName) => {
    setSearch('');
    toggleMenu(false);
    if (mobile) {
      mobileClose();
    }
    history.push(`/cloud/profile/${userName}`);
  };

  const close = () => {
    setSearch('');
    if (mobile) {
      mobileClose();
    }
    toggleMenu(false);
  };

  return (
    <div className="static">
      <div
        className={`flex items-center ${
          menu ? 'justify-between' : 'space-x-2'
        } py-1 bg-gray-100 rounded-md px-5 ${mobile ? 'w-full' : 'w-64'}`}
      >
        <div className="">
          <SearchRounded fontSize="small" />
        </div>
        <div className="">
          <input
            className="focus:outline-none bg-transparent"
            placeholder="Search MatchBuds"
            onFocus={() => toggleMenu(true)}
            onChange={(event) => handleSearch(event)}
            value={search}
          />
        </div>
        {menu && (
          <button className="focus:outline-none" onClick={close}>
            <CancelRounded fontSize="small" />
          </button>
        )}
      </div>
      {menu && (
        <div
          className={`flex flex-col items-center space-y-2 py-1 shadow-lg bg-white rounded-md px-5 absolute ${
            mobile ? 'w-96' : 'w-64'
          } h-80 top-12 z-10`}
        >
          {search === '' && !searching && (
            <div className="text-sm font-bold text-center">
              Try searching for people
            </div>
          )}
          {searching && <CircularProgress size={20} color="secondary" />}
          {!searching && search !== '' && users.length === 0 && (
            <div className="text-sm font-bold text-center">No users found</div>
          )}
          {!searching &&
            users.length !== 0 &&
            users.map((user) => (
              <User
                key={user.id}
                user={user}
                handleUserClicked={handleUserClicked}
              />
            ))}
        </div>
      )}
    </div>
  );
});

const User = React.memo(({ user, handleUserClicked }) => {
  const exp = useSelector((state) => state.experience);

  return (
    <div
      to={`/cloud/profile/${user.userName}`}
      className="flex w-full items-center space-x-2 cursor-pointer"
      onClick={() => handleUserClicked(user.userName)}
    >
      <Avatar
        url={user.avatarUrl}
        name={user.name}
        id={user.id}
        canEdit={false}
        style={`rounded-full h-8 w-8 text-white font-bold flex items-center justify-center ${
          exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
        }`}
      />
      <div className="flex flex-col items-start space-x-1">
        <div className="font-bold text-sm">{`${user.name}`}</div>
        <div className="text-gray-700 text-xs font-semibold">
          @{`${user.userName}`}
        </div>
      </div>
    </div>
  );
});

export default SearchBar;
