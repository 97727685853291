import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/logo.png';
import { Menu } from '@material-ui/icons';
import { Drawer } from '@material-ui/core';
import { Modal } from '../../components/Modal';
import moment from 'moment';
import * as actionTypes from '../../store/actions';
import { useSnackbar } from 'notistack';
import { Authenticate } from '../Authenticate';
import { signOut } from '../../supabase/queries/auth';

const showModalAlgo = (over21, dispatch) => {
  // if there is an over21expiry key in localstorage then get its value
  const over21expiry = localStorage.getItem('over21expiry');

  if (over21) {
    return false;
  } else {
    if (over21expiry) {
      // if over21expiry value is in the past then dispatch resetOver21 and return true
      if (moment.unix(over21expiry).isBefore(moment())) {
        dispatch(actionTypes.resetOver21());
        return true;
      } else {
        // if over21expiry is in the future then return false
        return false;
      }
    } else {
      // if there isn't then return true
      return true;
    }
  }
};

export const WebNavBar = React.memo(() => {
  /** Variables */
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const over21 = useSelector((state) => state.over21);
  const auth = useSelector((state) => state.auth);
  const loggedIn = auth.account.hasOwnProperty('uuid');
  const [showModal, setShowModal] = useState(
    loggedIn ? false : showModalAlgo(over21, dispatch)
  );
  const [open, setOpen] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const history = useHistory();

  /** Methods */
  const toggleDrawer = (open) => {
    setOpen(open);
  };

  const toggleModal = (show) => {
    setShowModal(show);
  };

  const toggleAuth = (show) => {
    setAuthOpen(show);
  };

  const handleYes = () => {
    dispatch(actionTypes.answerYes());
    toggleModal(false);
  };

  const handleNo = () => {
    enqueueSnackbar(
      "We're sorry, you must be at least 21 yrs or older to use Match Buds",
      {
        variant: 'error',
      }
    );
  };

  const toggleAuthFromDrawer = (fromLogin) => {
    setIsLogin(fromLogin);
    toggleDrawer(false);
    toggleAuth(true);
  };

  const handleLoginOrSignupClicked = (fromLogin) => {
    setIsLogin(fromLogin);
    toggleAuth(true);
  };

  const handleLogout = () => {
    signOut();
    localStorage.removeItem('authEmail');
    dispatch(actionTypes.logout());
    history.push('/');
  };

  return (
    <div className="bg-black py-2 sticky top-0 z-50">
      <Drawer anchor={'right'} open={open} onClose={() => toggleDrawer(false)}>
        <WebDrawer toggleAuth={toggleAuthFromDrawer} />
      </Drawer>
      <Modal
        isOpen={showModal}
        handleClose={() => toggleAuth(false)}
        content={<Over21 handleYes={handleYes} handleNo={handleNo} />}
        title={
          <div className="text-xl font-bold text-center">
            Are You 21 Years Or Older?
          </div>
        }
      />
      <Modal
        isOpen={authOpen}
        handleClose={() => toggleAuth(false)}
        content={
          <Authenticate
            propMode={isLogin ? 'login' : 'signup'}
            close={() => toggleAuth(false)}
          />
        }
      />
      <div className="flex flex-row justify-between content-center px-6">
        <div className="flex space-x-8 place-items-center">
          <div>
            <NavLink exact to="/home">
              <img alt="home" src={Logo} className="h-10" />
            </NavLink>
          </div>
          <div className="hidden lg:flex">
            <NavLink
              exact
              to="/learn"
              activeClassName="text-green-400"
              className="text-white"
            >
              <div className="font-bold text-lg hover:text-green-400">
                Learn
              </div>
            </NavLink>
          </div>
          <div className="hidden lg:flex">
            <NavLink
              exact
              to="/cloud9"
              activeClassName="text-green-400"
              className="text-white"
            >
              <div className="font-bold text-lg hover:text-green-400">
                Cloud 9
              </div>
            </NavLink>
          </div>
          <div className="hidden lg:flex">
            <NavLink
              exact
              to="/about"
              activeClassName="text-green-400"
              className="text-white"
            >
              <div className="font-bold text-lg hover:text-green-400">
                About
              </div>
            </NavLink>
          </div>
          <div className="hidden lg:flex">
            <a
              href="https://120i36398444513.3dcartstores.com"
              target="_blank"
              rel="noreferrer"
              activeClassName="text-green-400"
              className="text-white"
            >
              <div className="font-bold text-lg hover:text-green-400">
                Market Place
              </div>
            </a>
          </div>
        </div>
        <div className="flex lg:hidden text-white">
          <button
            className="focus:outline-none"
            onClick={() => toggleDrawer(true)}
          >
            <Menu fontSize="large" />
          </button>
        </div>
        {!loggedIn && (
          <div className="hidden lg:flex lg:flex-row space-x-8 place-items-center">
            <div>
              <button
                onClick={() => handleLoginOrSignupClicked(true)}
                className="text-white text-lg font-bold focus:outline-none"
              >
                Log In
              </button>
            </div>
            <div>
              <button
                onClick={() => handleLoginOrSignupClicked(false)}
                className="bg-green-500 text-lg text-white px-10 py-1 rounded-2xl font-bold border-green-500 border-2 hover:bg-black hover:border-white hover:border-2 focus:outline-none"
              >
                Sign Up
              </button>
            </div>
          </div>
        )}
        {loggedIn && (
          <div className="hidden lg:flex lg:flex-row space-x-8 place-items-center">
            <div>
              <button
                onClick={() => handleLogout()}
                className="text-white text-lg font-bold focus:outline-none"
              >
                Log Out
              </button>
            </div>
            <div>
              <NavLink exact to="/cloud">
                <button
                  type="button"
                  className="bg-green-500 text-lg text-white px-10 py-1 rounded-2xl font-bold border-green-500 border-2 hover:bg-black hover:border-white hover:border-2 focus:outline-none"
                >
                  Enter Cloud 9
                </button>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const WebDrawer = React.memo(({ toggleAuth }) => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = auth.account.hasOwnProperty('uuid');
  const handleLogout = () => {
    signOut();
    localStorage.removeItem('authEmail');
    dispatch(actionTypes.logout());
    history.push('/');
  };

  return (
    <div className="w-full h-full bg-black text-green-500 flex flex-col items-center space-y-8 p-10">
      <div>
        <NavLink
          exact
          to="/home"
          activeClassName="text-green-400"
          className="text-white"
        >
          <div className="font-bold">Home</div>
        </NavLink>
      </div>
      <div>
        <NavLink
          exact
          to="/learn"
          activeClassName="text-green-400"
          className="text-white"
        >
          <div className="font-bold">Learn</div>
        </NavLink>
      </div>
      <div>
        <NavLink
          exact
          to="/cloud9"
          activeClassName="text-green-400"
          className="text-white"
        >
          <div className="font-bold">Cloud 9</div>
        </NavLink>
      </div>
      <div>
        <NavLink
          exact
          to="/about"
          activeClassName="text-green-400 "
          className="text-white"
        >
          <div className="font-bold">About</div>
        </NavLink>
      </div>
      <div>
        <a
          href="https://120i36398444513.3dcartstores.com"
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          <div className="font-bold">Market Place</div>
        </a>
      </div>
      {!loggedIn && (
        <div className="flex flex-col space-y-8">
          <div className="w-full">
            <button
              onClick={() => toggleAuth(true)}
              className="bg-white text-black px-10 py-1 rounded-full w-full font-bold focus:outline-none"
            >
              Log In
            </button>
          </div>
          <div className="w-full">
            <button
              onClick={() => toggleAuth(false)}
              className="bg-green-500 text-white px-10 py-1 rounded-full w-full font-bold focus:outline-none"
            >
              Sign Up
            </button>
          </div>
        </div>
      )}
      {loggedIn && (
        <div className="flex flex-col space-y-8">
          <div>
            <button
              onClick={() => handleLogout()}
              className="bg-white text-black px-10 py-1 rounded-full w-full font-bold focus:outline-none"
            >
              Log Out
            </button>
          </div>
          <div>
            <NavLink exact to="/cloud">
              <button
                type="button"
                className="bg-green-500 text-white px-10 py-1 rounded-full w-full font-bold focus:outline-none"
              >
                Enter Cloud 9
              </button>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
});

const Over21 = React.memo(({ handleYes, handleNo }) => {
  return (
    <div className="flex flex-col space-y-5 font-serif">
      <div className="flex justify-center space-x-2">
        <div>
          <button
            className="bg-green-400 w-40 h-10 rounded-3xl focus:outline-none"
            onClick={() => handleYes()}
          >
            Yes
          </button>
        </div>
        <div>
          <button
            className="bg-red-400 w-40 h-10 rounded-3xl focus:outline-none"
            onClick={() => handleNo()}
          >
            No
          </button>
        </div>
      </div>
      <div className="text-xs text-center">
        By clicking Yes, you agree to the{' '}
        <a className="text-blue-600" href="/">
          Terms
        </a>{' '}
        and{' '}
        <a className="text-blue-600" href="/">
          Privacy Policy
        </a>
        .
      </div>
    </div>
  );
});
