import React from 'react';
import { withRouter, BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from 'history';
import { createStyles, makeStyles, ThemeProvider } from '@material-ui/core';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import AuthAuto from './components/AuthAuto';

import Routes from './Routes';

const history = createBrowserHistory();

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);

const App = () => {
  useStyles();
  const { settings } = useSettings();

  return (
    <div>
      <ThemeProvider theme={createTheme(settings)}>
        <SnackbarProvider maxSnack={1}>
          <BrowserRouter history={history}>
            <AuthAuto>
              <Routes />
            </AuthAuto>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(App);
