import * as actionTypes from '../actions/actionTypes';

export const login = (res) => {
  return {
    type: actionTypes.LOG_IN,
    res: res,
  };
};

export const signup = (res) => {
  return {
    type: actionTypes.SIGN_UP,
    res: res,
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOG_OUT,
  };
};

export const updateAccount = (data) => {
  return {
    type: actionTypes.UPDATE_ACCOUNT,
    data: data,
  };
};

export const updateUrl = (url) => {
  return {
    type: actionTypes.UPDATE_URL,
    url: url,
  };
};

export const handleNotificationRead = (notification) => {
  return {
    type: actionTypes.HANDLE_NOTIFICATION_READ,
    notification: notification,
  };
};
