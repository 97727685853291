import * as actionTypes from './actionTypes';
import { getUserRequests } from '../../supabase/queries/requests';

export const startFetchingRequests = () => {
  return {
    type: actionTypes.START_FETCHING_REQUESTS,
  };
};

export const getRequests = (userId) => {
  return (dispatch) => {
    fetchRequests(userId, null).then((res) => {
      dispatch(
        getRequestsSuccess(res.requests, res.noMore, res.lastCreatedAt, true)
      );
    });
  };
};

export const fetchMoreRequests = (userId, lastCreatedAt) => {
  return (dispatch) => {
    fetchRequests(userId, lastCreatedAt).then((res) => {
      dispatch(
        getRequestsSuccess(res.requests, res.noMore, res.lastCreatedAt, false)
      );
    });
  };
};

export const resetRequestsReducer = () => {
  return {
    type: actionTypes.RESET_REQUESTS_REDUCER,
  };
};

export const handleRequestResponse = (reqId, actionTaken) => {
  return {
    type: actionTypes.HANDLE_REQUEST_RESPONSE,
    reqId: reqId,
    actionTaken: actionTaken,
  };
};

const getRequestsSuccess = (requests, noMore, lastCreatedAt, isInit) => {
  return {
    type: isInit ? actionTypes.GET_REQUESTS : actionTypes.FETCH_MORE_REQUESTS,
    requests: requests,
    noMore: noMore,
    lastCreatedAt: lastCreatedAt,
    loading: false,
  };
};

const fetchRequests = async (userId, lastCreatedAt) => {
  let body = {
    requests: [],
    noMore: true,
    lastCreatedAt: null,
  };

  try {
    await getUserRequests(userId, lastCreatedAt, 20).then((res) => {
      if (res.length > 0) {
        body.requests = res;
        body.noMore = res.length < 20;
        body.lastCreatedAt = res[res.length - 1].createdAt;
      }
    });
  } catch (error) {
    console.log('error.message: ', error.message);
  }

  return body;
};
