import { combineReducers } from 'redux';
import authReducer from './auth';
import over21Reducer from './over21';
import networkReducer from './network';
import expReducer from './experience';
import postReducer from './posts';
import notificationReducer from './notifications';
import requestsReducer from './requests';
import selectedPostReducer from './selectedPost';

const rootReducer = combineReducers({
  auth: authReducer,
  over21: over21Reducer,
  network: networkReducer,
  experience: expReducer,
  posts: postReducer,
  notifications: notificationReducer,
  requests: requestsReducer,
  selectedPost: selectedPostReducer,
});

export default rootReducer;
