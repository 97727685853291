import Axios from 'axios';
import moment from 'moment';
import sha1 from 'sha1';

const api_key = '974959867544991';
const secret = '4LmjvopfXj6RPp6guKeHOc00X9M';

export const uploadAvatar = async (file) => {
  let eager = 'w_400,h_300,c_pad|w_260,h_200,c_crop';
  let folder = 'avatars';
  let timestamp = moment().unix();

  const formData = new FormData();
  formData.append('file', file);
  formData.append('eager', eager);
  formData.append('folder', folder);
  formData.append('timestamp', timestamp);
  formData.append('api_key', api_key);
  formData.append(
    'signature',
    sha1(`eager=${eager}&folder=${folder}&timestamp=${timestamp}${secret}`)
  );

  try {
    const res = await Axios.post(
      'https://api.cloudinary.com/v1_1/match-buds/image/upload',
      formData
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteImage = async (public_id) => {
  let timestamp = moment().unix();

  const formData = new FormData();
  formData.append('public_id', public_id);
  formData.append('api_key', api_key);
  formData.append('timestamp', timestamp);
  formData.append('invalidate', true);
  formData.append(
    'signature',
    sha1(
      `invalidate=true&public_id=${public_id}&timestamp=${timestamp}${secret}`
    )
  );

  try {
    const res = await Axios.post(
      'https://api.cloudinary.com/v1_1/match-buds/image/destroy',
      formData
    );

    return res;
  } catch (error) {
    throw error;
  }
};
