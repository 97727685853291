import React, { useState } from 'react';
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  CreateRounded,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { insertState, updateState } from '../../../supabase/queries/learn';
import { Modal } from '../../../components/Modal';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const defaultState = {
  name: '',
  legal: false,
  decriminalized: false,
};

export const BasicInfoSection = React.memo(
  ({ fetching, state, name, stateCreateUpdate }) => {
    /* Variables */
    const account = useSelector((state) => state.auth.account);
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState('');

    /* Methods */
    const toggleOpen = (show) => {
      setOpen(show);
      if (!show) {
        setAction('');
      }
    };

    const create = () => {
      setAction('create');
      toggleOpen(true);
    };

    const edit = () => {
      setAction('edit');
      toggleOpen(true);
    };

    return (
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="font-black text-3xl">{name}</div>
          {!fetching && state && account.type === 'admin' && (
            <div>
              <button className="focus:outline-none" onClick={() => edit()}>
                <CreateRounded />
              </button>
            </div>
          )}
        </div>

        {fetching && <Skeleton width="30%" />}
        {!fetching && state && (
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 font-serif">
            <Chip checked={state?.legal} value={'Legalized'} />
            <Chip checked={state?.decriminalized} value={'Decriminalized'} />
          </div>
        )}
        {!fetching && !state && account.type !== 'admin' && (
          <div>No record exists for this state</div>
        )}
        {!fetching && !state && account.type === 'admin' && (
          <div>
            <button className="focus:outline-none" onClick={() => create()}>
              No record exists for this state. Click here to create one!
            </button>
          </div>
        )}
        <Modal
          isOpen={open}
          handleClose={() => toggleOpen(false)}
          content={
            <StateDetails
              name={name}
              stateCreateUpdate={stateCreateUpdate}
              close={() => toggleOpen(false)}
              action={action}
              state={state}
            />
          }
        />
      </div>
    );
  }
);

const Chip = React.memo(({ checked, value }) => {
  return (
    <div className="flex space-x-1">
      <div>
        {checked ? (
          <CheckBoxRounded fontSize="small" className="text-green-400" />
        ) : (
          <CheckBoxOutlineBlankRounded fontSize="small" />
        )}
      </div>
      <div>{value}</div>
    </div>
  );
});

const StateDetails = React.memo(
  ({ name, close, stateCreateUpdate, action, state }) => {
    /* Variables */
    const [updating, setUpdating] = useState(false);
    const isOnline = useSelector((state) => state.network.isOnline);
    const buttonDisabled = updating || !isOnline;
    const [thisState, setThisState] = useState(state ? state : defaultState);
    const { enqueueSnackbar } = useSnackbar();

    /* Methods */
    const submit = (event) => {
      event.preventDefault();
      setUpdating(true);

      if (action === 'create') {
        insertState({ ...thisState, name: name })
          .then((res) => {
            stateCreateUpdate(res);
            setUpdating(false);
            displaySnackBar('State has been successfully created.', 'success');
            close();
          })
          .catch((err) => {
            console.log('err: ', err);
            displaySnackBar('Something went wrong, please try again.', 'error');
          });
      } else {
        updateState(thisState.id, {
          legal: thisState.legal,
          decriminalized: thisState.decriminalized,
        })
          .then((res) => {
            stateCreateUpdate(res[0]);
            setUpdating(false);
            displaySnackBar('State has been successfully updated.', 'success');
            close();
          })
          .catch((err) => {
            console.log('err: ', err);
            displaySnackBar('Something went wrong, please try again.', 'error');
          });
      }
    };

    const toggleInfo = (wut) => {
      setThisState((old) => {
        let update = { ...old };
        update[wut] = !update[wut];
        return update;
      });
    };

    const displaySnackBar = (message, variant) => {
      enqueueSnackbar(message, {
        variant: variant,
      });
    };

    return (
      <form
        className="flex flex-col items-center space-y-6 relative"
        onSubmit={(event) => submit(event)}
        autoComplete="true"
      >
        <div className="font-mono font-bold text-xl">{`Details for ${name}`}</div>
        <div>
          <input
            type="checkbox"
            id="legalized"
            name="legalized"
            checked={thisState.legal}
            onChange={() => toggleInfo('legal')}
          />
          <label htmlFor="legalized" className="ml-5 text-lg font-bold">
            Legalized?
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="decriminalized"
            name="decriminalized"
            checked={thisState.decriminalized}
            onChange={() => toggleInfo('decriminalized')}
          />
          <label htmlFor="decriminalized" className="ml-5 text-lg font-bold">
            Decriminalized?
          </label>
        </div>
        <div>
          <button
            className={`${
              buttonDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-600'
            } float-right py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
            type="submit"
            disabled={buttonDisabled}
          >
            <div className="flex space-x-2 justify-center items-center">
              {updating && (
                <div>
                  <CircularProgress size={10} />
                </div>
              )}
              <div>{updating ? 'Saving' : 'Save'}</div>
            </div>
          </button>
        </div>
      </form>
    );
  }
);
