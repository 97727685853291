import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  ChatBubbleOutlineRounded,
  // FavoriteBorderRounded,
  // FavoriteRounded,
  MoreHorizRounded,
  RepeatRounded,
  DeleteRounded,
  CreateRounded,
} from '@material-ui/icons';
import { getNumber } from '../../../../utils';
import moment from 'moment';
import {
  deletePost,
  likePost,
  unlikePost,
  createPostReply,
  createRepostPost,
  fetchPostLike,
  fetchPostRepost,
  deleteRepost,
} from '../../../../supabase/queries/post';
import { Modal } from '../../../../components/Modal';
import { useSnackbar } from 'notistack';
import * as actionTypes from '../../../../store/actions';
import { NavLink } from 'react-router-dom';
import ActionReplyBody from '../../../../components/ActionReplyBody';
import ActionQuoteBody from '../../../../components/ActionQuoteBody';
import likeActive from '../../../../assets/icons/likeActive.png';
import likeInactive from '../../../../assets/icons/likeInactive.png';
import repostActive from '../../../../assets/icons/repostActive.png';
import repostInactive from '../../../../assets/icons/repostInactive.png';
import Avatar from '../../../../components/Avatar';
import PostMessage from '../../../../components/PostMessage';

const Post = React.memo((props) => {
  /* Variables */
  const dispatch = useDispatch();
  const isOnline = useSelector((state) => state.network.isOnline);
  const [menuClicked, setMenuClicked] = useState(false);
  const [rpMenuClicked, setRpMenuClicked] = useState(false);
  const [togglingLike, setTogglingLike] = useState(false);
  const [togglingRepost, setTogglingRepost] = useState(false);
  const [posting, setPosting] = useState(false);
  const [postText, setPostText] = useState('');
  const ref = useRef(null);
  const rpRef = useRef(null);
  const account = useSelector((state) => state.auth.account);
  const exp = useSelector((state) => state.experience);
  const posts = useSelector((state) => state.posts);
  const interactions = posts.interactions;
  const [action, setAction] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const postButtonDisabled =
    !isOnline || (action === 'Reply' && postText === '') || posting;
  const postId = props.type === 'repost' ? props.parentPost.id : props.id;

  // personal interactions
  const postLikeId = props.fromTimeLine
    ? interactions.find((pi) => pi.postId === postId).postLikeId
    : null;
  const postRepostId = props.fromTimeLine
    ? interactions.find((pi) => pi.postId === postId).postRepostId
    : null;

  // general interactions
  const likes = props.fromTimeLine
    ? interactions.find((pi) => pi.postId === postId).likes
    : null;
  const reposts = props.fromTimeLine
    ? interactions.find((pi) => pi.postId === postId).reposts
    : null;
  const comments = props.fromTimeLine
    ? interactions.find((pi) => pi.postId === postId).comments
    : null;
  const fetched = props.fromTimeLine
    ? interactions.find((pi) => pi.postId === postId).fetched
    : null;

  // not from timeline interactions
  const [notFTLinteractions, setNotFTLinteractions] = useState({
    postLikeId: null,
    postRepostId: null,
    likes: props.likes,
    reposts: props.reposts,
    comments: props.comments,
    fetched: false,
  });

  /* Use Callback */
  const fetchLikeAndRepost = useCallback(() => {
    if (isOnline) {
      if (props.fromTimeLine && fetched === false) {
        const promiseArray = [
          fetchPostLike(account.id, postId),
          fetchPostRepost(account.id, postId),
        ];

        Promise.all(promiseArray)
          .then((res) => {
            let plId = null;
            let prId = null;

            if (res[0]) {
              plId = res[0].id;
            }
            if (res[1]) {
              prId = res[1].id;
            }

            if (plId && prId) {
              dispatch(actionTypes.addPostLikeAndRepost(postId, plId, prId));
            }
            if (plId && !prId) {
              dispatch(actionTypes.addPostLike(postId, plId, true));
            }
            if (!plId && prId) {
              dispatch(actionTypes.addPostRepost(postId, prId, true));
            }
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      }

      if (!props.fromTimeLine && notFTLinteractions.fetched === false) {
        const promiseArray = [
          fetchPostLike(account.id, postId),
          fetchPostRepost(account.id, postId),
        ];

        Promise.all(promiseArray)
          .then((res) => {
            let plId = null;
            let prId = null;

            if (res[0]) {
              plId = res[0].id;
            }
            if (res[1]) {
              prId = res[1].id;
            }

            if (plId && prId) {
              dispatch(actionTypes.addPostLikeAndRepost(postId, plId, prId));
            }
            if (plId && !prId) {
              dispatch(actionTypes.addPostLike(postId, plId, true));
            }
            if (!plId && prId) {
              dispatch(actionTypes.addPostRepost(postId, prId, true));
            }

            setNotFTLinteractions((old) => {
              let update = { ...old };

              update.postLikeId = plId;
              update.prId = prId;
              update.fetched = true;

              return update;
            });
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      }
    }
  }, []);

  /* Use effect */
  useEffect(() => {
    fetchLikeAndRepost();
  }, []);

  /* Methods */
  const handleOpen = () => {
    setMenuClicked(true);
  };

  const handleClose = () => {
    setMenuClicked(false);
  };

  const handleDelete = () => {
    deletePost(postId, props.type, props.parentPost.id, account.id)
      .then(() => {
        dispatch(actionTypes.deletePost(postId));
      })
      .catch((e) => {
        console.log('e: ', e);
      });
  };

  const displaySnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  const handleToggleLikePost = async () => {
    setTogglingLike(true);

    if (props.fromTimeLine) {
      if (postLikeId) {
        await unlikePost(postId, postLikeId, account.id, props.account)
          .then(() => {
            dispatch(actionTypes.removePostLike(postId));
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      } else {
        await likePost(postId, account.id, props.account, exp)
          .then((res) => {
            const likeId = res[0].data[0].id;
            dispatch(actionTypes.addPostLike(postId, likeId, false));
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      }
    } else {
      if (notFTLinteractions.postLikeId) {
        await unlikePost(
          postId,
          notFTLinteractions.postLikeId,
          account.id,
          props.account
        )
          .then(() => {
            setNotFTLinteractions((old) => {
              let update = { ...old };
              update.postLikeId = null;
              update.likes = update.likes - 1;
              return update;
            });
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      } else {
        await likePost(postId, account.id, props.account, exp)
          .then((res) => {
            const likeId = res[0].data[0].id;
            setNotFTLinteractions((old) => {
              let update = { ...old };
              update.postLikeId = likeId;
              update.likes = update.likes + 1;
              return update;
            });
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      }
    }

    setTogglingLike(false);
  };

  const handleActionClose = () => {
    setAction('');
    setPostText('');
  };

  const handleActionClicked = (clicked) => {
    setAction(clicked);
  };

  const handleTextChange = (event) => {
    event.persist();

    if (event.target.value.length <= 240) {
      setPostText(event.target.value);
    }
  };

  const handleReply = async () => {
    setPosting(true);

    try {
      await createPostReply(account.id, postText, postId, exp);
      handleActionClose();
      displaySnackBar('Reply sent', 'success');

      if (props.fromTimeLine) {
        dispatch(actionTypes.addPostComment(postId));
      } else {
        setNotFTLinteractions((old) => {
          let update = { ...old };
          update.comments = update.comments + 1;
          return update;
        });
      }
    } catch (error) {
      console.log('error: ', error);
      displaySnackBar('Something went wrong', 'error');
    }

    setPosting(false);
  };

  const handleRpOpen = () => {
    setRpMenuClicked(true);
  };

  const handleRpClose = () => {
    setRpMenuClicked(false);
  };

  const handleQuoteClicked = () => {
    handleRpClose();
    handleActionClicked('Repost');
  };

  const handleRepost = async () => {
    setPosting(true);
    setTogglingRepost(true);

    try {
      let res = await createRepostPost(account.id, postText, postId, exp);
      handleRpClose();
      handleActionClose();
      displaySnackBar('Repost sent', 'success');

      if (props.fromTimeLine) {
        dispatch(actionTypes.addPostRepost(postId, res[0].body[0].id, false));
      } else {
        setNotFTLinteractions((old) => {
          let update = { ...old };
          update.reposts = update.reposts + 1;
          return update;
        });
      }
    } catch (error) {
      console.log('error: ', error);
      displaySnackBar('Something went wrong', 'error');
    }

    setPosting(false);
    setTogglingRepost(false);
  };

  const handleDeleteRepost = async () => {
    setTogglingRepost(true);

    try {
      await deleteRepost(postRepostId, postId);
      displaySnackBar('Unrepost successful', 'success');
      handleRpClose();

      if (props.fromTimeLine) {
        dispatch(actionTypes.removePostRepost(postId));
        dispatch(actionTypes.deletePost(postRepostId));
      } else {
        setNotFTLinteractions((old) => {
          let update = { ...old };
          update.postRepostId = null;
          update.reposts = update.reposts - 1;
          return update;
        });
      }
    } catch (error) {
      console.log('error: ', error);
      displaySnackBar('Something went wrong', 'error');
    }

    setTogglingRepost(false);
  };

  const handlePostClicked = (e) => {
    if (e.target.id) {
      dispatch(actionTypes.selectPost(postId));
    }
  };

  const handleParentPostClicked = (e) => {
    if (e.target.id) {
      dispatch(actionTypes.selectPost(props.parentPost.id));
    }
  };

  return (
    <div
      className="flex flex-col pt-5 cursor-pointer relative overflow-x-hidden"
      onClick={(event) => handlePostClicked(event)}
      id="postBody"
    >
      {props.type === 'repost' && (
        <div className="flex space-x-2 text-xs font-black pl-10 pb-1 items-center">
          <RepeatRounded fontSize="small" />
          <div>
            {props.account === account.id ? 'You' : props.name} Reposted
          </div>
        </div>
      )}
      {props.type === 'reply' && (
        <div className="flex space-x-2 text-xs font-black pl-10 pb-5 items-center">
          <div>
            {props.account === account.id ? 'You' : props.name} replied to{' '}
            {props.parentPost.account === account.id
              ? 'You'
              : props.parentPost.account.name}
          </div>
        </div>
      )}
      {props.type === 'repost' && (
        <Post
          name={props.parentPost.account.name}
          userName={props.parentPost.account.userName}
          message={props.parentPost.message}
          comments={props.parentPost.comments}
          likes={props.parentPost.likes}
          reposts={props.parentPost.reposts}
          time={props.parentPost.createdAt}
          avatarUrl={props.parentPost.account.avatarUrl}
          account={props.parentPost.account.id}
          id={props.parentPost.id}
          deletePost={props.deletePost}
          type={props.parentPost.type}
          parentPost={props.parentPost.parentPost}
          replyUnder={props.type === 'reply'}
        />
      )}
      {props.type !== 'repost' && (
        <div
          className={`flex space-x-2 ${!props.replyUnder && 'border-b'} ${
            exp === 'Recreational' ? 'border-green-500' : 'border-yellow-400'
          } px-5 pb-5`}
          id="main"
        >
          <Modal
            isOpen={action !== ''}
            handleClose={() => handleActionClose()}
            id="modal"
            content={
              action === 'Reply' ? (
                <ActionReplyBody
                  props={{
                    ...props,
                    reply: handleReply,
                    postText: postText,
                    handleTextChange: handleTextChange,
                    postButtonDisabled: postButtonDisabled,
                    posting: posting,
                  }}
                />
              ) : (
                <ActionQuoteBody
                  props={{
                    ...props,
                    repost: handleRepost,
                    postText: postText,
                    handleTextChange: handleTextChange,
                    postButtonDisabled: postButtonDisabled,
                    posting: posting,
                  }}
                />
              )
            }
          />
          <div className="flex-initial" id="avatarSection">
            <NavLink to={`/cloud/profile/${props.userName}`}>
              <Avatar
                url={props.avatarUrl}
                name={props.name}
                id={props.id}
                canEdit={false}
                style={`rounded-full h-12 w-12 text-white font-bold flex items-center justify-center ${
                  exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
                }`}
              />
            </NavLink>
          </div>
          <div className="flex-auto flex flex-col" id="infoSection">
            <div
              className="flex space-x-1 lg:space-x-2 items-start lg:items-center"
              id="nameSection"
            >
              <NavLink
                to={`/cloud/profile/${props.userName}`}
                className="font-bold"
              >
                {props.name}
              </NavLink>
              <NavLink
                to={`/cloud/profile/${props.userName}`}
                className="text-gray-700 text-sm font-semibold"
              >
                @{props.userName}
              </NavLink>
              <div className="text-gray-700 text-xs font-semibold" id="dot">
                •
              </div>
              <div className="text-gray-700 text-xs font-semibold" id="timeAgo">
                {moment(props.time).fromNow()}
              </div>
            </div>
            <div className="mb-5 flex flex-col space-y-2" id="messageInfo">
              <PostMessage message={props.message} />
              {props.type === 'repostWithMessage' && (
                <div
                  className="flex flex-col rounded-lg border-gray-400 border-2 p-2"
                  onClick={(event) => handleParentPostClicked(event)}
                >
                  <div className="flex space-x-1 lg:space-x-2 items-start lg:items-center">
                    <div id="avatar">
                      <Avatar
                        url={props.parentPost.account.avatarUrl}
                        name={props.parentPost.account.name}
                        id={props.parentPost.account.id}
                        canEdit={false}
                        style={`rounded-full h-6 w-6 text-white text-xs font-bold flex items-center justify-center ${
                          exp === 'Recreational'
                            ? 'bg-green-500'
                            : 'bg-yellow-400'
                        }`}
                      />
                    </div>
                    <div className="font-bold text-xs">
                      {props.parentPost.account.name}
                    </div>
                    <div className="text-gray-700 text-xs font-semibold">
                      @{props.parentPost.account.userName}
                    </div>
                    <div className="text-gray-700 text-xs font-semibold">•</div>
                    <div className="text-gray-700 text-xs font-semibold">
                      {moment(props.parentPost.createdAt).fromNow()}
                    </div>
                  </div>
                  <PostMessage message={props.parentPost.message} />
                </div>
              )}
            </div>
            <div className="flex space-x-10 items-center" id="interact">
              <Tooltip
                title="Reply"
                interactive
                disableFocusListener
                disableTouchListener
                arrow
              >
                <button
                  className={`flex space-x-1 text-gray-500 ${
                    exp === 'Recreational'
                      ? 'hover:text-green-500'
                      : 'hover:text-yellow-500'
                  } items-center focus:outline-none`}
                  disabled={!isOnline}
                  onClick={() => handleActionClicked('Reply')}
                >
                  <div>
                    <ChatBubbleOutlineRounded />
                  </div>
                  <div>
                    {comments
                      ? getNumber(comments)
                      : getNumber(notFTLinteractions.comments)}
                  </div>
                </button>
              </Tooltip>
              <Tooltip
                title="Like"
                interactive
                disableFocusListener
                disableTouchListener
                arrow
              >
                <button
                  className={`flex space-x-1 ${
                    postLikeId || notFTLinteractions.postLikeId
                      ? exp === 'Recreational'
                        ? 'text-green-500'
                        : 'text-yellow-500'
                      : 'text-gray-500'
                  } ${
                    exp === 'Recreational'
                      ? 'hover:text-green-500'
                      : 'hover:text-yellow-500'
                  } items-center focus:outline-none`}
                  onClick={() => handleToggleLikePost()}
                  disabled={togglingLike || !isOnline}
                >
                  <div>
                    {postLikeId || notFTLinteractions.postLikeId ? (
                      // <FavoriteRounded />
                      <img src={likeActive} alt="likeActive" className="h-10" />
                    ) : (
                      // <FavoriteBorderRounded />
                      <img
                        src={likeInactive}
                        alt="likeInactive"
                        className="h-10"
                      />
                    )}
                  </div>
                  <div>
                    {likes
                      ? getNumber(likes)
                      : getNumber(notFTLinteractions.likes)}
                  </div>
                </button>
              </Tooltip>
              <Tooltip
                title="Repost"
                interactive
                disableFocusListener
                disableTouchListener
                arrow
              >
                <button
                  className={`flex space-x-1 text-gray-500 ${
                    exp === 'Recreational'
                      ? 'hover:text-green-500'
                      : 'hover:text-yellow-500'
                  } items-center focus:outline-none`}
                  disabled={togglingRepost || !isOnline}
                  ref={rpRef}
                  onClick={() => handleRpOpen()}
                >
                  <div>
                    {postRepostId !== null ||
                    notFTLinteractions.postRepostId !== null ? (
                      // <RepeatRounded
                      //   className={`${
                      //     exp === 'Recreational'
                      //       ? 'text-green-500'
                      //       : 'text-yellow-500'
                      //   }`}
                      // />
                      <img
                        src={repostActive}
                        alt="repostActive"
                        className="h-10"
                      />
                    ) : (
                      // <RepeatRounded />
                      <img
                        src={repostInactive}
                        alt="repostInactive"
                        className="h-10"
                      />
                    )}
                  </div>
                  <div>
                    {reposts
                      ? getNumber(reposts)
                      : getNumber(notFTLinteractions.reposts)}
                  </div>
                </button>
              </Tooltip>
              <Menu
                onClose={() => handleRpClose()}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                getContentAnchorEl={null}
                anchorEl={rpRef.current}
                open={rpMenuClicked}
                className="flex flex-col"
              >
                {postRepostId || notFTLinteractions.postRepostId ? (
                  <MenuItem
                    className="flex space-x-2"
                    onClick={() => handleDeleteRepost()}
                  >
                    <div>
                      <RepeatRounded fontSize="small" />
                    </div>
                    <div className="font-bold text-sm">Undo Repost</div>
                  </MenuItem>
                ) : (
                  <MenuItem
                    className="flex space-x-2"
                    onClick={() => handleRepost()}
                  >
                    <div>
                      <RepeatRounded fontSize="small" />
                    </div>
                    <div className="font-bold text-sm">Repost</div>
                  </MenuItem>
                )}
                <MenuItem
                  className="flex space-x-2"
                  onClick={() => handleQuoteClicked()}
                >
                  <div>
                    <CreateRounded fontSize="small" />
                  </div>
                  <div className="font-bold text-sm">Quote Post</div>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="flex-initial" id="moreCol">
            <div className="flex content-start justify-end">
              <button
                className="focus:outline-none"
                ref={ref}
                onClick={() => handleOpen()}
              >
                <MoreHorizRounded />
              </button>
            </div>
            <Menu
              onClose={() => handleClose()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              getContentAnchorEl={null}
              anchorEl={ref.current}
              open={menuClicked}
            >
              {account.id === props.account && (
                <MenuItem
                  className="flex space-x-2"
                  onClick={() => handleDelete()}
                >
                  <div>
                    <DeleteRounded fontSize="small" />
                  </div>
                  <div className="font-bold text-sm">Delete Post</div>
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
});

export default Post;
