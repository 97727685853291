import * as actionTypes from '../actions/actionTypes';

const initState = {
  fetching: false,
  loading: true,
  lastCreatedAt: null,
  budIds: [],
  posts: [],
  interactions: [],
  noMore: false,
  fetched: false,
};

const getPosts = (state, action) => {
  const { budIds, posts, interactions, lastCreatedAt, noMore } = action;
  let updateState = { ...state };
  updateState.budIds = budIds;
  updateState.interactions = interactions;
  updateState.posts = posts;
  updateState.lastCreatedAt = lastCreatedAt;
  updateState.noMore = noMore;
  updateState.loading = false;
  updateState.fetched = true;
  return updateState;
};

const addPost = (state, action) => {
  const { post } = action;
  let updateState = { ...state };

  updateState.interactions.push({
    postId: post.id,
    postLikeId: null,
    postRepostId: null,
    likes: post.likes,
    reposts: post.reposts,
    comments: post.comments,
  });

  updateState.posts.unshift(post);

  return updateState;
};

const fetchMore = (state, action) => {
  const { posts, interactions, lastCreatedAt, noMore } = action;
  let updateState = { ...state };
  updateState.interactions = [...updateState.interactions, ...interactions];
  updateState.posts = [...updateState.posts, ...posts];
  updateState.lastCreatedAt = lastCreatedAt
    ? lastCreatedAt
    : updateState.lastCreatedAt;
  updateState.noMore = noMore;
  updateState.fetching = false;
  return updateState;
};

const deletePost = (state, action) => {
  const { postId } = action;
  let updateState = { ...state };
  let posts = [...updateState.posts];
  let interactions = [...updateState.interactions];

  const piIndex = interactions.findIndex((pi) => pi.postId === postId);
  if (piIndex !== -1) {
    interactions.splice(piIndex, 1);
  }
  const pIndex = posts.findIndex((p) => p.id === postId);
  if (pIndex !== -1) {
    posts.splice(pIndex, 1);
  }

  updateState.posts = posts;
  updateState.interactions = interactions;
  return updateState;
};

const setFetching = (state) => {
  let updateState = { ...state };
  updateState.fetching = true;
  return updateState;
};

const stopFetching = (state) => {
  let updateState = { ...state };
  updateState.fetching = false;
  return updateState;
};

const stopLoading = (state) => {
  let updateState = { ...state };
  updateState.looading = false;
  return updateState;
};

const resetPostReducer = () => {
  return initState;
};

const addPostLikeAndRepost = (state, action) => {
  const { postId, postLikeId, postRepostId } = action;
  let updateState = { ...state };
  const pIndex = updateState.interactions.findIndex(
    (pi) => pi.postId === postId
  );
  if (pIndex !== -1) {
    updateState.interactions[pIndex].postLikeId = postLikeId;
    updateState.interactions[pIndex].postRepostId = postRepostId;
    updateState.interactions[pIndex].fetched = true;
  }
  return updateState;
};

const addPostLike = (state, action) => {
  const { postId, postLikeId, isInit } = action;
  let updateState = { ...state };
  const pIndex = updateState.interactions.findIndex(
    (pi) => pi.postId === postId
  );
  if (pIndex !== -1) {
    updateState.interactions[pIndex].postLikeId = postLikeId;
    updateState.interactions[pIndex].fetched = true;

    if (!isInit) {
      updateState.interactions[pIndex].likes =
        updateState.interactions[pIndex].likes + 1;
    }
  }
  return updateState;
};

const removePostLike = (state, action) => {
  const { postId } = action;
  let updateState = { ...state };
  const pIndex = updateState.interactions.findIndex(
    (pi) => pi.postId === postId
  );
  if (pIndex !== -1) {
    updateState.interactions[pIndex].postLikeId = null;
    updateState.interactions[pIndex].likes =
      updateState.interactions[pIndex].likes - 1;
  }
  return updateState;
};

const addPostRepost = (state, action) => {
  const { postId, postRepostId, isInit } = action;
  let updateState = { ...state };
  const pIndex = updateState.interactions.findIndex(
    (pi) => pi.postId === postId
  );
  if (pIndex !== -1) {
    updateState.interactions[pIndex].postRepostId = postRepostId;
    updateState.interactions[pIndex].fetched = true;

    if (!isInit) {
      updateState.interactions[pIndex].reposts =
        updateState.interactions[pIndex].reposts + 1;
    }
  }
  return updateState;
};

const addPostComment = (state, action) => {
  const { postId } = action;
  let updateState = { ...state };
  const pIndex = updateState.interactions.findIndex(
    (pi) => pi.postId === postId
  );
  if (pIndex !== -1) {
    updateState.interactions[pIndex].comments =
      updateState.interactions[pIndex].comments + 1;
  }
  return updateState;
};

const removePostComment = (state, action) => {
  const { postId } = action;
  let updateState = { ...state };
  const pIndex = updateState.interactions.findIndex(
    (pi) => pi.postId === postId
  );
  if (pIndex !== -1) {
    updateState.interactions[pIndex].comments =
      updateState.interactions[pIndex].comments - 1;
  }
  return updateState;
};

const removePostRepost = (state, action) => {
  const { postId } = action;
  let updateState = { ...state };
  const pIndex = updateState.interactions.findIndex(
    (pi) => pi.postId === postId
  );
  if (pIndex !== -1) {
    updateState.interactions[pIndex].postRepostId = null;
    updateState.interactions[pIndex].reposts =
      updateState.interactions[pIndex].reposts - 1;
  }
  return updateState;
};

const getPostsWoIds = (state, action) => {
  const { posts, interactions, lastCreatedAt, noMore } = action;
  let updateState = { ...state };
  updateState.interactions = interactions;
  updateState.posts = posts;
  updateState.lastCreatedAt = lastCreatedAt;
  updateState.noMore = noMore;
  updateState.loading = false;
  return updateState;
};

const fetchMorePostsWoIds = (state, action) => {
  const { posts, interactions, lastCreatedAt, noMore } = action;
  let updateState = { ...state };
  updateState.interactions = [...updateState.interactions, ...interactions];
  updateState.posts = [...updateState.posts, ...posts];
  updateState.lastCreatedAt = lastCreatedAt
    ? lastCreatedAt
    : updateState.lastCreatedAt;
  updateState.noMore = noMore;
  updateState.fetching = false;
  return updateState;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ADD_POST_LIKE_AND_REPOST:
      return addPostLikeAndRepost(state, action);
    case actionTypes.ADD_POST_LIKE:
      return addPostLike(state, action);
    case actionTypes.REMOVE_POST_LIKE:
      return removePostLike(state, action);
    case actionTypes.ADD_POST_REPOST:
      return addPostRepost(state, action);
    case actionTypes.REMOVE_POST_REPOST:
      return removePostRepost(state, action);
    case actionTypes.ADD_POST_COMMENT:
      return addPostComment(state, action);
    case actionTypes.REMOVE_POST_COMMENT:
      return removePostComment(state, action);
    case actionTypes.GET_POSTS:
      return getPosts(state, action);
    case actionTypes.GET_USER_POSTS:
      return getPostsWoIds(state, action);
    case actionTypes.FETCH_MORE_USER_POSTS:
      return fetchMorePostsWoIds(state, action);
    case actionTypes.GET_USER_LIKED_POSTS:
      return getPostsWoIds(state, action);
    case actionTypes.FETCH_MORE_USER_LIKED_POSTS:
      return fetchMorePostsWoIds(state, action);
    case actionTypes.ADD_POST:
      return addPost(state, action);
    case actionTypes.FETCH_MORE:
      return fetchMore(state, action);
    case actionTypes.DELETE_POST:
      return deletePost(state, action);
    case actionTypes.SET_FETCHING:
      return setFetching(state);
    case actionTypes.STOP_FETCHING:
      return stopFetching(state);
    case actionTypes.STOP_LOADING:
      return stopLoading(state);
    case actionTypes.RESET_POST_REDUCER:
      return resetPostReducer();
    default:
      return state;
  }
};

export default reducer;
