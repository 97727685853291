import React, { useState } from 'react';
import People from '../../assets/images/people.png';
import { FancyHeader } from '../../components/FancyHeader';
import { PlayCircleFilledSharp } from '@material-ui/icons';
import { Modal } from '../../components/Modal';
import { Authenticate } from '../../components/Authenticate';
import LearnVid from '../../assets/videos/matchbud2.mov';

export const FirstSection = React.memo(() => {
  const [authOpen, setAuthOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleAuth = (show) => {
    setAuthOpen(show);
  };

  const toggleModal = (show) => {
    setShowModal(show);
  };

  return (
    <div className="bg-black grid place-items-center py-20">
      <Modal
        isOpen={authOpen}
        handleClose={() => toggleAuth(false)}
        content={
          <Authenticate propMode="signup" close={() => toggleAuth(false)} />
        }
      />
      <Modal
        isOpen={showModal}
        handleClose={() => toggleModal(false)}
        content={<LearnVideo />}
      />
      <div className="container mx-auto flex flex-row items-center px-4 md:px-0">
        <div className="flex-1 flex flex-col space-y-6">
          <div>
            <FancyHeader
              text={'Learn, Experience, Matchup on Matchbuds'}
              color={'white'}
            />
          </div>
          {/* <div className="font-serif w-auto lg:w-2/3 text-lg text-white">
            Learn, Experience, Matchup on Matchbuds
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 place-items-center lg:gap-0 lg:place-items-start">
            <div className="">
              <button
                onClick={() => toggleAuth(true)}
                className="bg-green-400 text-white rounded-lg font-bold border-green-400 border-2 hover:bg-black hover:border-white hover:border-2 focus:outline-none h-10 px-10"
              >
                Join Match Buds
              </button>
            </div>
            <div className="group ">
              <button
                onClick={() => toggleModal(true)}
                className="grid grid-flow-col place-content-center space-x-1 bg-black border-2 border-white text-white rounded-lg font-bold group-hover:text-black group-hover:bg-white group-hover:shadow-lg focus:outline-none h-10 px-10"
              >
                <div>Watch Video</div>
                <div>
                  <PlayCircleFilledSharp className="group-hover:text-black" />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 hidden lg:flex">
          <img className="img-fluid " alt="People" src={People} />
        </div>
      </div>
    </div>
  );
});

const LearnVideo = React.memo(() => {
  return (
    <video className="rounded-lg focus:outline-none" controls>
      <source src={LearnVid} type="video/mp4" />
    </video>
  );
});
