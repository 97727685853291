import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuggestBuds from '../components/SuggestBuds';
import BottomBar from './BottomBar';
import { NavSidebar } from './NavSidebar';
import TopBar from './TopBar';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { CreateRounded, FlashOnRounded } from '@material-ui/icons';
import * as actionTypes from '../store/actions';
import { useSnackbar } from 'notistack';
import { Modal } from '../components/Modal';
import CreatePost from '../views/Cloud9Views/CloudHome/CreatePost';
import SelectedPost from '../components/SelectedPost';

const actions = [
  { icon: <CreateRounded />, name: 'Post' },
  { icon: <FlashOnRounded />, name: 'Switch' },
];

const Cloud9 = React.memo(({ children }) => {
  /* Variables */
  const exp = useSelector((state) => state.experience);
  const selectedPost = useSelector((state) => state.selectedPost);
  const [speedOpen, setSpeedOpen] = useState(false);
  const [postOpen, setPostOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const actionMethods = [
    {
      name: 'Post',
      function: () => handlePostClicked(),
    },
    {
      name: 'Switch',
      function: () => handleExperienceSwitch(),
    },
  ];

  /* Methods */
  const toggleSpeed = (speed) => {
    setSpeedOpen(speed);
  };

  const togglePost = (post) => {
    setPostOpen(post);
    toggleSpeed(false);
  };

  const handleExperienceSwitch = () => {
    dispatch(
      actionTypes.setExp(exp === 'Recreational' ? 'Medicinal' : 'Recreational')
    );
    toggleSpeed(false);
    displaySnackBar(
      exp === 'Recreational'
        ? 'Switched to Medicinal'
        : 'Switched to Recreational',
      'success'
    );
  };

  const handlePostClicked = () => {
    toggleSpeed(false);
    togglePost(true);
  };

  const displaySnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  return (
    <>
      <div
        className={`h-screen table w-full z-40 ${
          exp === 'Recreational' ? 'bg-green-50' : 'bg-yellow-50'
        }`}
      >
        <div className="sticky top-0 bg-black shadow z-50">
          <TopBar />
        </div>
        <div className="container mx-0 md:mx-auto px-2 md:px-0 flex flex-col">
          <div className="hidden md:inline-flex fixed">
            <NavSidebar togglePost={() => togglePost(true)} />
          </div>
          <div className="grid grid-cols-12 gap-5 w-full pl-0 md:pl-28 lg:pl-64">
            <div
              className="py-5 col-span-12 md:col-span-8"
              style={{
                display: 'grid',
              }}
            >
              <div
                className={`w-full ${
                  selectedPost.postId !== '' ? 'hidden' : ''
                }`}
                style={{ gridArea: '1 / 1 / 2 / 2', width: '100%' }}
              >
                {children}
              </div>
              <div
                className={`w-full ${
                  selectedPost.postId === '' ? 'hidden' : ''
                }`}
                style={{ gridArea: '1 / 1 / 2 / 2', width: '100%' }}
              >
                <SelectedPost />
              </div>
            </div>
            <div className="hidden md:inline pt-14 col-span-4">
              <SuggestBuds />
            </div>
          </div>
        </div>
        <div className="fixed bottom-16 right-5 md:hidden focus:outline-none">
          <SpeedDial
            ariaLabel="MatchBuds SpeedDial"
            icon={<SpeedDialIcon />}
            onClose={() => toggleSpeed(false)}
            onOpen={() => toggleSpeed(true)}
            open={speedOpen}
            FabProps={{ color: 'secondary' }}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={
                  actionMethods.find((a) => a.name === action.name).function
                }
              />
            ))}
          </SpeedDial>
        </div>
      </div>
      <div className="sticky bottom-0 flex w-full md:hidden">
        <BottomBar />
      </div>
      <Modal
        isOpen={postOpen}
        handleClose={() => togglePost(false)}
        content={
          <CreatePost fromSpeedDial={true} close={() => togglePost(false)} />
        }
      />
    </>
  );
});

export default Cloud9;
