import React, { useState, useEffect, useRef } from 'react';

const AutoTextAreaReadOnly = ({ text }) => {
  const textAreaRef = useRef(null);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');

  useEffect(() => {
    setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
  }, [text]);

  return (
    <div
      style={{
        minWidth: 'auto',
      }}
    >
      <textarea
        ref={textAreaRef}
        rows={1}
        style={{
          height: textAreaHeight,
          resize: 'none',
        }}
        value={text}
        readOnly
        className="focus:outline-none w-full font-mono"
      />
    </div>
  );
};

export default AutoTextAreaReadOnly;
