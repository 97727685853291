export const getInitials = (name) => {
  // instantiate initials string
  let init = '';

  // split name into array based on whitespaces
  if (name) {
    name.split(' ').forEach((n) => {
      // for each string array, get first char
      // and uppercase it
      if (init.length <= 2) {
        if ([...n][0]) {
          init += [...n][0].toUpperCase();
        }
      }
    });
  }

  // return initals
  return init;
};

export const getNumber = (num) => {
  let n = parseInt(num);

  if (n >= 1000) {
    let numStrArr = [...n.toString()];

    n = numStrArr[0] + '.' + numStrArr[1] + 'k';
  }

  return n;
};

export const timeAgoUnix = (unix) => {};
