import React from 'react';
import { WebFooter } from '../../components/WebFooter';
import { WebNavBar } from '../../components/WebNavbar';
import { LocationInformation } from './LocationInformation';

const LearnView = React.memo(() => {
  // have to decide if id param will be necessary or if we can simply use below
  // there will also be query params for state & zip code
  // if query params are not null, will pass down to location information compoent (either or)
  return (
    <div>
      <WebNavBar />
      <div className="container mx-auto px-4 md:px-0 py-24">
        <LocationInformation />
      </div>
      <WebFooter />
    </div>
  );
});

export default LearnView;
