import React from 'react';
import { FancyHeader } from '../../components/FancyHeader';
import Logo from '../../assets/logo.png';
import AboutOdos from '../../assets/images/AboutOdos.png';

export const SecondSection = React.memo(() => {
  return (
    <div className="py-20">
      <div className="container mx-auto grid place-items-center px-4 md:px-0 space-y-10">
        <div>
          <FancyHeader text={'Our Story'} color={'black'} />
        </div>
        <div className="flex font-serif justify-evenly items-center">
          <div className="flex-1 flex flex-col space-y-8">
            <div className="text-2xl font-bold">Mission Statement</div>
            <div className="text-xl w-auto ">
              Our mission is to unite all members of the cannabis community,
              while cleansing the negative connotations behind marijuana, by
              informing users of the laws and cannabis practices. Match Buds
              will establish a safe space for recreational and medicinal users
              to connect with the common goal to empower the cannabis community.
            </div>
            <div className="flex-1 flex lg:hidden justify-start">
              <img className="img-fluid " alt="People" src={Logo} />
            </div>
          </div>
          <div className="flex-1 hidden lg:flex justify-end">
            <img className="img-fluid " alt="People" src={Logo} />
          </div>
        </div>
        <div className="flex font-serif justify-evenly items-center">
          <div className="flex-1 hidden lg:flex justify-start">
            <img
              className="img-fluid h-96 rounded shadow-lg"
              alt="People"
              src={AboutOdos}
            />
          </div>
          <div className="flex-1 flex flex-col items-end space-y-8">
            <div className="text-2xl font-bold">Meet Odos</div>
            <div className="text-xl w-auto text-right">
              Jordin Ambroise, also known as Odos, is the Chief Operating
              Officer at MatchBuds. With a background in finance, mathematics,
              and entrepreneurship, Odos has emerged as an up and coming mogul
              within the cannabis community. Diving into the cannabis industry
              Odos started off creating and editing cannabis business plans, as
              well as researching both the benefits & sides effects of
              marijuana. With his illuminating personality and interest of
              cannabis and its practices, Odos is now becoming a marijuana
              representative & cultivator. His exceptional background in
              Cannabis both recreational & medicinal allow Odos to lead us in
              the unification of the cannabis community. Join MatchBuds today
              and interact with Odos (@Odos) in the Cloud 9!
            </div>
            <div className="flex-1 flex lg:hidden justify-end">
              <img className="img-fluid " alt="People" src={AboutOdos} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
