import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

export const Modal = React.memo(
  ({ isOpen, handleClose, content, disableBackDropClick, title }) => {
    return (
      <Dialog
        onClose={handleClose}
        open={isOpen}
        disableBackdropClick={disableBackDropClick}
        fullWidth={true}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }
);
