import React, { useState, useEffect } from 'react';
import AutoTextArea from '../../../../components/AutoTextArea';
import { useSelector, useDispatch } from 'react-redux';
import { createRegularPost } from '../../../../supabase/queries/post';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as actionTypes from '../../../../store/actions';
import Avatar from '../../../../components/Avatar';

const CreatePost = React.memo(({ fromSpeedDial, close }) => {
  /* Variables */
  const account = useSelector((state) => state.auth.account);
  const isOnline = useSelector((state) => state.network.isOnline);
  const [postText, setPostText] = useState('');
  const [posting, setPosting] = useState(false);
  const postButtonDisabled = !isOnline || postText === '' || posting;
  const { enqueueSnackbar } = useSnackbar();
  const exp = useSelector((state) => state.experience);
  const dispatch = useDispatch();

  /* Methods */
  const handleTextChange = (event) => {
    event.persist();

    if (event.target.value.length <= 240) {
      setPostText(event.target.value);
    }
  };

  useEffect(() => {
    const handlePosViaHotkey = async () => {
      setPosting(true);

      await createRegularPost(account.id, postText.trim(), exp)
        .then((res) => {
          let newPost = {
            ...res,
            name: account.name,
            userName: account.userName,
            avatarUrl: account.avatarUrl,
          };
          dispatch(actionTypes.addPost(newPost));
          enqueueSnackbar('Post successfully created.', {
            variant: 'success',
          });
          setPostText('');
          if (fromSpeedDial) {
            close();
          }
        })
        .catch((e) => {
          console.log('e: ', e);
          enqueueSnackbar(e.message, {
            variant: 'error',
          });
        });

      setPosting(false);
    };

    const keyDownHandler = (e) => {
      if (e.keyCode === 13 && e.metaKey && !postButtonDisabled) {
        handlePosViaHotkey();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  }, [
    postButtonDisabled,
    account.id,
    exp,
    enqueueSnackbar,
    postText,
    dispatch,
    account.name,
    account.userName,
    account.avatarUrl,
    fromSpeedDial,
    close,
  ]);

  const handlePost = async () => {
    setPosting(true);

    await createRegularPost(account.id, postText.trim(), exp)
      .then((res) => {
        let newPost = {
          ...res,
          name: account.name,
          userName: account.userName,
          avatarUrl: account.avatarUrl,
        };
        dispatch(actionTypes.addPost(newPost));
        handleSuccess();
        setPostText('');
        if (fromSpeedDial) {
          close();
        }
      })
      .catch((e) => {
        console.log('e: ', e);
        handleError(e.message);
      });

    setPosting(false);
  };

  const handleSuccess = () => {
    enqueueSnackbar('Post successfully created.', {
      variant: 'success',
    });
  };

  const handleError = (message) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  return (
    <div
      className={`bg-white rounded-lg shadow ${!fromSpeedDial && 'p-5 mb-5'}`}
    >
      <form className="flex flex-col space-y-2 p-1">
        <div className="flex space-x-2 w-full">
          <div className="flex-initial">
            <Avatar
              url={account.avatarUrl}
              name={account.name}
              id={account.id}
              canEdit={false}
              style={`rounded-full text-white font-bold h-12 w-12 flex items-center justify-center ${
                exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
              }`}
            />
          </div>
          <div className="w-full">
            <AutoTextArea
              className="w-full"
              placeholder={
                exp === 'Recreational'
                  ? "What's smoking?"
                  : "What's on your mind?"
              }
              value={postText}
              onChange={handleTextChange}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="flex space-x-2 items-center">
            <div>{240 - postText.length}</div>
            <div>
              <button
                className={`${
                  postButtonDisabled
                    ? 'bg-gray-500 cursor-not-allowed'
                    : exp === 'Recreational'
                    ? 'bg-green-500'
                    : 'bg-yellow-400'
                } py-2 px-5 rounded-full flex focus:outline-none`}
                type="button"
                disabled={postButtonDisabled}
                onClick={() => handlePost()}
              >
                <div className="flex space-x-2 justify-center items-center text-lg font-bold text-white antialiased">
                  {posting && (
                    <div>
                      <CircularProgress size={10} />
                    </div>
                  )}
                  <div>{posting ? 'Posting' : 'Post'}</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default CreatePost;
