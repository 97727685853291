import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../store/actions';
import {
  ArrowBackRounded,
  ChatBubbleOutlineRounded,
  // FavoriteBorderRounded,
  // FavoriteRounded,
  // MoreHorizRounded,
  // RepeatRounded,
  // DeleteRounded,
  CreateRounded,
} from '@material-ui/icons';
import { CircularProgress, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import {
  deleteRepost,
  unlikePost,
  likePost,
  createRepostPost,
  createPostReply,
} from '../supabase/queries/post';
import { useSnackbar } from 'notistack';
import ActionReplyBody from './ActionReplyBody';
import ActionQuoteBody from './ActionQuoteBody';
import { Modal } from './Modal';
import likeActive from '../assets/icons/likeActive.png';
import likeInactive from '../assets/icons/likeInactive.png';
import repostActive from '../assets/icons/repostActive.png';
import repostInactive from '../assets/icons/repostInactive.png';
import { fetchRepliesToPost } from '../supabase/queries/post';
import Post from '../views/Cloud9Views/CloudHome/Timeline/Post';
import Avatar from './Avatar';
import PostMessage from './PostMessage';

const SelectedPost = React.memo(() => {
  /* Variables */
  const account = useSelector((state) => state.auth.account);
  const exp = useSelector((state) => state.experience);
  const isOnline = useSelector((state) => state.network.isOnline);
  const selectedPost = useSelector((state) => state.selectedPost);
  const dispatch = useDispatch();
  const [action, setAction] = useState('');
  const [togglingLike, setTogglingLike] = useState(false);
  const [togglingRepost, setTogglingRepost] = useState(false);
  const rpRef = useRef(null);
  const [rpMenuClicked, setRpMenuClicked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [posting, setPosting] = useState(false);
  const [postText, setPostText] = useState('');
  const postButtonDisabled =
    !isOnline || (action === 'Reply' && postText === '') || posting;
  const hasInteractions =
    selectedPost.post?.likes > 0 || selectedPost.post?.reposts > 0;
  const [repliesInfo, setRepliesInfo] = useState({
    fetching: false,
    loading: true,
    replies: [],
    lastCreatedAt: null,
    noMore: true,
  });

  /* Methods */
  const fetchData = useCallback(async () => {
    if (isOnline && selectedPost.postId !== '' && !selectedPost.post) {
      dispatch(actionTypes.fetchPost(selectedPost.postId, account.id));
      let replies = await fetchRepliesToPost(selectedPost.postId, null, 20);
      setRepliesInfo((old) => {
        let update = { ...old };
        update.replies = replies;
        update.loading = false;
        update.noMore = replies.length < 20;
        update.lastCreatedAt =
          replies.length > 0 ? replies[replies.length - 1].createdAt : null;
        return update;
      });
    }
  }, [selectedPost.postId, isOnline, dispatch, account.id, selectedPost.post]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleBackClicked = () => {
    dispatch(actionTypes.clearSelectedPost());
  };

  const handleActionClicked = (clicked) => {
    setAction(clicked);
  };

  const handleActionClose = () => {
    setAction('');
    setPostText('');
  };

  const handleToggleLikePost = async () => {
    setTogglingLike(true);

    if (selectedPost.interactions.postLikeId) {
      await unlikePost(
        selectedPost.postId,
        selectedPost.interactions.postLikeId,
        account.id,
        selectedPost.post.account.id
      )
        .then(() => {
          dispatch(actionTypes.removePostLike(selectedPost.postId));
          dispatch(
            actionTypes.singlePostRemoveLike(
              selectedPost.interactions.postLikeId
            )
          );
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    } else {
      await likePost(
        selectedPost.postId,
        account.id,
        selectedPost.post.account.id,
        exp
      )
        .then((res) => {
          const likeId = res[0].data[0].id;
          dispatch(actionTypes.addPostLike(selectedPost.postId, likeId, false));
          dispatch(actionTypes.singlePostAddLike(likeId));
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }

    setTogglingLike(false);
  };

  const handleRpOpen = () => {
    setRpMenuClicked(true);
  };

  const handleRpClose = () => {
    setRpMenuClicked(false);
  };

  const displaySnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  const handleDeleteRepost = async () => {
    setTogglingRepost(true);

    try {
      await deleteRepost(
        selectedPost.interactions.postRepostId,
        selectedPost.postId
      );
      displaySnackBar('Unrepost successful', 'success');
      handleRpClose();

      dispatch(actionTypes.removePostRepost(selectedPost.postId));
      dispatch(actionTypes.deletePost(selectedPost.interactions.postRepostId));
      dispatch(
        actionTypes.singlePostRemoveRepost(
          selectedPost.interactions.postRepostId
        )
      );
    } catch (error) {
      console.log('error: ', error);
      displaySnackBar('Something went wrong', 'error');
    }

    setTogglingRepost(false);
  };

  const handleRepost = async () => {
    setPosting(true);
    setTogglingRepost(true);

    try {
      let res = await createRepostPost(
        account.id,
        postText,
        selectedPost.postId,
        exp
      );
      handleRpClose();
      handleActionClose();
      displaySnackBar('Repost sent', 'success');

      dispatch(
        actionTypes.addPostRepost(selectedPost.postId, res[0].body[0].id, false)
      );
      dispatch(actionTypes.singlePostAddRepost(res[0].body[0].id));
    } catch (error) {
      console.log('error: ', error);
      displaySnackBar('Something went wrong', 'error');
    }

    setPosting(false);
    setTogglingRepost(false);
  };

  const handleQuoteClicked = () => {
    handleRpClose();
    handleActionClicked('Repost');
  };

  const handleTextChange = (event) => {
    event.persist();

    if (event.target.value.length <= 240) {
      setPostText(event.target.value);
    }
  };

  const handleReply = async () => {
    setPosting(true);

    try {
      await createPostReply(account.id, postText, selectedPost.postId, exp);
      handleActionClose();
      displaySnackBar('Reply sent', 'success');

      dispatch(actionTypes.addPostComment(selectedPost.postId));
    } catch (error) {
      console.log('error: ', error);
      displaySnackBar('Something went wrong', 'error');
    }

    setPosting(false);
  };

  const handleFetchMore = async () => {
    setRepliesInfo((old) => {
      let update = { ...old };
      update.fetching = true;
      return update;
    });

    let replies = [];

    try {
      replies = await fetchRepliesToPost(
        selectedPost.postId,
        repliesInfo.lastCreatedAt,
        20
      );
      setRepliesInfo((old) => {
        let update = { ...old };
        update.replies = [...update.replies, ...replies];
        update.fetching = false;
        update.noMore = replies.length < 20;
        update.lastCreatedAt =
          replies.length > 0 ? replies[replies.length - 1].createdAt : null;
        return update;
      });
    } catch (error) {
      setRepliesInfo((old) => {
        let update = { ...old };
        update.fetching = false;
        return update;
      });
    }
  };

  const handleParentPostClicked = () => {
    dispatch(actionTypes.selectPost(selectedPost.post.parentPost.id));
  };

  return (
    <div className="flex flex-col">
      <Modal
        isOpen={action !== ''}
        handleClose={() => handleActionClose()}
        id="modal"
        content={
          action === 'Reply' ? (
            <ActionReplyBody
              props={{
                name: selectedPost.post?.account.name,
                userName: selectedPost.post?.account.userName,
                time: selectedPost.post?.createdAt,
                message: selectedPost.post?.message,
                reply: handleReply,
                postText: postText,
                handleTextChange: handleTextChange,
                postButtonDisabled: postButtonDisabled,
                posting: posting,
              }}
            />
          ) : (
            <ActionQuoteBody
              props={{
                name: selectedPost.post?.account.name,
                userName: selectedPost.post?.account.userName,
                time: selectedPost.post?.createdAt,
                message: selectedPost.post?.message,
                repost: handleRepost,
                postText: postText,
                handleTextChange: handleTextChange,
                postButtonDisabled: postButtonDisabled,
                posting: posting,
              }}
            />
          )
        }
      />
      <div className="flex items-center space-x-2">
        <div className="cursor-pointer" onClick={() => handleBackClicked()}>
          <ArrowBackRounded />
        </div>
        <div
          className={`pl-1 py-1 text-xl ${
            exp === 'Recreational' ? 'text-green-700' : 'text-yellow-700'
          } font-extrabold`}
        >
          {`Post`}
        </div>
      </div>
      <div className="flex flex-col bg-white shadow rounded-lg p-5">
        {selectedPost.loading && (
          <CircularProgress
            size={20}
            color="secondary"
            className="self-center"
          />
        )}
        {!selectedPost.loading && selectedPost.error && (
          <div className="font-bold text-center p-5">
            There was an issue fetching this post. Please refresh the page and
            try again.
          </div>
        )}
        {!selectedPost.loading && selectedPost.post && (
          <div className="flex flex-col space-y-3">
            <div className="flex space-x-2 items-center">
              <NavLink
                to={`/cloud/profile/${selectedPost.post.account.userName}`}
              >
                <Avatar
                  url={selectedPost.post.account.avatarUrl}
                  name={selectedPost.post.account.name}
                  id={selectedPost.post.account.id}
                  style={`rounded-full h-12 w-12 text-white font-bold flex items-center justify-center ${
                    exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
                  }`}
                />
              </NavLink>
              <div className="flex flex-col">
                <NavLink
                  to={`/cloud/profile/${selectedPost.post.account.userName}`}
                  className="font-bold"
                >
                  {selectedPost.post.account.name}
                </NavLink>
                <NavLink
                  to={`/cloud/profile/${selectedPost.post.account.userName}`}
                  className="text-gray-700 text-sm font-semibold"
                >
                  @{selectedPost.post.account.userName}
                </NavLink>
              </div>
            </div>
            <PostMessage message={selectedPost.post.message} />
            {selectedPost.post.type === 'repostWithMessage' && (
              <div
                className="flex flex-col rounded-lg border-gray-400 border-2 p-2 cursor-pointer"
                onClick={(event) => handleParentPostClicked(event)}
              >
                <div className="flex space-x-1 lg:space-x-2 items-start lg:items-center">
                  <div id="avatar">
                    <Avatar
                      url={selectedPost.post.parentPost.account.avatarUrl}
                      name={selectedPost.post.parentPost.account.name}
                      id={selectedPost.post.parentPost.account.id}
                      style={`rounded-full h-6 w-6 text-white text-xs font-bold flex items-center justify-center ${
                        exp === 'Recreational'
                          ? 'bg-green-500'
                          : 'bg-yellow-400'
                      }`}
                    />
                  </div>
                  <div className="font-bold text-xs">
                    {selectedPost.post.parentPost.account.name}
                  </div>
                  <div className="text-gray-700 text-xs font-semibold">
                    @{selectedPost.post.parentPost.account.userName}
                  </div>
                  <div className="text-gray-700 text-xs font-semibold">•</div>
                  <div className="text-gray-700 text-xs font-semibold">
                    {moment(selectedPost.post.parentPost.createdAt).fromNow()}
                  </div>
                </div>
                <PostMessage message={selectedPost.post.parentPost.message} />
              </div>
            )}
            <div
              className={`flex space-x-2 text-sm text-gray-600 ${
                hasInteractions
                  ? `border-b ${
                      exp === 'Recreational'
                        ? 'border-green-500'
                        : 'border-yellow-400'
                    } pb-2`
                  : null
              }`}
            >
              {moment(selectedPost.post.createdAt).format(
                'hh:mm A MMM D, yyyy'
              )}
            </div>
            {hasInteractions && (
              <div className="flex space-x-2">
                {selectedPost.post.reposts > 0 && (
                  <div className="flex space-x-1 text-sm">
                    <div className="font-black">{`${selectedPost.post.reposts}`}</div>
                    <div className="font-bold">{`Repost${
                      selectedPost.post.reposts > 1 ? 's' : ''
                    }`}</div>
                  </div>
                )}
                {selectedPost.post.likes > 0 && (
                  <div className="flex space-x-1 text-sm">
                    <div className="font-black">{`${selectedPost.post.likes}`}</div>
                    <div className="font-bold">{`Like${
                      selectedPost.post.likes > 1 ? 's' : ''
                    }`}</div>
                  </div>
                )}
              </div>
            )}
            <div
              className={`flex space-x-10 items-center justify-around border-t border-b ${
                exp === 'Recreational'
                  ? 'border-green-500'
                  : 'border-yellow-400'
              } py-2`}
              id="interact"
            >
              <Tooltip
                title="Reply"
                interactive
                disableFocusListener
                disableTouchListener
                arrow
              >
                <button
                  className={`flex space-x-1 text-gray-500 ${
                    exp === 'Recreational'
                      ? 'hover:text-green-500'
                      : 'hover:text-yellow-500'
                  } items-center focus:outline-none`}
                  disabled={!isOnline}
                  onClick={() => handleActionClicked('Reply')}
                >
                  <div>
                    <ChatBubbleOutlineRounded />
                  </div>
                </button>
              </Tooltip>
              <Tooltip
                title="Like"
                interactive
                disableFocusListener
                disableTouchListener
                arrow
              >
                <button
                  className={`flex space-x-1 ${
                    selectedPost.interactions.postLikeId
                      ? exp === 'Recreational'
                        ? 'text-green-500'
                        : 'text-yellow-500'
                      : 'text-gray-500'
                  } ${
                    exp === 'Recreational'
                      ? 'hover:text-green-500'
                      : 'hover:text-yellow-500'
                  } items-center focus:outline-none`}
                  onClick={() => handleToggleLikePost()}
                  disabled={togglingLike || !isOnline}
                >
                  <div>
                    {selectedPost.interactions.postLikeId ? (
                      // <FavoriteRounded />
                      <img src={likeActive} alt="likeActive" className="h-10" />
                    ) : (
                      // <FavoriteBorderRounded />
                      <img
                        src={likeInactive}
                        alt="likeInactive"
                        className="h-10"
                      />
                    )}
                  </div>
                </button>
              </Tooltip>
              <Tooltip
                title="Repost"
                interactive
                disableFocusListener
                disableTouchListener
                arrow
              >
                <button
                  className={`flex space-x-1 text-gray-500 ${
                    exp === 'Recreational'
                      ? 'hover:text-green-500'
                      : 'hover:text-yellow-500'
                  } items-center focus:outline-none`}
                  disabled={togglingRepost || !isOnline}
                  ref={rpRef}
                  onClick={() => handleRpOpen()}
                >
                  <div>
                    {selectedPost.interactions.postRepostId ? (
                      // <RepeatRounded
                      //   className={`${
                      //     exp === 'Recreational'
                      //       ? 'text-green-500'
                      //       : 'text-yellow-500'
                      //   }`}
                      // />
                      <img
                        src={repostActive}
                        alt="repostActive"
                        className="h-10"
                      />
                    ) : (
                      // <RepeatRounded />
                      <img
                        src={repostInactive}
                        alt="repostInactive"
                        className="h-10"
                      />
                    )}
                  </div>
                </button>
              </Tooltip>
              <Menu
                onClose={() => handleRpClose()}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                getContentAnchorEl={null}
                anchorEl={rpRef.current}
                open={rpMenuClicked}
                className="flex flex-col"
              >
                {selectedPost.interactions.postRepostId ? (
                  <MenuItem
                    className="flex space-x-2"
                    onClick={() => handleDeleteRepost()}
                  >
                    <div>
                      {/* <RepeatRounded fontSize="small" /> */}
                      <img
                        src={repostActive}
                        alt="repostActive"
                        className="h-10"
                      />
                    </div>
                    <div className="font-bold text-sm">Undo Repost</div>
                  </MenuItem>
                ) : (
                  <MenuItem
                    className="flex space-x-2"
                    onClick={() => handleRepost()}
                  >
                    <div>
                      {/* <RepeatRounded fontSize="small" /> */}
                      <img
                        src={repostActive}
                        alt="repostActive"
                        className="h-10"
                      />
                    </div>
                    <div className="font-bold text-sm">Repost</div>
                  </MenuItem>
                )}
                <MenuItem
                  className="flex space-x-2"
                  onClick={() => handleQuoteClicked()}
                >
                  <div>
                    <CreateRounded fontSize="small" />
                  </div>
                  <div className="font-bold text-sm">Quote Post</div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}
      </div>
      {repliesInfo.replies.length > 0 && (
        <Replies repliesInfo={repliesInfo} handleFetchMore={handleFetchMore} />
      )}
    </div>
  );
});

const Replies = React.memo(({ repliesInfo, handleFetchMore }) => {
  /* Variables */
  const exp = useSelector((state) => state.experience);
  const isOnline = useSelector((state) => state.network.isOnline);

  return (
    <div className="flex flex-col bg-white shadow rounded-lg mt-5 p-5">
      {repliesInfo.replies.map((p, i) => (
        <Post
          key={p.id}
          name={p.account.name}
          userName={p.account.userName}
          message={p.message}
          comments={p.comments}
          likes={p.likes}
          reposts={p.reposts}
          time={p.createdAt}
          avatarUrl={p.account.avatarUrl}
          account={p.account.id}
          id={p.id}
          type={p.type}
          parentPost={p.parentPost}
          isLast={i === repliesInfo.replies.length - 1}
          fromTimeLine={false}
        />
      ))}
      <div className="grid justify-items-center pt-5">
        {!repliesInfo.noMore && !repliesInfo.fetching && (
          <button
            className={`focus:outline-none font-bold ${
              exp === 'Recreational' ? 'text-green-700' : 'text-yellow-700'
            }`}
            disabled={!isOnline}
            onClick={() => handleFetchMore()}
          >
            Fetch More Replies
          </button>
        )}

        {repliesInfo.noMore && !repliesInfo.fetching && (
          <div
            className={`font-bold ${
              exp === 'Recreational' ? 'text-green-700' : 'text-yellow-700'
            }`}
          >
            You're all caught up!
          </div>
        )}

        {repliesInfo.fetching && (
          <CircularProgress size={20} color="secondary" />
        )}
      </div>
    </div>
  );
});

export default SelectedPost;
