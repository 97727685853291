import supabase from '../api';

/**
 * create a regular post
 * @param {string} accountId id of account that is posting
 * @param {string} message message of post
 * @returns returns post that is being created
 */
export const createRegularPost = async (accountId, message, environment) => {
  const { data: posts, error } = await supabase.from('posts').insert([
    {
      account: accountId,
      message: message,
      parentPost: null,
      type: 'regular',
      environment: environment,
    },
  ]);

  if (error) {
    throw error;
  }

  if (posts) {
    return posts[0];
  }
};

/**
 * handles fetching of post
 * @param {string} postId uuid of post being fetched
 * @returns single post
 */
export const fetchSinglePost = async (postId) => {
  const { data: post, error } = await supabase
    .from('posts')
    .select(
      `*, 
      account(name, userName, avatarUrl, id),
      parentPost(*, account(name, userName, avatarUrl, id),
      parentPost(*, account(name, userName, avatarUrl, id)))`
    )
    .eq('id', postId);

  if (error) {
    // throw error;
    return null;
  }

  if (post) {
    return post[0];
  }
};

export const fetchRepliesToPost = async (postId, lastCreatedAt, fetchLimit) => {
  const query =
    lastCreatedAt === null
      ? `and(parentPost.eq.${postId},type.eq.reply)`
      : `and(parentPost.eq.${postId},type.eq.reply,createdAt.lt.${lastCreatedAt})`;

  const { data: replies, error } = await supabase
    .from('posts')
    .select(
      `*, 
    account(name, userName, avatarUrl, id),
    parentPost(*, account(name, userName, avatarUrl, id),
    parentPost(*, account(name, userName, avatarUrl, id)))`
    )
    .or(query)
    .order('createdAt', { ascending: false })
    .limit(fetchLimit);

  if (error) {
    // throw error;
    return null;
  }

  if (replies) {
    return replies;
  }
};

/**
 * handles the repost of a post
 * @param {string} accountId uuid of account that is reposting
 * @param {string} message quoted message of repost (will be null most times)
 * @param {string} parentPostId uuid of post that is being reposted
 * @param {string} environment environment that user is reposting in
 * @returns repost post
 */
export const createRepostPost = async (
  accountId,
  message,
  parentPostId,
  environment
) => {
  // check if parent post still exists
  const post = await fetchSinglePost(parentPostId);

  if (post) {
    let promiseArray = [];

    // create repost
    // increment reposts on post
    // create repost notification
    const { data: reposts, error } = await supabase.from('posts').insert([
      {
        account: accountId,
        message: message,
        parentPost: parentPostId,
        type: message ? 'repostWithMessage' : 'repost',
        environment: environment,
      },
    ]);

    if (error) {
      throw error;
    }

    if (reposts) {
      promiseArray.push(
        supabase
          .from('posts')
          .update({ reposts: post.reposts + 1 })
          .eq('id', parentPostId)
      );

      // if person reposting is not the owner of the original post, create notification
      if (accountId !== post.account.id) {
        promiseArray.push(
          supabase.from('notifications').insert([
            {
              user: post.account.id,
              type: message ? 'repostWithMessage' : 'repost',
              createdBy: accountId,
              post: reposts[0].id,
              environment: environment,
            },
          ])
        );

        // if recreational then set newRecNotification to true
        if (environment === 'Recreational') {
          promiseArray.push(
            supabase
              .from('accounts')
              .update({ newRecNotification: true })
              .eq('id', post.account.id)
          );
        } else {
          // else set newMedNotification to true
          promiseArray.push(
            supabase
              .from('accounts')
              .update({ newMedNotification: true })
              .eq('id', post.account.id)
          );
        }
      }
    }

    try {
      return Promise.all(promiseArray);
    } catch (error) {
      throw error;
    }
  } else {
    return false;
  }
};

/**
 * handles the deletion of a repost
 * @param {string} postId id of repost in question
 * @param {string} parentPostId id of repost's parent
 * @returns error or resolved promise array
 */
export const deleteRepost = async (postId, parentPostId) => {
  // check if parent post still exists
  const post = await fetchSinglePost(parentPostId);

  if (post) {
    // promise array containing delete post + delete notification + reduce count of post
    const promiseArray = [
      supabase.from('notifications').delete().eq('post', postId),
      supabase
        .from('posts')
        .update({ reposts: post.reposts - 1 })
        .eq('id', parentPostId),
      supabase.from('posts').delete().eq('id', postId),
    ];

    try {
      return Promise.all(promiseArray);
    } catch (error) {
      throw error;
    }
  } else {
    return false;
  }
};

/**
 * handles replying to a post
 * @param {string} accountId uuid of account that is replying
 * @param {string} message message of reply
 * @param {string} parentPostId uuid of post that is being replied to
 * @param {string} environment environment that user is replying in
 * @returns reply post
 */
export const createPostReply = async (
  accountId,
  message,
  parentPostId,
  environment
) => {
  // check if parent post still exists
  const post = await fetchSinglePost(parentPostId);

  if (post) {
    let promiseArray = [];

    // create reply
    // increment replies on post
    // create reply notification
    const { data: replies, error } = await supabase.from('posts').insert([
      {
        account: accountId,
        message: message,
        parentPost: parentPostId,
        type: 'reply',
        environment: environment,
      },
    ]);

    if (error) {
      throw error;
    }

    if (replies) {
      promiseArray.push(
        supabase
          .from('posts')
          .update({ comments: post.comments + 1 })
          .eq('id', parentPostId)
      );

      // if person replying is not the owner of the original post, create notification
      if (accountId !== post.account.id) {
        promiseArray.push(
          supabase.from('notifications').insert([
            {
              user: post.account.id,
              type: 'reply',
              createdBy: accountId,
              post: replies[0].id,
              environment: environment,
            },
          ])
        );

        // if recreational then set newRecNotification to true
        if (environment === 'Recreational') {
          promiseArray.push(
            supabase
              .from('accounts')
              .update({ newRecNotification: true })
              .eq('id', post.account.id)
          );
        } else {
          // else set newMedNotification to true
          promiseArray.push(
            supabase
              .from('accounts')
              .update({ newMedNotification: true })
              .eq('id', post.account.id)
          );
        }
      }
    }

    try {
      return Promise.all(promiseArray);
    } catch (error) {
      console.log('error: ', error);
      throw error;
    }
  } else {
    return false;
  }
};

/**
 * handles fetching of posts for user's timeline
 * @param {string[]} idArr array of user id + buds
 * @returns array of posts to be displayed
 */
export const getPostsForTimeline = async (
  idArr,
  environment,
  lastCreatedAt,
  fetchLimit
) => {
  // query string
  const query =
    lastCreatedAt === null
      ? `and(account.in.(${idArr}),status.eq.active,environment.eq.${environment})`
      : `and(account.in.(${idArr}),status.eq.active,environment.eq.${environment},createdAt.lt.${lastCreatedAt})`;

  // now fetch all posts where account lookup is in array of ids
  // logic will be changed to dynamically get them by scroll/load
  const { data: posts, error } = await supabase
    .from('posts')
    .select(
      `*, 
      account(name, userName, avatarUrl, id),
      parentPost(*, account(name, userName, avatarUrl, id),
      parentPost(*, account(name, userName, avatarUrl, id)))`
    )
    .or(query)
    .order('createdAt', { ascending: false })
    .limit(fetchLimit);

  if (error) {
    console.log('error: ', error);
    throw error;
  }

  if (posts) {
    return posts;
  }
};

export const fetchPostLike = async (userId, postId) => {
  const { data: postLike, error } = await supabase
    .from('postLike')
    .select('id')
    .match({ user: userId, post: postId });

  if (error) {
    throw error;
  }

  if (postLike) {
    return postLike[0];
  }
};

export const fetchPostRepost = async (userId, postId) => {
  const { data: postRepost, error } = await supabase
    .from('posts')
    .select('id')
    .match({ account: userId, parentPost: postId, type: 'repost' });
  if (error) {
    throw error;
  }

  if (postRepost) {
    return postRepost[0];
  }
};

/**
 * handles deleting of post
 * @param {string} postId uuid of post getting deleted
 * @returns data of deletion
 */
export const deletePost = async (postId, postType, parentPostId, userId) => {
  // update post and postlikes to status of deleted
  const promiseArray = [
    supabase.from('posts').update({ status: 'deleted' }).eq('id', postId),
    supabase.from('postLike').update({ status: 'deleted' }).eq('post', postId),
  ];

  // if post type is reply or repost then we have to decrement necessary field + delete notification
  if (['reply', 'repostWithMessage', 'repost'].includes(postType)) {
    const post = await fetchSinglePost(parentPostId);

    if (post) {
      if (postType === 'reply') {
        promiseArray.push(
          supabase
            .from('posts')
            .update({ comments: post.comments - 1 })
            .eq('id', parentPostId)
        );
      } else {
        promiseArray.push(
          supabase
            .from('posts')
            .update({ reposts: post.reposts - 1 })
            .eq('id', parentPostId)
        );
      }

      promiseArray.push(
        supabase
          .from('notifications')
          .delete()
          .match({ post: parentPostId, createdBy: userId, type: postType })
      );
    }
  }

  return Promise.all(promiseArray);
};

/**
 * handle liking of a post
 * @param {string} postId uuid of post
 * @param {string} userId uuid of user
 * @param {string} postOwnerId uuid of postOwner
 */
export const likePost = async (postId, userId, postOwnerId, environment) => {
  // check if post still exists
  const post = await fetchSinglePost(postId);

  if (post) {
    // create promise array
    // add post like creation to array
    // increment like on post
    // create like notification
    const promiseArray = [
      supabase.from('postLike').insert([
        {
          user: userId,
          post: postId,
          environment: environment,
        },
      ]),
      supabase
        .from('posts')
        .update({ likes: post.likes + 1 })
        .eq('id', postId),
    ];

    // if user that liked is not the owner of the post then add notification
    if (userId !== postOwnerId) {
      promiseArray.push(
        supabase.from('notifications').insert([
          {
            user: postOwnerId,
            type: 'like',
            createdBy: userId,
            post: postId,
            environment: environment,
          },
        ])
      );

      // if recreational then set newRecNotification to true
      if (environment === 'Recreational') {
        promiseArray.push(
          supabase
            .from('accounts')
            .update({ newRecNotification: true })
            .eq('id', postOwnerId)
        );
      } else {
        // else set newMedNotification to true
        promiseArray.push(
          supabase
            .from('accounts')
            .update({ newMedNotification: true })
            .eq('id', postOwnerId)
        );
      }
    }

    try {
      return Promise.all(promiseArray);
    } catch (error) {
      throw error;
    }
  } else {
    return false;
  }
};

/**
 * handles the unliking of a post
 * @param {string} postId uuid of post
 * @param {string} likePostId uuid of postlike doc
 * @param {string} userId uuid of user unliking post
 * @param {string} postOwnerId uuid of user that created post
 * @returns promise or 'false'
 */
export const unlikePost = async (postId, likePostId, userId, postOwnerId) => {
  // check if post still exists
  const post = await fetchSinglePost(postId);

  if (post) {
    // create promise array
    // add post like deletion to array
    // decrement like from post
    // delete like notification
    const promiseArray = [
      supabase.from('postLike').delete().eq('id', likePostId),
      supabase
        .from('posts')
        .update({ likes: post.likes - 1 })
        .eq('id', postId),
    ];

    // if user that unliked is not the owner of the post then delete notification
    if (userId !== postOwnerId) {
      promiseArray.push(
        supabase
          .from('notifications')
          .delete()
          .match({ post: postId, type: 'like', createdBy: userId })
      );
    }

    try {
      return Promise.all(promiseArray);
    } catch (error) {
      throw error;
    }
  } else {
    return false;
  }
};

export const fetchPostsByUser = async (
  userId,
  environment,
  lastCreatedAt,
  fetchLimit
) => {
  // query string
  const query =
    lastCreatedAt === null
      ? `and(account.eq.${userId},status.eq.active,environment.eq.${environment})`
      : `and(account.eq.${userId},status.eq.active,environment.eq.${environment},createdAt.lt.${lastCreatedAt})`;

  const { data: posts, error } = await supabase
    .from('posts')
    .select(
      `*, 
    account(name, userName, avatarUrl, id),
    parentPost(*, account(name, userName, avatarUrl, id),
    parentPost(*, account(name, userName, avatarUrl, id)))`
    )
    .or(query)
    .order('createdAt', { ascending: false })
    .limit(fetchLimit);

  if (error) {
    throw error;
  }

  if (posts) {
    return posts;
  }
};

export const fetchPostsLikedByUser = async (
  userId,
  environment,
  lastCreatedAt,
  fetchLimit
) => {
  const query =
    lastCreatedAt === null
      ? `and(user.eq.${userId},status.eq.active,environment.eq.${environment})`
      : `and(user.eq.${userId},status.eq.active,environment.eq.${environment},createdAt.lt.${lastCreatedAt})`;

  const { data: likes, error } = await supabase
    .from('postLike')
    .select(
      `post(
        *,
        account(name, userName, avatarUrl, id),
        parentPost(*, account(name, userName, avatarUrl, id),
        parentPost(*, account(name, userName, avatarUrl, id)))
      )`
    )
    .or(query)
    .order('createdAt', { ascending: false })
    .limit(fetchLimit);

  if (error) {
    console.log('error: ', error);
    throw error;
  }

  if (likes) {
    return likes;
  }
};
