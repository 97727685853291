import supabase from '../api';

export const getState = async (name) => {
  const { data: states, error } = await supabase
    .from('state')
    .select('*')
    .eq('name', name);

  if (error) {
    throw error;
  }

  if (states) {
    return states;
  }
};

export const insertState = async (data) => {
  const { data: states, error } = await supabase.from('state').insert([data]);

  if (error) {
    throw error;
  }

  if (states) {
    return states[0];
  }
};

export const updateState = async (id, data) => {
  const { data: state, error } = await supabase
    .from('state')
    .update(data)
    .eq('id', id);

  if (error) {
    throw error;
  }

  if (state) {
    return state;
  }
};

export const getLawsByState = async (stateId) => {
  const { data: laws, error } = await supabase
    .from('laws')
    .select('*')
    .eq('state', stateId);

  if (error) {
    throw error;
  }

  if (laws) {
    return laws;
  }
};

export const insertLaw = async (data) => {
  const { data: laws, error } = await supabase.from('laws').insert([data]);

  if (error) {
    throw error;
  }

  if (laws) {
    return laws;
  }
};

export const updateLaw = async (id, data) => {
  const { data: laws, error } = await supabase
    .from('laws')
    .update(data)
    .eq('id', id);

  if (error) {
    throw error;
  }

  if (laws) {
    return laws;
  }
};

export const deleteLaw = async (id) => {
  const { data: laws, error } = await supabase
    .from('laws')
    .delete()
    .eq('id', id);

  if (error) {
    throw error;
  }

  if (laws) {
    return laws;
  }
};

export const getDispensariesByState = async (stateId) => {
  const { data: dispensaries, error } = await supabase
    .from('dispensaries')
    .select('*')
    .eq('state', stateId);

  if (error) {
    throw error;
  }

  if (dispensaries) {
    return dispensaries;
  }
};

export const insertDispensary = async (data) => {
  const { data: dispensaries, error } = await supabase
    .from('dispensaries')
    .insert([data]);

  if (error) {
    throw error;
  }

  if (dispensaries) {
    return dispensaries;
  }
};

export const updateDispensary = async (id, data) => {
  const { data: dispensaries, error } = await supabase
    .from('dispensaries')
    .update(data)
    .eq('id', id);

  if (error) {
    throw error;
  }

  if (dispensaries) {
    return dispensaries;
  }
};

export const deleteDispensary = async (id) => {
  const { data: dispensaries, error } = await supabase
    .from('dispensaries')
    .delete()
    .eq('id', id);

  if (error) {
    throw error;
  }

  if (dispensaries) {
    return dispensaries;
  }
};
