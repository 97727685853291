import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createBudRequest } from '../../supabase/queries/buds';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { NavLink } from 'react-router-dom';
import Avatar from '../Avatar';

const MatchSuggestion = React.memo((props) => {
  /* Variables */
  const [sending, setSending] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isOnline = useSelector((state) => state.network.isOnline);
  const account = useSelector((state) => state.auth.account);
  const matchDisabled = !isOnline || sending;
  const exp = useSelector((state) => state.experience);

  /* Methods */
  const handleMatch = async () => {
    setSending(true);

    await createBudRequest(account.id, props.id, account.id)
      .then((res) => {
        setRequestSent(true);
        showSuccess();
      })
      .catch((e) => {
        console.log('e: ', e);
      });

    setSending(false);
  };

  const showSuccess = () => {
    enqueueSnackbar(`Request sent to @${props.userName}`, {
      variant: 'success',
    });
  };

  return (
    <div className="flex justify-between items-center">
      <NavLink
        to={`/cloud/profile/${props.userName}`}
        className="flex space-x-2"
      >
        <div className="flex-initial">
          <Avatar
            url={props.avatarUrl}
            name={props.name}
            id={props.id}
            canEdit={false}
            style={`rounded-full h-10 w-10 font-bold flex items-center text-white justify-center ${
              exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
            } shadow-xl`}
          />
        </div>
        <div className="flex flex-col font-bold">
          <div className="text-sm">{props.name}</div>
          <div className="text-xs text-gray-500">@{props.userName}</div>
        </div>
      </NavLink>
      <div>
        {requestSent ? (
          <div className="font-bold text-gray-500">Sent</div>
        ) : (
          <button
            className={`rounded-full py-1 px-2 font-bold ${
              matchDisabled
                ? 'bg-gray-500 cursor-not-allowed'
                : exp === 'Recreational'
                ? 'bg-green-600'
                : 'bg-yellow-400'
            } text-white shadow-xl focus:outline-none`}
            disabled={matchDisabled}
            onClick={() => handleMatch()}
          >
            <div className="flex space-x-2 justify-center items-center">
              {sending && (
                <div>
                  <CircularProgress size={10} />
                </div>
              )}
              <div>{sending ? 'Sending' : 'Match'}</div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
});

export default MatchSuggestion;
