import * as actionTypes from './actionTypes';
import { getNotifications } from '../../supabase/queries/notifications';

export const startFetching = () => {
  return {
    type: actionTypes.START_FETCHING_NOTIFICATIONS,
  };
};

export const getUserNotifications = (userId, exp) => {
  return (dispatch) => {
    fetchNotifications(userId, exp, null).then((res) => {
      dispatch(
        getNotificationsSuccess(
          res.notifications,
          res.noMore,
          res.lastCreatedAt,
          true
        )
      );
    });
  };
};

export const fetchMoreNotifications = (userId, exp, lastCreatedAt) => {
  return (dispatch) => {
    fetchNotifications(userId, exp, lastCreatedAt).then((res) => {
      dispatch(
        getNotificationsSuccess(
          res.notifications,
          res.noMore,
          res.lastCreatedAt,
          false
        )
      );
    });
  };
};

export const resetNotificationReducer = () => {
  return {
    type: actionTypes.RESET_NOTICATION_REDUCER,
  };
};

const getNotificationsSuccess = (
  notifications,
  noMore,
  lastCreatedAt,
  isInit
) => {
  return {
    type: isInit
      ? actionTypes.GET_NOTIFICATIONS
      : actionTypes.FETCH_MORE_NOTIFICATIONS,
    notifications: notifications,
    noMore: noMore,
    lastCreatedAt: lastCreatedAt,
  };
};

const fetchNotifications = async (userId, exp, lastCreatedAt) => {
  let body = {
    notifications: [],
    noMore: true,
    lastCreatedAt: null,
  };

  let allNotifications = [];
  let likedPostsToUsersArr = [];
  let usersToLikedPostsArr = [];
  let repostPostsToUsersArr = [];
  let usersToRepostPostsArr = [];
  let notificationIdArr = [];

  /**
   * handles grouping of notifications either by post or user
   * @param {object} noty the notification element being evaluated
   */
  const handleGroupingAndPopulation = (noty) => {
    // if of type like, add to user liked group and liked group
    if (noty.type === 'like') {
      // get index of this post in likedPostsToUsersArr
      let postIndex = likedPostsToUsersArr.findIndex(
        (p) => p.id === noty.post.id
      );

      // if post is not in array then add it and create new array of users that liked
      if (postIndex === -1) {
        likedPostsToUsersArr.push({
          id: noty.post.id,
          post: noty.post,
          createdAt: noty.createdAt,
          type: 'postManyLikes',
          users: [
            {
              createdById: noty.createdBy?.id,
              createdByName: noty.createdBy?.name,
              avatarUrl: noty.createdBy?.avatarUrl,
            },
          ],
          notificationIds: [noty.id],
        });
      } else {
        // get index of user in post array
        let userIndex = likedPostsToUsersArr[postIndex].users.findIndex(
          (u) => u.createdById === noty.createdBy?.id
        );

        // only if it doesn't exist should we push it to array
        if (userIndex === -1) {
          likedPostsToUsersArr[postIndex].createdAt = noty.createdAt;
          likedPostsToUsersArr[postIndex].users.push({
            createdById: noty.createdBy?.id,
            createdByName: noty.createdBy?.name,
            avatarUrl: noty.createdBy?.avatarUrl,
          });
          likedPostsToUsersArr[postIndex].notificationIds.push(noty.id);
        }
      }

      // get index of this user in usersToLikedPostsArr
      let uIndex = usersToLikedPostsArr.findIndex(
        (u) => u.createdById === noty.createdBy?.id
      );

      // if user is not in array then add them and create new array of users
      if (uIndex === -1) {
        usersToLikedPostsArr.push({
          avatarUrl: noty.createdBy?.avatarUrl,
          id: noty.createdBy?.id,
          createdById: noty.createdBy?.id,
          createdAt: noty.createdAt,
          createdByName: noty.createdBy?.name,
          type: 'userLikedManyPosts',
          posts: [noty.post],
          notificationIds: [noty.id],
        });
      } else {
        // update this specific object for the notifications user to update the fields being tracked
        usersToLikedPostsArr[uIndex].createdAt = noty.createdAt;
        usersToLikedPostsArr[uIndex].posts.push(noty.post);
        usersToLikedPostsArr[uIndex].notificationIds.push(noty.id);
      }
    }

    // if of type repost, add to user reposted group and rt group
    if (noty.type === 'repost') {
      // get index of this post in repostPostsToUsersArr
      let postIndex = repostPostsToUsersArr.findIndex(
        (p) => p.id === noty.post.id
      );

      // if post is not in array then add it and create new array of users that liked
      if (postIndex === -1) {
        repostPostsToUsersArr.push({
          id: noty.post.id,
          post: noty.post,
          createdAt: noty.createdAt,
          type: 'postManyReposts',
          users: [
            {
              createdById: noty.createdBy?.id,
              createdByName: noty.createdBy?.name,
              avatarUrl: noty.createdBy?.avatarUrl,
            },
          ],
          notificationIds: [noty.id],
        });
      } else {
        // get index of user in post array
        let userIndex = repostPostsToUsersArr[postIndex].users.findIndex(
          (u) => u.createdById === noty.createdBy?.id
        );

        // only if it doesn't exist should we push it to array
        if (userIndex === -1) {
          repostPostsToUsersArr[postIndex].createdAt = noty.createdAt;
          repostPostsToUsersArr[postIndex].users.push({
            createdById: noty.createdBy?.id,
            createdByName: noty.createdBy?.name,
            avatarUrl: noty.createdBy?.avatarUrl,
          });
          repostPostsToUsersArr[postIndex].notificationIds.push(noty.id);
        }
      }

      // get index of this user in usersToRepostPostsArr
      let uIndex = usersToRepostPostsArr.findIndex(
        (u) => u.createdById === noty.createdBy?.id
      );

      // if user is not in array then add them and create new array of users
      if (uIndex === -1) {
        usersToRepostPostsArr.push({
          avatarUrl: noty.createdBy?.avatarUrl,
          id: noty.createdBy?.id,
          createdById: noty.createdBy?.id,
          createdAt: noty.createdAt,
          createdByName: noty.createdBy?.name,
          type: 'userRepostedManyPosts',
          posts: [noty.post],
          notificationIds: [noty.id],
        });
      } else {
        // update this specific object for the notifications user to update the fields being tracked
        usersToRepostPostsArr[uIndex].createdAt = noty.createdAt;
        usersToRepostPostsArr[uIndex].posts.push(noty.post);
        usersToRepostPostsArr[uIndex].notificationIds.push(noty.id);
      }
    }
  };

  await getNotifications(userId, exp, lastCreatedAt, 20)
    .then((res) => {
      let notArr = [];
      let postLikeGroupings = [];
      let userLikeGroupings = [];
      let postRepostGroupings = [];
      let userRepostGroupings = [];

      // iterate over notifications for user
      res.forEach((n) => {
        // handle grouping if necessary
        handleGroupingAndPopulation(n);

        // also push notification to array
        notArr.push({
          createdAt: n.createdAt,
          avatarUrl: n.createdBy?.avatarUrl,
          createdById: n.createdBy?.id,
          createdByName: n.createdBy?.name,
          id: n.id,
          readAt: n.readAt,
          type: n.type,
          post: n.post,
          request: n.request,
        });
      });

      // for each element in likedPostsToUsersArr, we only care about those that have more than 1 user like
      likedPostsToUsersArr.forEach((p) => {
        if (p.users.length > 1) {
          postLikeGroupings.push(p);
        }
      });

      // for each element in usersToLikedPostsArr, we only care about those that have more than 1 interation
      usersToLikedPostsArr.forEach((u) => {
        if (u.posts.length > 1) {
          userLikeGroupings.push(u);
        }
      });

      // get the ids of all notifications found in postLikeGroupings
      postLikeGroupings
        .map((p) => p.notificationIds)
        .forEach((p) => {
          notificationIdArr.push(...p);
        });

      // get the ids of all notifications found in userLikeGroupings
      userLikeGroupings
        .map((u) => u.notificationIds)
        .forEach((u) => {
          notificationIdArr.push(...u);
        });

      // for each element in repostPostsToUsersArr, we only care about those that have more than 1 user repost
      repostPostsToUsersArr.forEach((p) => {
        if (p.users.length > 1) {
          postRepostGroupings.push(p);
        }
      });

      // for each element in usersToRepostPostsArr, we only care about those that have more than 1 interation
      usersToRepostPostsArr.forEach((u) => {
        if (u.posts.length > 1) {
          userRepostGroupings.push(u);
        }
      });

      // get the ids of all notifications found in postRepostGroupings
      postRepostGroupings
        .map((p) => p.notificationIds)
        .forEach((p) => {
          notificationIdArr.push(...p);
        });

      // get the ids of all notifications found in userRepostGroupings
      userRepostGroupings
        .map((u) => u.notificationIds)
        .forEach((u) => {
          notificationIdArr.push(...u);
        });

      // we now want an array of notifications that are not found in above groupings
      let tempArr = notArr.filter((n) => !notificationIdArr.includes(n.id));

      // all the notifications we now care about consist of both groupings and also notifications that are not in either grouping
      allNotifications = [
        ...postLikeGroupings,
        ...userLikeGroupings,
        ...postRepostGroupings,
        ...userRepostGroupings,
        ...tempArr,
      ].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

      body.notifications = allNotifications;
      body.noMore = res.length < 20;
      body.lastCreatedAt = res[res.length - 1].createdAt;
    })
    .catch((e) => {});

  return body;
};
