import React, { useState, useRef } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import {
  AccountCircleRounded,
  ExitToAppRounded,
  NotificationsRounded,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../supabase/queries/auth';
import * as actionTypes from '../../store/actions';
// import Avatar from '../../components/Avatar';

const User = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const auth = useSelector((state) => state.auth);
  const exp = useSelector((state) => state.experience);
  const showNotificationBadge =
    (exp === 'Recreational' && auth.account.newRecNotification) ||
    (exp === 'Medicinal' && auth.account.newMedNotification);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    signOut();
    // dispatch user's main use as the experience
    dispatch(actionTypes.setExp('Recreational'));
    dispatch(actionTypes.logout());
    history.push('/');
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="text-white border-r-2 pr-1 border-white relative">
        <NavLink exact to="/cloud/notifications">
          <NotificationsRounded />
        </NavLink>
        {showNotificationBadge && (
          <div className="absolute top-0 right-1 animate-pulse h-2 w-2 rounded-full bg-red-500"></div>
        )}
      </div>
      {auth.account && (
        <button
          onClick={() => handleOpen()}
          className="flex space-x-4 text-white items-center font-bold focus:outline-none"
          ref={ref}
        >
          <div>Hey {auth.account?.name?.split(' ')[0]}</div>
          <div>
            <AccountCircleRounded fontSize="large" />
            {/* <Avatar
              url={auth.account.avatarUrl}
              name={auth.account.name}
              id={auth.account.id}
              canEdit={false}
              style={`rounded-full h-10 w-10 flex items-center justify-center ${
                exp === 'Recreational' ? 'bg-green-500' : 'bg-yellow-400'
              }`}
            /> */}
          </div>
        </button>
      )}
      <Menu
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={open}
      >
        <MenuItem
          className="flex space-x-2"
          component={Link}
          to={`/cloud/profile/${auth.account.userName}`}
        >
          <div>
            <AccountCircleRounded fontSize="small" />
          </div>
          <div className="font-bold text-sm">Profile</div>
        </MenuItem>
        <MenuItem className="flex space-x-2" onClick={() => handleLogout()}>
          <div>
            <ExitToAppRounded fontSize="small" />
          </div>
          <div className="font-bold text-sm">Logout</div>
        </MenuItem>
      </Menu>
    </div>
  );
});

export default User;
