import React from 'react';
import { FancyHeader } from '../../../components/FancyHeader';
import states from '../../../shared/states.json';

export const SearchSection = React.memo(({ selectedState, submit }) => {
  /* Methods */
  const handleStateSelect = (event) => {
    const val = event.target.value;
    submit(val);
  };

  return (
    <div className="relative pt-16 flex flex-col space-y-4">
      <div>
        <FancyHeader text={'Search Legality by State'} />
      </div>
      <div className="font-mono text-lg">
        Select a state to get marijuana news and laws based on that location.
        Currently limited to US Territories.
      </div>
      <form className="flex flex-col md:flex-row space-y-5 space-x-0 md:space-x-5 md:space-y-0 w-full">
        <select
          name="states"
          id="states"
          className="font-bold text-lg text-center rounded-md border-2 border-yellow-400 w-full h-10 px-2 outline-none"
          placeholder="Select State"
          value={selectedState}
          onChange={(event) => handleStateSelect(event)}
        >
          <option value="">Select State</option>
          {states.map((s) => (
            <option key={s.abbreviation} value={s.name}>
              {s.name}
            </option>
          ))}
        </select>
      </form>
    </div>
  );
});
