import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const NavItem = React.memo(({ icon, title, to, external }) => {
  const exp = useSelector((state) => state.experience);
  const auth = useSelector((state) => state.auth);

  return (
    <>
      {external ? (
        <a
          className={`text-black ${
            exp === 'Recreational'
              ? 'hover:text-green-500 hover:bg-green-100'
              : 'hover:text-yellow-400 hover:bg-yellow-100'
          } rounded-full p-1`}
          target="_blank"
          rel="noreferrer"
          href={to}
        >
          <div className="flex items-center justify-center xl:justify-start space-x-3">
            <div className="">{icon}</div>
            <div className="">
              <p className="hidden xl:inline-flex text-lg font-black">
                {title}
              </p>
            </div>
          </div>
        </a>
      ) : (
        <NavLink
          exact
          to={to}
          activeClassName={`${
            exp === 'Recreational' ? 'text-green-400' : 'text-yellow-400'
          }`}
          className={`relative text-black ${
            exp === 'Recreational'
              ? 'hover:text-green-500 hover:bg-green-100'
              : 'hover:text-yellow-400 hover:bg-yellow-100'
          } rounded-full p-1`}
        >
          {title === 'Buds' && auth.account.newBudRequests && (
            <div className="absolute top-1 left-8 animate-pulse h-2 w-2 rounded-full bg-red-500"></div>
          )}
          <div className="flex items-center justify-center xl:justify-start space-x-3">
            <div className="">{icon}</div>
            <div className="">
              <p className="hidden xl:inline-flex text-lg font-black">
                {title}
              </p>
            </div>
          </div>
        </NavLink>
      )}
    </>
  );
});
