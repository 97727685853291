import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SettingsProvider } from "./context/SettingsContext";
import { configureStore } from "./store";
import { restoreSettings } from "./utils/settings";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const store = configureStore();
const settings = restoreSettings();

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>{app}</React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
