import React, { useEffect, useState, useCallback } from 'react';
import {
  AddCircleRounded,
  CreateRounded,
  CloseRounded,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Modal } from '../../../components/Modal';
import {
  getDispensariesByState,
  insertDispensary,
  updateDispensary,
  deleteDispensary,
} from '../../../supabase/queries/learn';
import { useSnackbar } from 'notistack';

const defaultDispensary = {
  name: '',
  address: '',
};

export const DispensarySection = React.memo(({ state }) => {
  /* Variables */
  const account = useSelector((state) => state.auth.account);
  const [fetching, setFetching] = useState(true);
  const [dispensaries, setDispensaries] = useState([]);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedDispensary, setSelectedDispensary] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  /* useCallback */
  const fetchDispensaries = useCallback(async () => {
    setFetching(true);
    setDispensaries([]);
    await getDispensariesByState(state.id)
      .then((res) => {
        if (res.length > 0) {
          setDispensaries(res);
        }
      })
      .catch((err) => {
        console.log('err: ', err);
      });
    setFetching(false);
  }, [state.id]);

  /* useEffect */
  useEffect(() => {
    fetchDispensaries();
  }, [fetchDispensaries]);

  /* Methods */
  const toggleOpen = (show) => {
    setOpen(show);
    if (!show) {
      setAction('');
      setSelectedDispensary({});
    }
  };

  const create = () => {
    setAction('create');
    toggleOpen(true);
  };

  const edit = (dispensary) => {
    setAction('edit');
    setSelectedDispensary(dispensary);
    toggleOpen(true);
  };

  const del = (dispensary) => {
    setAction('delete');
    setSelectedDispensary(dispensary);
    toggleOpen(true);
  };

  const handleCreate = async (data) => {
    let ds = await insertDispensary(data).catch((err) => {
      console.log('err: ', err);
      displaySnackBar('Something went wrong please try again.', 'error');
    });

    if (ds) {
      setDispensaries((old) => {
        let update = [...old];
        update.push(ds[0]);
        return update;
      });

      displaySnackBar('Dispensary has been successfully created.', 'success');

      toggleOpen(false);
    }
  };

  const handleUpdate = async (id, data) => {
    let ds = await updateDispensary(id, data).catch((err) => {
      console.log('err: ', err);
      displaySnackBar('Something went wrong please try again.', 'error');
    });

    if (ds) {
      setDispensaries((old) => {
        let update = [...old];
        const index = update.findIndex((l) => l.id === id);
        if (index !== -1) {
          update.splice(index, 1, ds[0]);
        }
        return update;
      });

      displaySnackBar('Dispensary has been successfully updated.', 'success');

      toggleOpen(false);
    }
  };

  const handleDelete = async (id) => {
    let err = null;

    await deleteDispensary(id).catch((er) => {
      err = er;
      displaySnackBar('Something went wrong please try again.', 'error');
    });

    if (!err) {
      setDispensaries((old) => {
        let update = [...old];
        const index = update.findIndex((l) => l.id === id);
        if (index !== -1) {
          update.splice(index, 1);
        }
        return update;
      });
    }
  };

  const displaySnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  return (
    <div className="flex flex-col py-4 space-y-6">
      <div className="flex items-center justify-between font-black text-2xl font-serif border-b-2 border-green-400">
        <div>{`Dispensaries in ${state.name}`}</div>
        {!fetching && account?.type === 'admin' && (
          <div>
            <button className="focus:outline-none" onClick={() => create()}>
              <AddCircleRounded />
            </button>
          </div>
        )}
      </div>
      {fetching && (
        <div className="flex flex-wrap justify-evenly">
          <Skeleton variant="circle" width={150} height={60} />
          <Skeleton variant="circle" width={150} height={60} />
          <Skeleton variant="circle" width={150} height={60} />
        </div>
      )}
      {!fetching && dispensaries.length === 0 && account?.type !== 'admin' && (
        <div className="flex flex-col justify-between gap-10">
          <div>
            There are currently no dispensaries for this state in our database.
          </div>
        </div>
      )}
      {!fetching && dispensaries.length === 0 && account?.type === 'admin' && (
        <div className="flex flex-col justify-between gap-10">
          <div>
            There are currently dispensaries laws for this state in our
            database. Click the plus button above to add some.
          </div>
        </div>
      )}
      {!fetching && dispensaries.length > 0 && (
        <div className="flex flex-wrap">
          {dispensaries.map((d) => (
            <Dispensary key={d.id} dispensary={d} edit={edit} del={del} />
          ))}
        </div>
      )}
      <Modal
        isOpen={open}
        handleClose={() => toggleOpen(false)}
        content={
          <DispensaryDetails
            stateId={state.id}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            close={() => toggleOpen(false)}
            action={action}
            state={state}
            selectedDispensary={selectedDispensary}
          />
        }
      />
    </div>
  );
});

const Dispensary = React.memo(({ dispensary, edit, del }) => {
  /* Variables */
  const account = useSelector((state) => state.auth.account);

  return (
    <div className="flex justify-between rounded-full border-yellow-300 border-2 m-1 p-3 w-full lg:w-auto">
      <div className="flex flex-col">
        <div className="font-bold font-mono">{dispensary.name}</div>
        <div>
          <address>{dispensary.address}</address>
        </div>
      </div>
      {account?.type === 'admin' && (
        <div className="flex">
          <button
            className="focus:outline-none"
            onClick={() => edit(dispensary)}
          >
            <CreateRounded fontSize="small" />
          </button>
          <button
            className="focus:outline-none text-red-500"
            onClick={() => del(dispensary)}
          >
            <CloseRounded fontSize="small" />
          </button>
        </div>
      )}
    </div>
  );
});

const DispensaryDetails = React.memo(
  ({
    stateId,
    action,
    handleCreate,
    handleUpdate,
    handleDelete,
    close,
    selectedDispensary,
  }) => {
    /* Variables */
    const [updating, setUpdating] = useState(false);
    const isOnline = useSelector((state) => state.network.isOnline);
    const buttonDisabled = updating || !isOnline;
    const [dispensary, setDispensary] = useState(
      selectedDispensary ? selectedDispensary : defaultDispensary
    );

    /* Methods */
    const submit = async (event) => {
      event.preventDefault();
      setUpdating(true);

      if (action === 'create') {
        await handleCreate({ ...dispensary, state: stateId });
        setUpdating(false);
        close();
      }

      if (action === 'edit') {
        await handleUpdate(dispensary.id, {
          name: dispensary.name,
          address: dispensary.address,
        });
        setUpdating(false);
        close();
      }

      if (action === 'delete') {
        await handleDelete(dispensary.id);
        setUpdating(false);
        close();
      }
    };

    const updateDispensary = (event, field) => {
      event.persist();
      setDispensary((oldData) => {
        let updateData = { ...oldData };
        updateData[field] = event.target.value;
        return updateData;
      });
    };

    return (
      <form
        className="flex flex-col  space-y-6 relative w-full"
        onSubmit={(event) => submit(event)}
        autoComplete="true"
      >
        {action === 'create' && (
          <div className="font-mono font-bold text-2xl">Add Dispensary</div>
        )}
        {action === 'edit' && (
          <div className="font-mono font-bold text-2xl">Edit Dispensary</div>
        )}
        {action === 'delete' && (
          <div className="font-mono font-bold text-2xl">
            Sure you want to delete this?
          </div>
        )}
        <div className="flex flex-col">
          <div className="text-sm font-bold">Name</div>
          <div>
            {action !== 'delete' ? (
              <input
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                type="text"
                value={dispensary.name}
                onChange={(event) => updateDispensary(event, 'name')}
              />
            ) : (
              <output>{dispensary.name}</output>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-sm font-bold">Address</div>
          <div>
            {action !== 'delete' ? (
              <textarea
                className="w-full p-2 text-black rounded-lg border border-black focus:outline-none"
                type="text"
                value={dispensary.address}
                onChange={(event) => updateDispensary(event, 'address')}
              />
            ) : (
              <output>{dispensary.address}</output>
            )}
          </div>
        </div>
        <div>
          {action !== 'delete' && (
            <button
              className={`${
                buttonDisabled
                  ? 'bg-gray-500 cursor-not-allowed'
                  : 'bg-green-600'
              } float-right py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
              type="submit"
              disabled={buttonDisabled}
            >
              <div className="flex space-x-2 justify-center items-center">
                {updating && (
                  <div>
                    <CircularProgress size={10} />
                  </div>
                )}
                <div>{updating ? 'Saving' : 'Save'}</div>
              </div>
            </button>
          )}
          {action === 'delete' && (
            <button
              className={`${
                buttonDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-red-600'
              } float-right py-2 px-6 rounded-full text-white focus:outline-none shadow-xl`}
              type="submit"
              disabled={buttonDisabled}
            >
              <div className="flex space-x-2 justify-center items-center">
                {updating && (
                  <div>
                    <CircularProgress size={10} />
                  </div>
                )}
                <div>{updating ? 'Deleting' : 'Confirm'}</div>
              </div>
            </button>
          )}
        </div>
      </form>
    );
  }
);
