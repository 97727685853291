import supabase from '../api';

/**
 * signIn method that will log user in and also grab account information
 * @param {string} email user's email address
 * @param {string} password user's password
 */
export const signIn = async (email, password) => {
  // signIn
  const { user, error } = await supabase.auth.signIn({
    email,
    password,
  });

  if (error) {
    throw error;
  } else {
    return getAccountByUUID(user.id);
  }
};

/**
 * get account based on unique user id
 * @param {string} uuid user's unique id
 * @returns account that matches this id
 */
export const getAccountByUUID = async (uuid) => {
  // query for account where uuid matches param
  const { data: accounts, error } = await supabase
    .from('accounts')
    .select('*')
    .eq('uuid', uuid);

  if (error) {
    throw error;
  } else {
    return accounts[0];
  }
};

/**
 * get an account based on unique username
 * @param {string} userName username val
 * @returns account
 */
export const getAccountByUsername = async (userName) => {
  // run query on account where username matches param
  const { data: accounts, error } = await supabase
    .from('accounts')
    .select('*')
    .eq('userName', userName);

  if (error) {
    throw error;
  } else {
    return accounts[0];
  }
};

/**
 * checks if a user name is in use
 * @param {string} userName username that will be checked
 * @returns true or false based on accounts size
 */
export const userNameInUse = async (userName) => {
  const { data: accounts, error } = await supabase
    .from('accounts')
    .select('userName')
    .like('userName', userName);

  if (error) {
    throw error;
  } else {
    return accounts.length !== 0;
  }
};

/**
 * check if an email is in use
 * @param {string} email email address that will be checked
 * @returns true or false based on what the length of the list is
 */
export const emailInUse = async (email) => {
  const { data: users, error } = await supabase
    .from('accounts')
    .select('email')
    .eq('email', email);

  if (error) {
    throw error;
  } else {
    return users.length !== 0;
  }
};

/**
 * sign up method
 * @param {string} name new user's name
 * @param {string} email new user's email
 * @param {date} dateOfBirth new user's date of birth
 * @param {string} userName new user's username
 * @param {string} mainUse new user's main experience
 * @param {string} password new user's password
 * @param {string} avatarUrl location in storage for a user
 * @returns throws error or returns newly created account
 */
export const signUp = async (
  name,
  email,
  dateOfBirth,
  userName,
  mainUse,
  password,
  avatarUrl,
  bio,
  state
) => {
  const { user, error } = await supabase.auth.signUp({
    email,
    password,
  });

  if (error) {
    throw error;
  }

  if (user) {
    const { data: accounts, error } = await supabase.from('accounts').insert([
      {
        avatarUrl: avatarUrl,
        name: name,
        dateOfBirth: dateOfBirth,
        mainUse: mainUse,
        email: email,
        userName: userName,
        uuid: user.id,
        type: 'normal',
        bio: bio,
        state: state,
      },
    ]);

    if (error) {
      await supabase.from('users').delete().eq('id', user.id);
      throw error;
    } else {
      return accounts[0];
    }
  }
};

/**
 * method to auto login if there is still a valid session
 * @returns gets user by uuid or null
 */
export const autoLogin = async () => {
  const session = supabase.auth.session();

  if (session) {
    const user = session.user;
    return getAccountByUUID(user.id);
  } else {
    return null;
  }
};

/**
 * signs out of match buds
 * @returns throws error or just returns true
 */
export const signOut = () => {
  const { error } = supabase.auth.signOut();

  if (error) {
    throw error;
  } else {
    return true;
  }
};

export const updateAccount = async (id, uData) => {
  const { data, error } = await supabase
    .from('accounts')
    .update(uData)
    .eq('id', id);

  if (error) {
    throw error;
  }

  if (data) {
    return data;
  }
};

export const handleNotificationRead = async (accountId, updateData) => {
  const { data, error } = await supabase
    .from('accounts')
    .update(updateData)
    .eq('id', accountId);

  if (error) {
    throw error;
  }

  if (data) {
    return data;
  }
};

export const sendPasswordResetEmail = async (email) => {
  const { data, error } = await supabase.auth.api.resetPasswordForEmail(email);

  if (error) {
    throw error;
  }

  if (data) {
    return data;
  }
};

export const handlePasswordReset = async (access_token, new_password) => {
  const { error, data } = await supabase.auth.api.updateUser(access_token, {
    password: new_password,
  });

  if (error) {
    throw error;
  }

  if (data) {
    return data;
  }
};
