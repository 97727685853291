import React, { useState } from 'react';
import { FancyHeader } from '../../components/FancyHeader';
import Leaf from '../../assets/icons/leaf.png';
import budsImage from '../../assets/icons/buds.png';
import Earth from '../../assets/icons/earth.png';
import { NavLink } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { Authenticate } from '../../components/Authenticate';

export const SecondSection = React.memo(() => {
  const [authOpen, setAuthOpen] = useState(false);

  const toggleAuth = (show) => {
    setAuthOpen(show);
  };

  return (
    <div className="relative overflow-hidden">
      <Modal
        isOpen={authOpen}
        handleClose={() => toggleAuth(false)}
        content={
          <Authenticate propMode="signup" close={() => toggleAuth(false)} />
        }
      />
      <div className="absolute transform top-10 -rotate-45 -right-36 z-0 opacity-20">
        <img alt="leaf" src={Leaf} className="img-fluid" />
      </div>
      <div className="absolute transform top-80 rotate-45 -left-36 z-0 opacity-20">
        <img alt="leaf" src={Leaf} className="img-fluid" />
      </div>
      <div className="absolute transform bottom-10 -rotate-45 -right-36 z-0 opacity-20">
        <img alt="leaf" src={Leaf} className="img-fluid" />
      </div>
      <div className="container mx-auto px-4 md:px-0 grid place-items-center py-16 space-y-20">
        <div className="flex flex-col md:flex-row justify-between w-full items-center">
          <div className="flex flex-col space-y-5 items-center md:items-start">
            <div>
              <FancyHeader text={'Learn'} color={'black'} />
            </div>
            <div className="font-serif text-2xl w-full md:w-6/12 text-center md:text-left pb-10 md:pb-0">
              Are you up to date with the current marijuana laws in your state
              or a state you’re traveling to? Do you know the latest news on
              cannabis and information about the cannabis industry?{' '}
              <button
                className="focus:outline-none font-bold text-green-600"
                onClick={() => toggleAuth(true)}
              >
                Signup
              </button>{' '}
              and learn about Marijuana laws, practices, and news today on
              Matchbuds
            </div>
          </div>
          <div className="">
            <div className="transform rotate-0 transition duration-500 hover:scale-125">
              <NavLink to="/learn">
                <img className="img-fluid " alt="Earth PNG" src={Earth} />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-between w-full items-center">
          <div className="">
            <div className="transform rotate-0 transition duration-500 hover:scale-125">
              <NavLink to="/cloud9">
                <img className="img-fluid " alt="Earth PNG" src={Leaf} />
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col space-y-5 items-center md:items-end">
            <div>
              <FancyHeader text={'Experience'} color={'black'} />
            </div>
            <div className="font-serif text-2xl w-full md:w-6/12 text-center md:text-right pb-10 md:pb-0">
              Experience the lifestyle of a recreational or medicinal marijuana
              consumer and enjoy the Resources our Cloud 9 & marketplace has to
              offer.
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between w-full items-center">
          <div className="flex flex-col space-y-5">
            <div>
              <FancyHeader text={'Match Up!'} color={'black'} />
            </div>
            <div className="font-serif text-2xl w-full md:w-6/12 pb-10 md:pb-0">
              Connect with like minded Cannabis consumers in the Cloud 9 or to
              marijuana strains and use the MatchBuds feature to matchup!
            </div>
          </div>
          <div className="">
            <div className="transform rotate-0 transition duration-500 hover:scale-125">
              <NavLink to="/cloud9">
                <img className="img-fluid " alt="Earth PNG" src={budsImage} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
