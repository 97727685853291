import React from "react";
import Logo from "../assets/logo.png";

const SplashScreen = () => {
  return (
    <div className="bg-black h-screen grid place-content-center">
      <div>
        <img src={Logo} alt="logo" />
      </div>
    </div>
  );
};

export default SplashScreen;
