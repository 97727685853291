import React from 'react';
import { useSelector } from 'react-redux';

export const FancyHeader = React.memo(({ text, color }) => {
  const exp = useSelector((state) => state.experience);

  return (
    <div className="flex flex-col space-y-1">
      <div
        className={`text-3xl font-serif font-bold ${
          color === 'white' ? 'text-white' : `text-${color}-500`
        }`}
      >
        {text}
      </div>
      <div
        className={`w-16 border-b-2 ${
          exp === 'Recreational' ? 'border-green-400' : 'border-yellow-400'
        }`}
      ></div>
    </div>
  );
});
