import React, { useState } from 'react';
import { FancyHeader } from '../../components/FancyHeader';
import Hands from '../../assets/images/hands.png';
import Leaf from '../../assets/icons/leaf.png';
import { Modal } from '../../components/Modal';
import { Authenticate } from '../../components/Authenticate';

export const FourthSection = React.memo(() => {
  const [authOpen, setAuthOpen] = useState(false);

  const toggleAuth = (show) => {
    setAuthOpen(show);
  };

  return (
    <div className="relative overflow-hidden">
      <Modal
        isOpen={authOpen}
        handleClose={() => toggleAuth(false)}
        content={
          <Authenticate propMode="signup" close={() => toggleAuth(false)} />
        }
      />
      <div className="absolute transform top-10 rotate-45 -left-36 z-0 opacity-20">
        <img alt="leaf" src={Leaf} className="img-fluid" />
      </div>
      <div className="container mx-auto flex flex-wrap justify-between items-center py-20 relative px-2 md:px-0">
        <div className="flex flex-col flex-1 space-y-8">
          <div className="self-end lg:self-start">
            <FancyHeader text={'Join The Community'} color={'black'} />
          </div>
          <div className="text-black text-lg font-serif font-normal w-auto lg:w-96 text-right lg:text-left">
            Get first hand information about marijuana, the misconceptions and
            dangers and also connect with other people using marijuana for
            different purposes in America.
          </div>
          <div className="self-end lg:self-start">
            <button
              onClick={() => toggleAuth(true)}
              className="bg-green-400 text-white px-10 py-3 rounded-lg font-bold border-green-400 border-2 hover:text-black hover:bg-white hover:border-black hover:border-2 focus:outline-none"
            >
              Join Match Buds
            </button>
          </div>
        </div>
        <div className="hidden lg:flex flex-1 ">
          <img alt="hands" src={Hands} className="img-fluid rounded-lg" />
        </div>
      </div>
    </div>
  );
});
