import * as actionTypes from "../actions/actionTypes";

export const answerYes = () => {
  return {
    type: actionTypes.ANSWER_YES,
  };
};

export const resetOver21 = () => {
  return {
    type: actionTypes.RESET_OVER_21,
  };
};
