// over 21 action types
export const ANSWER_YES = 'ANSWER_YES';
export const RESET_OVER_21 = 'RESET_OVER_21';

// network action types
export const UPDATE_NETWORK_STATUS = 'UPDATE_NETWORK_STATUS';

// auth action types
export const LOG_IN = 'LOG_IN';
export const SIGN_UP = 'SIGN_UP';
export const LOG_OUT = 'LOG_OUT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const HANDLE_NOTIFICATION_READ = 'HANDLE_NOTIFICATION_READ';
export const UPDATE_URL = 'UPDATE_URL';

// experience action types
export const SET_EXP = 'SET_EXP';

// post action types
export const ADD_POST_LIKE_AND_REPOST = 'ADD_POST_LIKE_AND_REPOST';
export const ADD_POST_LIKE = 'ADD_POST_LIKE';
export const REMOVE_POST_LIKE = 'REMOVE_POST_LIKE';
export const ADD_POST_REPOST = 'ADD_POST_REPOST';
export const REMOVE_POST_REPOST = 'REMOVE_POST_REPOST';
export const ADD_POST_COMMENT = 'ADD_POST_COMMENT';
export const REMOVE_POST_COMMENT = 'REMOVE_POST_COMMENT';
export const GET_POSTS = 'GET_POSTS';
export const ADD_POST = 'ADD_POST';
export const FETCH_MORE = 'FETCH_MORE';
export const DELETE_POST = 'DELETE_POST';
export const SET_FETCHING = 'SET_FETCHING';
export const STOP_FETCHING = 'STOP_FETCHING';
export const STOP_LOADING = 'STOP_LOADING';
export const RESET_POST_REDUCER = 'RESET_POST_REDUCER';
export const GET_USER_POSTS = 'GET_USER_POSTS';
export const FETCH_MORE_USER_POSTS = 'FETCH_MORE_USER_POSTS';
export const GET_USER_LIKED_POSTS = 'GET_USER_LIKED_POSTS';
export const FETCH_MORE_USER_LIKED_POSTS = 'FETCH_MORE_USER_LIKED_POSTS';

// notification action types
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const START_FETCHING_NOTIFICATIONS = 'START_FETCHING_NOTIFICATIONS';
export const FETCH_MORE_NOTIFICATIONS = 'FETCH_MORE_NOTIFICATIONS';
export const RESET_NOTICATION_REDUCER = 'RESET_NOTICATION_REDUCER';

// requests action types
export const GET_REQUESTS = 'GET_REQUESTS';
export const START_FETCHING_REQUESTS = 'START_FETCHING_REQUESTS';
export const FETCH_MORE_REQUESTS = 'FETCH_MORE_REQUESTS';
export const RESET_REQUESTS_REDUCER = 'RESET_REQUESTS_REDUCER';
export const HANDLE_REQUEST_RESPONSE = 'HANDLE_REQUEST_RESPONSE';

// selected post action types
export const SELECT_POST = 'SELECT_POST';
export const FETCH_POST = 'FETCH_POST';
export const CLEAR_SELECTED_POST = 'CLEAR_SELECTED_POST';
export const SINGLE_POST_ADD_LIKE = 'SINGLE_POST_ADD_LIKE';
export const SINGLE_POST_ADD_REPOST = 'SINGLE_POST_ADD_REPOST';
export const SINGLE_POST_REMOVE_LIKE = 'SINGLE_POST_REMOVE_LIKE';
export const SINGLE_POST_REMOVE_REPOST = 'SINGLE_POST_REMOVE_REPOST';
