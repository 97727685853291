import React, { useState } from 'react';
import { PlayCircleFilledSharp } from '@material-ui/icons';
import { Modal } from '../../components/Modal';
import { Authenticate } from '../../components/Authenticate';
import LearnVid from '../../assets/videos/matchbud2.mov';

export const FirstSection = React.memo(() => {
  /* Variables */
  const [authOpen, setAuthOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  /* Methods */
  const toggleModal = (show) => {
    setShowModal(show);
  };

  const toggleAuth = (show) => {
    setAuthOpen(show);
  };

  return (
    <div id="top">
      <Modal
        isOpen={authOpen}
        handleClose={() => toggleAuth(false)}
        content={
          <Authenticate propMode="signup" close={() => toggleAuth(false)} />
        }
      />
      <Modal
        isOpen={showModal}
        handleClose={() => toggleModal(false)}
        content={<LearnVideo />}
      />
      <div className="container mx-auto px-4 md:px-0 grid place-items-center md:place-items-start space-y-10 py-28 lg:py-52 font-serif">
        <div className="text-5xl lg-text-7xl font-bold text-center md:text-left text-white w-auto md:w-8/12 lg:w-6/12">
          Matchup on Matchbuds!
        </div>
        <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:justify-between w-full md:w-8/12 lg:w-6/12">
          <div className="">
            <button
              onClick={() => toggleAuth(true)}
              className="w-full md:w-auto bg-green-400 text-white rounded-lg font-bold border-green-400 border-2 hover:bg-black hover:border-white hover:border-2 focus:outline-none h-12 px-10"
            >
              Join Match Buds
            </button>
          </div>
          <div className="group">
            <button
              onClick={() => toggleModal(true)}
              className="w-full md:w-auto grid grid-flow-col place-content-center space-x-1 bg-black border-2 border-white text-white rounded-lg font-bold group-hover:text-black group-hover:bg-white group-hover:shadow-lg focus:outline-none h-12 px-10"
            >
              <div>Watch Video</div>
              <div>
                <PlayCircleFilledSharp className="group-hover:text-black" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

const LearnVideo = React.memo(() => {
  return (
    <video className="rounded-lg focus:outline-none" controls>
      <source src={LearnVid} type="video/mp4" />
    </video>
  );
});
