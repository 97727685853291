import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// import Earth from "../../assets/icons/earth.png";
// import Bud from "../../assets/icons/bud.png";
// import Leaf from "../../assets/icons/leaf.png";
import { Modal } from '../../components/Modal';
import states from '../../shared/states.json';
import TextVid from '../../assets/videos/matchbuds1.mov';

export const FirstSection = React.memo(() => {
  /** variables **/
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');
  const [badStateVal, setBadStateVal] = useState(false);
  const history = useHistory();

  /** methods **/
  const toggleModal = (show) => {
    setShowModal(show);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // if search value is good ahead and do the needful
    if (states.some((state) => state.name === search)) {
      setBadStateVal(false);

      // push to learn page with params
      history.push(`./learn?state=${search}`);
    } else {
      setBadStateVal(true);
    }
  };

  return (
    <div className="bg-green-500 grid place-items-center py-20">
      <Modal
        isOpen={showModal}
        handleClose={() => toggleModal(false)}
        content={
          <SearchModalContent
            searchValue={search}
            stateChange={handleSearchChange}
            submit={handleSubmit}
            badStateVal={badStateVal}
          />
        }
        title={
          <div className="text-4xl font-bold text-center text-black">
            New Here?
          </div>
        }
      />
      <div className="container mx-auto flex flex-col md:flex-row items-center px-4 md:px-0">
        <div className="flex flex-col space-y-8 pb-5 md:pb-0">
          <div className="font-serif text-6xl w-8/12">
            A Cannabis Hub for Everyone
          </div>
          <div className=" text-3xl w-11/12">
            Match Buds is a digital space where you can{' '}
            <span className="text-white">learn</span> and{' '}
            <span className="text-white">shop</span> for all things cannabis
            while <span className="text-white">connecting</span> with
            like-minded individuals.
          </div>
          <div>
            <NavLink exact to="/cloud9">
              <button className="bg-black text-white px-10 py-3 rounded-3xl font-bold focus:outline-none">
                Get Started
              </button>
            </NavLink>
          </div>
        </div>
        <div className="">
          <video className="rounded-lg focus:outline-none" controls autoPlay>
            <source src={TextVid} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
});

const SearchModalContent = React.memo(
  ({ searchValue, stateChange, submit, badStateVal }) => {
    return (
      <div className="relative container mx-auto px-4 md:px-0 flex flex-col space-y-5 items-center justify-center space-y-10 font-serif">
        <div className="text-xl font-bold text-center text-black">
          Select your state to see it's legality status, laws and stories.
        </div>
        <form
          className="flex flex-col md:flex-row space-y-5 space-x-0 md:space-x-5 md:space-y-0 w-full md:w-auto pb-5"
          onSubmit={submit}
        >
          <div>
            <input
              list="states"
              id="state-choice"
              name="state-choice"
              placeholder="Select State"
              className="font-bold rounded-md border-2 border-black w-full md:w-60 h-10 px-2 outline-none"
              value={searchValue}
              onChange={(event) => stateChange(event)}
            />
            <datalist id="states">
              {states.map((s) => (
                <option key={s.abbreviation} value={s.name}></option>
              ))}
            </datalist>
          </div>
          <div>
            <button
              className="font-bold bg-green-400 border-2 border-green-400 rounded-md w-full md:w-60 h-10 text-white hover:text-black hover:border-black hover:bg-white focus:outline-none"
              type="submit"
            >
              Enter
            </button>
          </div>
        </form>
        {badStateVal && (
          <div className="absolute -bottom-2 text-red-500 font-bold text-lg">
            Please select/enter a valid state
          </div>
        )}
      </div>
    );
  }
);
