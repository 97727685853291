import supabase from '../api';

/**
 * creation of bud request amongst other things
 * @param {string} user1 uuid of first user
 * @param {string} user2 uuid of second user
 * @param {string} actionUser uuid of user making request
 * @returns returns notification record
 */
export const createBudRequest = async (user1, user2, actionUser) => {
  let u1;
  let u2;
  let uniqueId;
  let lastAction;
  let notifyUser;

  // we always want the "less than" user to be u1 and the other to be u2
  // this check drives that
  // uniqueId is comprised of both ids, with the less than uuid before the start and the other being the end
  if (user1 < user2) {
    u1 = user1;
    u2 = user2;
    uniqueId = user1 + user2;
  } else {
    u1 = user2;
    u2 = user1;
    uniqueId = user2 + user1;
  }

  // by determining if user is u1 or u2, we can set the last action field to the proper text value
  // we also want to determine the user that needs to receive a notification
  if (u1 === actionUser) {
    lastAction = 'pending_first_second';
    notifyUser = u2;
  } else {
    lastAction = 'pending_second_first';
    notifyUser = u1;
  }

  // now we attempt to insert bud relationship into db
  const { data: buds, error } = await supabase.from('buds').insert([
    {
      id: uniqueId,
      lastAction: lastAction,
      user1: u1,
      user2: u2,
    },
  ]);

  if (error) {
    throw error;
  }

  if (buds) {
    // promise array to create request and set newRequest to true on user
    const promiseArray = [
      supabase.from('requests').insert([
        {
          user: notifyUser,
          from: actionUser,
          type: 'follow_request',
          budId: uniqueId,
        },
      ]),
      supabase
        .from('accounts')
        .update({ newBudRequests: true })
        .eq('id', notifyUser),
    ];

    try {
      return Promise.all(promiseArray);
    } catch (error) {
      throw error;
    }
  }
};

/**
 * algorithm to get random buds for user to match with
 * @param {string} userId uuid of user that we will be getting random suggestions for
 * @returns returns 3 random users that user doesn't have any bud relations with
 */
export const getBudSuggestions = async (userId) => {
  // add user id to array cause we don't want to show them themselves
  let userArr = [userId];

  // we also want to ignore all users that have pending or matched status
  let pendingBudArr = await getAllBudsThatHaveAnExistingRelationshipWithUser(
    userId
  );

  // combine both arrays
  const ignoreArr = userArr.concat(pendingBudArr);

  // now get accounts that don't have their id in ignore array
  const { data: accounts, error } = await supabase
    .from('accounts')
    .select('*')
    .not('id', 'in', `(${ignoreArr})`)
    .limit(3);

  if (error) {
    throw error;
  }

  if (accounts) {
    return accounts;
  }
};

/**
 * get all matched buds for a specific user
 * @param {string} userId uuid of user in question
 * @returns returns an array of all ids of user's buds
 */
export const getAllBudIdsForUser = async (userId) => {
  let budsIdsArr = [];

  // fetch bud relationships where user is either user 1 or 2 and lastAction is buds
  const { data: allBuds, error } = await supabase
    .from('buds')
    .select('user2, user1')
    .or(`and(lastAction.eq.buds,or(user1.eq.${userId},user2.eq.${userId}))`);

  if (error) {
    throw error;
  }

  if (allBuds) {
    // if no error, iterate over each bud relationship and add other user id to array
    allBuds.forEach((bud) => {
      if (bud.user1 === userId) {
        budsIdsArr.push(bud.user2);
      } else {
        budsIdsArr.push(bud.user1);
      }
    });

    return budsIdsArr;
  }
};

export const getAllBudsForUser = async (userId, lastBecameBuds, fetchLimit) => {
  // create query
  const query =
    lastBecameBuds === null
      ? `and(lastAction.eq.buds,or(user1.eq.${userId},user2.eq.${userId}))`
      : `and(lastAction.eq.buds,or(user1.eq.${userId},user2.eq.${userId}),becameBuds.gt.${lastBecameBuds})`;

  let buds = [];

  // query for buds where user is user1 or user2 and last action is buds
  const { data: allBuds, error } = await supabase
    .from('buds')
    .select('user2(*), user1(*), *')
    .or(query)
    .order('becameBuds', { ascending: true })
    .limit(fetchLimit);

  // if error then throw it
  if (error) {
    throw error;
  }

  // if buds exist then add them to array and return
  if (allBuds) {
    allBuds.forEach((bud) => {
      if (bud.user1.id === userId) {
        buds.push({ ...bud.user2, becameBuds: bud.becameBuds, budId: bud.id });
      } else {
        buds.push({ ...bud.user1, becameBuds: bud.becameBuds, budId: bud.id });
      }
    });

    return buds;
  }
};

/**
 * gets all bud relationships where lastaction is a type of pending and user is either user 1 or 2
 * @param {string} userId id of user in questiion
 * @returns array of uuids
 */
export const getAllBudsThatHaveAnExistingRelationshipWithUser = async (
  userId
) => {
  let budsIdsArr = [];

  // fetch buds from db that match above criteria
  // TODO add more filters like not blocked, same state, similar strains liked
  const { data: buds, error } = await supabase
    .from('buds')
    .select('user2, user1')
    .or(`user1.eq.${userId},user2.eq.${userId}`);

  if (error) {
    throw error;
  }

  if (buds) {
    // if no error then iterate buds and add user id to array if it's not that of current user
    buds.forEach((bud) => {
      if (bud.user1 === userId) {
        budsIdsArr.push(bud.user2);
      } else {
        budsIdsArr.push(bud.user1);
      }
    });

    return budsIdsArr;
  }
};

export const deleteBudRelationShip = (budId) => {
  const promiseArray = [
    supabase.from('buds').delete().eq('id', budId),
    supabase.from('requests').delete().eq('budId', budId),
  ];

  try {
    return Promise.all(promiseArray);
  } catch (error) {
    throw error;
  }
};

export const getBudRelationship = async (id) => {
  const { data, error } = await supabase.from('buds').select('*').eq('id', id);

  if (error) {
    throw error;
  }

  if (data) {
    return data;
  }
};

export const getUsersByTextSearch = async (text) => {
  const { data, error } = await supabase
    .from('accounts')
    .select('name, userName, id, avatarUrl')
    .or(`name.ilike.%${text}%,userName.ilike.%${text}%`);

  if (error) {
    throw error;
  }

  if (data) {
    return data;
  }
};
