import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  EcoRounded,
  OfflineBoltRounded,
  WbSunnyRounded,
  BatteryFullRounded,
  EmojiEmotionsRounded,
  EmojiObjectsRounded,
  RestaurantRounded,
  NightsStayRounded,
  LocalHospitalRounded,
  WavesRounded,
  HotelRounded,
} from '@material-ui/icons';
import { FancyHeader } from '../../../components/FancyHeader';
import LearnVid from '../../../assets/videos/matchbud2.mov';
import { Modal } from '../../../components/Modal';
import { Authenticate } from '../../../components/Authenticate';

const LearnIntro = React.memo(() => {
  const yOffset = -10;

  const [recY, setRecY] = useState(null);
  const [medY, setMedY] = useState(null);

  const auth = useSelector((state) => state.auth);
  const loggedIn = auth.account.hasOwnProperty('uuid');
  const [authOpen, setAuthOpen] = useState(false);

  const recCallback = (el) => {
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      setRecY(y);
    }
  };

  const medCallback = (el) => {
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      setMedY(y);
    }
  };

  const scrollToRec = () => {
    window.scrollTo({ top: recY, behavior: 'smooth' });
  };

  const scrollToMed = () => {
    window.scrollTo({ top: medY, behavior: 'smooth' });
  };

  const toggleAuth = (show) => {
    setAuthOpen(show);
  };

  return (
    <div className="flex flex-col space-y-16">
      <Modal
        isOpen={authOpen}
        handleClose={() => toggleAuth(false)}
        content={
          <Authenticate propMode="signup" close={() => toggleAuth(false)} />
        }
      />
      <div className="flex flex-col space-y-5">
        <div>
          <FancyHeader text={'What is Cannabis?'} />
        </div>
        <div className="font-mono text-lg">
          Otherwise known as Marijuana is a tall plant used to produce hemp
          fiber for a recreational and medicinal healing drug. Parts of the
          Cannabis flower tops can be dried and prepared to be smoked and
          consumed{' '}
          <span
            className="font-black text-green-500 cursor-pointer"
            onClick={scrollToRec}
          >
            Recreationally
          </span>{' '}
          or{' '}
          <span
            className="font-black text-yellow-500 cursor-pointer"
            onClick={scrollToMed}
          >
            Medicinally
          </span>
        </div>
        <div className="font-mono text-lg">
          Other references of Cannabis: Marijuana, Weed, Ganja, Hashish, & Hemp
        </div>
        <div className="self-center">
          <video className="rounded-lg focus:outline-none" controls autoPlay>
            <source src={LearnVid} type="video/mp4" />
          </video>
        </div>
        <div className="flex flex-col space-y-2">
          <div>
            <FancyHeader text={'Get Involved'} />
          </div>
          <div className="font-mono text-lg">
            Want to get involved in the Cannabis industry? Coming soon!
          </div>
        </div>
        {!loggedIn && (
          <div className="flex flex-col space-y-2">
            <div>
              <FancyHeader text={'MatchUp on MatchBuds'} />
            </div>
            <div className="font-mono text-lg">
              Like what you see?{' '}
              <span
                onClick={() => toggleAuth(true)}
                className="text-blue-500 cursor-pointer font-black"
              >
                Click here
              </span>{' '}
              and learn more from the community in the Cloud9
            </div>
          </div>
        )}
      </div>
      <div ref={recCallback} className="flex flex-col space-y-2">
        <div>
          <FancyHeader text={'Recreational'} />
        </div>
        <div className="font-mono text-lg">
          Cannabis can be consumed as a psychoactive or body relaxing drug.
          Often smoked but can be prepared edible as well. Cannabis is not
          federally legal yet, PLEASE be sure to visit our laws section to
          consume responsibly!!
        </div>
      </div>
      <div ref={medCallback} className="flex flex-col space-y-2">
        <div>
          <FancyHeader text={'Medicinal'} />
        </div>
        <div className="font-mono text-lg">
          Cannabis as mentioned earlier can also be referred to as Medical
          Marijuana. Multiple studies show that the Cannabis Plant can be used
          as a treatment for multiple medical conditions as well as a healer of
          pain.
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div>
          <FancyHeader text={'Main Strains'} />
        </div>
        <div className="flex flex-wrap space-x-2 space-y-4 lg:space-y-0">
          <div className="flex-1 flex-col">
            <div className="text-right pr-2 text-2xl font-bold">Sativa</div>
            <div className="bg-green-200 flex flex-col rounded-tr-3xl rounded-bl-3xl p-5 space-y-4 shadow">
              <div className="flex flex-col">
                <div className="font-bold text-lg">Look:</div>
                <div className="flex items-center space-x-2">
                  <EcoRounded fontSize="large" />
                  <div className="font-mono">
                    skinny, light green leaves tall, slim plants
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-bold text-lg">Feel:</div>
                <div className="flex items-center space-x-2">
                  <OfflineBoltRounded fontSize="large" />
                  <div className="font-mono">stimulating</div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-bold text-lg">Usually used:</div>
                <div className="flex items-center space-x-2">
                  <WbSunnyRounded fontSize="large" />
                  <div className="font-mono">daytime use</div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-bold text-lg">Extras:</div>
                <div className="flex items-center space-x-2 font-mono">
                  <div className="flex-1 grid justify-items-center ">
                    <BatteryFullRounded fontSize="large" />
                    <div>boosts</div>
                    <div>energy</div>
                  </div>
                  <div className="flex-1 grid justify-items-center ">
                    <EmojiEmotionsRounded fontSize="large" />
                    <div>eases</div>
                    <div>depression</div>
                  </div>
                  <div className="flex-1 grid justify-items-center ">
                    <EmojiObjectsRounded fontSize="large" />
                    <div>increases</div>
                    <div>focus</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex-col">
            <div className="pl-2 text-2xl font-bold">Indica</div>
            <div className="bg-yellow-200 flex flex-col rounded-tl-3xl rounded-br-3xl p-5 space-y-4 shadow">
              <div className="flex flex-col">
                <div className="font-bold text-lg">Look:</div>
                <div className="flex items-center space-x-2">
                  <EcoRounded fontSize="large" />
                  <div className="font-mono">
                    short, bushy plants broad, dark green leaves
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-bold text-lg">Feel:</div>
                <div className="flex items-center space-x-2">
                  <WavesRounded fontSize="large" />
                  <div className="font-mono">relaxing</div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-bold text-lg">Usually used:</div>
                <div className="flex items-center space-x-2">
                  <NightsStayRounded fontSize="large" />
                  <div className="font-mono">nighttime use</div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-bold text-lg">Extras:</div>
                <div className="flex items-center space-x-2 font-mono">
                  <div className="flex-1 grid justify-items-center ">
                    <HotelRounded fontSize="large" />
                    <div>acts as</div>
                    <div>sedative</div>
                  </div>
                  <div className="flex-1 grid justify-items-center ">
                    <LocalHospitalRounded fontSize="large" />
                    <div>relieves</div>
                    <div>pain</div>
                  </div>
                  <div className="flex-1 grid justify-items-center ">
                    <RestaurantRounded fontSize="large" />
                    <div>stimulates</div>
                    <div>appetite</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LearnIntro;
